import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useDepartmentImages } from '@shared/hooks';
import { IDepartmentImageFilter } from '@shared/interfaces';
import { Button, Input, PageHeader } from 'antd';
import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentImageList from '../components/DepartmentImageList';
import { _ } from '@shared/utils';

const DepartmentImagesPage = () => {
  const navigate = useNavigate();

  const { search } = useLocation();
  const option: IDepartmentImageFilter = QueryString.parse(search.slice(1));
  const [query, setQuery] = useState<IDepartmentImageFilter>({ ...option });

  useEffect(() => {
    navigate(`?${_.queryNormalizer(query)}`);
  }, [navigate, query]);

  const { data, isLoading } = useDepartmentImages({
    options: {
      query: search.slice(1),
      page: option.page || 1,
      limit: option.limit || 10,
      sort: ['createdAt,DESC'],
    },
  });

  return (
    <Authorization allowedAccess={['DEPARTMENT_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Department Images List"
        subTitle={
          <Input
            size="large"
            placeholder="Search"
            prefix={<AiOutlineSearch />}
            style={{ borderRadius: 30 }}
            onChange={(e) => setQuery({ ...query, searchTerm: e.target.value })}
          />
        }
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.DepartmentImageCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <DepartmentImageList
          total={data?.data?.total}
          departmentImages={data?.data?.payload}
          isLoading={isLoading}
          query={query}
          setQuery={setQuery}
        />
      </PageHeader>
    </Authorization>
  );
};

export default DepartmentImagesPage;
