import { Authorization } from '@modules/auth';
import { useCreateSlot } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import SlotCreateForm from '../components/SlotCreateForm';

const SlotCreateRoute = () => {
  const navigate = useNavigate();

  const createSlot = useCreateSlot({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Slot">
        <SlotCreateForm
          isLoading={createSlot.isLoading}
          onFinish={(values) => createSlot.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};

export default SlotCreateRoute;
