import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { Button, Input, PageHeader } from 'antd';
import { startTransition, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import ZoneList from '../components/ZoneList';

const ZonesRoute = () => {
  const [searchTerm, setSearchTerm] = useState<string>();
  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <PageHeader
        title="Zones"
        subTitle={
          <Input
            size="large"
            placeholder="Search"
            prefix={<AiOutlineSearch />}
            style={{ borderRadius: 30 }}
            onChange={(e) =>
              startTransition(() => setSearchTerm(e.target.value))
            }
          />
        }
        extra={[
          <Button key="1" type="primary">
            <Link to={Paths.ZoneCreate}>Create</Link>
          </Button>,
        ]}
      >
        <ZoneList searchTerm={searchTerm} />
      </PageHeader>
    </Authorization>
  );
};

export default ZonesRoute;
