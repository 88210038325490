import { Authorization } from '@modules/auth';
import { useOffer, useUpdateOffer } from '@shared/hooks';
import { IOfferUpdate } from '@shared/interfaces';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import moment from 'moment';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OfferUpdateForm from '../components/OfferUpdateForm';

const OfferUpdateRoute = () => {
  const navigate = useNavigate();

  const { id }: any = useParams();
  const { data, isLoading } = useOffer({ id });

  const initialValues: IOfferUpdate = {
    title: data?.data?.payload?.title,
    image: data?.data?.payload?.image,
    description: data?.data?.payload?.description,
    startDate: moment(data?.data?.payload?.startDate),
    endDate: moment(data?.data?.payload?.endDate),
  };

  const updateOffer = useUpdateOffer({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });
  return (
    <Authorization allowedAccess={['ORDER_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader title="Update Offer" onBack={() => navigate(-1)}>
          <OfferUpdateForm
            initialValues={initialValues}
            isLoading={updateOffer.isLoading}
            onFinish={(values) => updateOffer.mutateAsync({ id, ...values })}
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default OfferUpdateRoute;
