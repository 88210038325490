import { Authorization } from '@modules/auth';
import { useCreateZone } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import ZoneCreateForm from '../components/ZoneCreateForm';

const ZoneCreateRoute = () => {
  const navigate = useNavigate();
  const createZone = useCreateZone({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Zone">
        <ZoneCreateForm
          isLoading={createZone.isLoading}
          onFinish={(values) => createZone.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};

export default ZoneCreateRoute;
