import { Authorization } from '@modules/auth';
import { useRole, useUpdateRole } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import RoleUpdateForm from '../components/RoleUpdateForm';

const RoleUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useRole({ id });

  const updateBaseDepartment = useUpdateRole({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ROLE_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update Role">
          <RoleUpdateForm
            initialValues={{ title: data?.data?.payload?.title }}
            isLoading={updateBaseDepartment.isLoading}
            onFinish={(values) =>
              updateBaseDepartment.mutateAsync({ id, ...values })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default RoleUpdateRoute;
