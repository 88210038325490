import { getAccess } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useDeleteUser } from '@shared/hooks';
import { IFamily, IUser } from '@shared/interfaces';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import FamiliesList from './FamiliesList';

interface IProps {
  isLoading?: boolean;
  users: IUser[];
  families?: IFamily[];
  pagination: PaginationProps;
}
const UserList: React.FC<IProps> = ({
  isLoading,
  users,
  families,
  pagination,
}) => {
  const navigate = useNavigate();

  const deleteUser = useDeleteUser();

  const dataSource = users?.map((x: IUser) => ({
    key: x?.id,
    id: x?.id,
    firstName: x?.firstName,
    lastName: x?.lastName,
    phoneNumber: x?.phoneNumber,
    email: x?.email,
    role: x?.role,
    referenceCode: x?.referenceCode,
  }));

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Reference Code',
      dataIndex: 'referenceCode',
      key: 'referenceCode',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            onClick={() => navigate(`${Paths.userUpdate}/${id}`)}
          >
            Update
          </Button>
          <Popconfirm
            disabled
            title="Are you sure to delete this User?"
            onConfirm={() =>
              getAccess(['USER_DELETE', 'FORBIDDEN'], () =>
                deleteUser.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button disabled danger type="primary">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
      expandable={{
        expandedRowRender: (record) => (
          <FamiliesList
            user={`${record.firstName} ${record.lastName}`}
            families={families?.filter((item) => item.user.id === record.id)}
          />
        ),
      }}
    />
  );
};

export default UserList;
