import { ICallRequest } from '@shared/interfaces';
import { Col, Row } from 'antd';
import React from 'react';

interface IProps {
  CallRequest: ICallRequest;
}
const CallRequestDetails: React.FC<IProps> = ({ CallRequest }) => {
  console.log(CallRequest);
  return (
    <Row
      gutter={[
        { sm: 16, md: 20, lg: 30 },
        { sm: 30, md: 30, lg: 30 },
      ]}
    >
      <Col span={8}>
        <ul>
          <li>
            <b>First Name:</b> {CallRequest.firstName}
          </li>
          {/* <li>
            <b>Active:</b> {Package.isActive?.toString()}
          </li>
          */}
        </ul>
      </Col>
    </Row>
  );
};
export default CallRequestDetails;
