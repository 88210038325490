import { useDeleteSlot, useSlots } from '@shared/hooks';
import { Button, Popconfirm, Space, Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '@shared/constant';
import { ISlot } from '@shared/interfaces';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import ImagePreview from '@shared/components/ImagePreview';
import { getAccess } from '@modules/auth';

interface IProps {
  searchTerm?: string;
}

const SlotList: React.FC<IProps> = ({ searchTerm }) => {
  const navigate = useNavigate();

  const deleteSlot = useDeleteSlot();

  const { search } = useLocation();
  const query = {
    page: parseInt(search.slice(6, 7)) || 1,
    limit: parseInt(search.slice(13)) || 10,
  };

  const { data, isLoading } = useSlots({
    options: {
      page: query.page,
      limit: query.limit,
      searchTerm: searchTerm,
      sort: ['createdAt,DESC'],
    },
  });

  const dataSource = data?.data?.payload?.map((x: ISlot) => ({
    key: x.id,
    id: x.id,
    title: x.title,
    time: x.time,
    isActive: x.isActive.toString(),
    icon: x.icon,
    image: x.image,
    backgroundImage: x.backgroundImage,
  }));

  const columns: any = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon: string) => <ImagePreview src={icon} alt="icon" />,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="thumb" />,
    },
    {
      title: 'BG Image',
      dataIndex: 'backgroundImage',
      key: 'backgroundImage',
      render: (backgroundImage: string) => (
        <ImagePreview src={backgroundImage} alt="BgImage" />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(`${Paths.slotUpdate}/${id}`)}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() =>
              getAccess(['ADDRESS_MANAGEMENT', 'FORBIDDEN'], () =>
                deleteSlot.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: query.page,
        total: data?.data?.total,
        onChange: (page: number, limit: number) => {
          navigate(`?page=${page}&limit=${limit}`);
        },
      }}
    />
  );
};

export default SlotList;
