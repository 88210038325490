import { IOrder } from '@shared/interfaces';
import { Col, Row } from 'antd';
import React from 'react';

interface IFProps {
  order: IOrder;
}
const OrderDetails: React.FC<IFProps> = ({ order }) => {
  return (
    <Row
      gutter={[
        { sm: 16, md: 20, lg: 30 },
        { sm: 30, md: 30, lg: 30 },
      ]}
    >
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <h2 className="text-2xl font-semibold underline">Order Info</h2>
        <ul>
          <li>
            <b>Created At:</b> {order.createdAt}
          </li>
          <li>
            <b>Delivery Date:</b> {order.deliveryDate}
          </li>
          <li>
            <b>Payment Status:</b> {order.paymentStatus}
          </li>
          <li>
            <b>Status:</b> {order.status}
          </li>
          <li>
            <b>Total Amount:</b> {order.total}
          </li>
          <li>
            <b>Subtotal Amount:</b> {order.subTotal}
          </li>
        </ul>

        <h2 className="text-2xl font-semibold underline">Provider Info</h2>
        <ul>
          <li>
            <b>Name:</b> {order.provider?.name}
          </li>
          <li>
            <b>Email:</b> {order.provider?.email}
          </li>
          <li>
            <b>Address:</b> {order.provider?.address}
          </li>
        </ul>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <h2 className="text-2xl font-semibold underline">Order Items</h2>

        {order.orderItems?.map((item) => (
          <ul>
            {item.providerPackage && (
              <li>
                <h4 className="text-lg font-semibold underline">Package</h4>
                <ul>
                  <li>
                    <b>Title:</b> {item?.providerPackage?.title}
                  </li>
                  <li>
                    <b>Package Id:</b> {item?.providerPackage?.id}
                  </li>
                </ul>
              </li>
            )}
            {item.providerProduct && (
              <li>
                <h4 className="text-lg font-semibold underline">Product</h4>
                <ul>
                  <li>
                    <b>Title:</b> {item?.providerProduct?.title}
                  </li>
                  <li>
                    <b>Package Id:</b> {item?.providerProduct?.id}
                  </li>
                </ul>
              </li>
            )}
          </ul>
        ))}
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <h2 className="text-2xl font-semibold underline">Address</h2>
        <ul>
          <li>
            <b>Title:</b> {order.address?.title}
          </li>
          <li>
            <b>Contact Name:</b> {order.address?.contactName}
          </li>
          <li>
            <b>Contact Number:</b> {order.address?.contactNumber}
          </li>
          <li>
            <b>Apartment:</b> {order.address?.apartment}
          </li>
          <li>
            <b>Floor:</b> {order.address?.floor}
          </li>
          <li>
            <b>Level:</b> {order.address?.level}
          </li>
          <li>
            <b>Street:</b> {order.address?.street}
          </li>
        </ul>

        <h2 className="text-2xl font-semibold underline">Slot</h2>
        <ul>
          <li>
            <b>Title:</b> {order.slot?.title}
          </li>
          <li>
            <b>Time:</b> {order.slot?.time}
          </li>
        </ul>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <h2 className="text-2xl font-semibold underline">User Info</h2>
        <ul>
          <li>
            <b>Name:</b> {order.user?.firstName + ' ' + order.user?.lastName}
          </li>
          <li>
            <b>Phone:</b> {order.user?.phoneNumber}
          </li>
          <li>
            <b>Email:</b> {order.user?.email}
          </li>
        </ul>

        <h2 className="text-2xl font-semibold underline">Family</h2>
        <ul>
          <li>
            <b>Relationship:</b>
            {order.family?.relationship}
          </li>
          <li>
            <b>Name:</b>
            {order.family?.firstName + ' ' + order.family?.lastName}
          </li>
          <li>
            <b>Gender:</b> {order.family?.gender}
          </li>
          <li>
            <b>Height:</b> {order.family?.height}
          </li>
          <li>
            <b>Weight:</b> {order.family?.weight}
          </li>
          <li>
            <b>Phone:</b> {order.family?.phoneNumber}
          </li>
        </ul>
      </Col>
    </Row>
  );
};

export default OrderDetails;
