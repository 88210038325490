import { ProviderService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useProviders hook ------------------------------------
type IUseProviders = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof ProviderService.filter>;
};
export const useProviders = ({ options, config }: IUseProviders = {}) => {
  return useQuery({
    ...config,
    queryKey: [ProviderService.NAME, options],
    queryFn: () => ProviderService.filter(options),
  });
};

//----------------------- useProvider hook --------------------------------------
type IUseProvider = {
  id: string;
  config?: QueryConfig<typeof ProviderService.filterById>;
};

export const useProvider = ({ id, config }: IUseProvider) => {
  return useQuery({
    ...config,
    queryFn: () => ProviderService.filterById(id),
  });
};

//------------------ useCreateProvider hook ---------------------------------
type IUseCreateProvider = {
  config?: MutationConfig<typeof ProviderService.create>;
};

export const useCreateProvider = ({ config }: IUseCreateProvider = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderService.create,
  });
};

//------------------ useUpdateProvider hook ----------------------------------
type IUseUpdateProvider = {
  config?: MutationConfig<typeof ProviderService.update>;
};

export const useUpdateProvider = ({ config }: IUseUpdateProvider = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderService.update,
  });
};

//------------------ useDeleteProvider hook ----------------------------------
type IUseDeleteProvider = {
  config?: MutationConfig<typeof ProviderService.delete>;
};

export const useDeleteProvider = ({ config }: IUseDeleteProvider = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(ProviderService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: ProviderService.delete,
  });
};
