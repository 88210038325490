import { OrderService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useOrders hook ------------------------------------
type IUseOrders = {
  options: IBaseFilter;
  config?: QueryConfig<typeof OrderService.filter>;
};
export const useOrders = ({ options, config }: IUseOrders) => {
  return useQuery({
    ...config,
    queryKey: [OrderService.NAME, options],
    queryFn: () => OrderService.filter(options),
  });
};

//----------------------- useOrder hook --------------------------------------
type IUseOrder = {
  id: string;
  config?: QueryConfig<typeof OrderService.filterById>;
};

export const useOrder = ({ id, config }: IUseOrder) => {
  return useQuery({
    ...config,
    queryFn: () => OrderService.filterById(id),
  });
};

//------------------ useCreateOrder hook ---------------------------------
type IUseCreateOrder = {
  config?: MutationConfig<typeof OrderService.create>;
};

export const useCreateOrder = ({ config }: IUseCreateOrder = {}) => {
  return useMutation({
    ...config,
    mutationFn: OrderService.create,
  });
};

//------------------ useUpdateOrder hook ----------------------------------
type IUseUpdateOrder = {
  config?: MutationConfig<typeof OrderService.update>;
};

export const useUpdateOrder = ({ config }: IUseUpdateOrder = {}) => {
  return useMutation({
    ...config,
    mutationFn: OrderService.update,
  });
};

//------------------ useDeleteOrder hook ----------------------------------
type IUseDeleteOrder = {
  config?: MutationConfig<typeof OrderService.delete>;
};

export const useDeleteOrder = ({ config }: IUseDeleteOrder = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(OrderService.NAME);
        notification.success({
          message: res?.data?.message || 'Order successfully deleted',
        });
      }
    },
    mutationFn: OrderService.delete,
  });
};
