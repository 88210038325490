import { DepartmentService } from '@shared/services';
import { IDepartmentFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useDepartments hook ------------------------------------
type IUseDepartments = {
  options?: IDepartmentFilter;
  config?: QueryConfig<typeof DepartmentService.filter>;
};
export const useDepartments = ({ options, config }: IUseDepartments = {}) => {
  return useQuery({
    ...config,
    queryKey: [DepartmentService.NAME, options],
    queryFn: () => DepartmentService.filter(options),
  });
};

//----------------------- useDepartment hook --------------------------------------
type IUseDepartment = {
  id: string;
  config?: QueryConfig<typeof DepartmentService.filterById>;
};

export const useDepartment = ({ id, config }: IUseDepartment) => {
  return useQuery({
    ...config,
    queryFn: () => DepartmentService.filterById(id),
  });
};

//------------------ useCreateDepartment hook ---------------------------------
type IUseCreateDepartment = {
  config?: MutationConfig<typeof DepartmentService.create>;
};

export const useCreateDepartment = ({ config }: IUseCreateDepartment = {}) => {
  return useMutation({
    ...config,
    mutationFn: DepartmentService.create,
  });
};

//------------------ useUpdateDepartment hook ----------------------------------
type IUseUpdateDepartment = {
  config?: MutationConfig<typeof DepartmentService.update>;
};

export const useUpdateDepartment = ({ config }: IUseUpdateDepartment = {}) => {
  return useMutation({
    ...config,
    mutationFn: DepartmentService.update,
  });
};

//------------------ useDeleteDepartment hook ----------------------------------
type IUseDeleteDepartment = {
  config?: MutationConfig<typeof DepartmentService.delete>;
};

export const useDeleteDepartment = ({ config }: IUseDeleteDepartment = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(DepartmentService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: DepartmentService.delete,
  });
};
