import { Authorization } from '@modules/auth';
import { useCreateProviderDepartment } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import ProviderDepartmentCreateForm from '../components/ProviderDepartmentCreateForm';

const ProviderDepartmentCreateRoute = () => {
  const navigate = useNavigate();

  const createProviderDepartment = useCreateProviderDepartment({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PROVIDER_DEPARTMENT_CREATE', 'FORBIDDEN']}>
      <PageHeader
        onBack={() => navigate(-1)}
        title="Create Provider Department"
      >
        <ProviderDepartmentCreateForm
          isLoading={createProviderDepartment.isLoading}
          onFinish={(values) => createProviderDepartment.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default ProviderDepartmentCreateRoute;
