import { Authorization } from '@modules/auth';
import { useAddress, useUpdateAddress } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import AddressUpdateForm from '../components/AddressUpdateForm';

const AddressUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useAddress({ id });

  const updateAddress = useUpdateAddress({
    config: {
      onSuccess: (res) => {
        if (res.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update Address">
          <AddressUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateAddress.isLoading}
            onFinish={(values) => updateAddress.mutateAsync({ ...values, id })}
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default AddressUpdateRoute;
