import { ProviderProductService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useProviderProducts hook ------------------------------------
type IUseProviderProducts = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof ProviderProductService.filter>;
};
export const useProviderProducts = ({
  options,
  config,
}: IUseProviderProducts = {}) => {
  return useQuery({
    ...config,
    queryKey: [ProviderProductService.NAME, options],
    queryFn: () => ProviderProductService.filter(options),
  });
};

//----------------------- useProviderProduct hook --------------------------------------
type IUseProviderProduct = {
  id: string;
  config?: QueryConfig<typeof ProviderProductService.filterById>;
};

export const useProviderProduct = ({ id, config }: IUseProviderProduct) => {
  return useQuery({
    ...config,
    queryFn: () => ProviderProductService.filterById(id),
  });
};

//------------------ useCreateProviderProduct hook ---------------------------------
type IUseCreateProviderProduct = {
  config?: MutationConfig<typeof ProviderProductService.create>;
};

export const useCreateProviderProduct = ({
  config,
}: IUseCreateProviderProduct = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderProductService.create,
  });
};

//------------------ useUpdateProviderProduct hook ----------------------------------
type IUseUpdateProviderProduct = {
  config?: MutationConfig<typeof ProviderProductService.update>;
};

export const useUpdateProviderProduct = ({
  config,
}: IUseUpdateProviderProduct = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderProductService.update,
  });
};

//------------------ useDeleteProviderProduct hook ----------------------------------
type IUseDeleteProviderProduct = {
  config?: MutationConfig<typeof ProviderProductService.delete>;
};

export const useDeleteProviderProduct = ({
  config,
}: IUseDeleteProviderProduct = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(ProviderProductService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: ProviderProductService.delete,
  });
};
