import { Modal } from 'antd';
import React, { CSSProperties, useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';

interface IProps {
  title?: string;
  src: string;
  alt?: string;
  style?: CSSProperties;
}
const ImagePreview: React.FC<IProps> = ({
  title,
  src,
  alt = 'icon',
  style,
}) => {
  const imageHolder = 'https://healthbondhu.com/images/image-holder.jpg';
  const [isView, setIsView] = useState(false);
  return (
    <>
      <div style={{ ...style }} className="image-preview">
        <img src={src || imageHolder} alt={alt} />
        <div className="view-handler">
          <AiOutlineEye onClick={() => setIsView(true)} />
        </div>
      </div>
      <Modal
        title={title || 'Image View'}
        centered
        width={500}
        footer={false}
        open={isView}
        onCancel={() => setIsView(false)}
      >
        <img className="h-full w-full" src={src || imageHolder} alt={alt} />
      </Modal>
    </>
  );
};
export default ImagePreview;
