import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import SubscriptionCreateRoute from './routes/SubscriptionCreateRoute';
import SubscriptionListRoute from './routes/SubscriptionListRoute';
import SubscriptionUpdateRoute from './routes/SubscriptionUpdateRoute';

export const SubscriptionRoutes = [
  {
    path: Paths.subscription,
    element: <Navigate to={Paths.subscriptionList} />,
  },
  { path: Paths.subscriptionList, element: <SubscriptionListRoute /> },
  { path: Paths.subscriptionCreate, element: <SubscriptionCreateRoute /> },
  { path: Paths.subscriptionUpdate(), element: <SubscriptionUpdateRoute /> },
];
