import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import DepartmentImageCreatePage from './routes/DepartmentImageCreatePage';
import DepartmentImagesPage from './routes/DepartmentImagesPage';
import DepartmentImageUpdatePage from './routes/DepartmentImageUpdatePage';

export const DepartmentImageRoutes = [
  { path: Paths.DepartmentImageList, element: <DepartmentImagesPage /> },
  { path: Paths.DepartmentImageCreate, element: <DepartmentImageCreatePage /> },
  {
    path: Paths.DepartmentImageUpdate(),
    element: <DepartmentImageUpdatePage />,
  },
  {
    path: Paths.DepartmentImage,
    element: <Navigate to={Paths.DepartmentImageList} />,
  },
];
