import { useDeleteMedicineOrder, useUpdateMedicineOrder } from '@shared/hooks';
import {
  Button,
  Form,
  Modal,
  PaginationProps,
  Popconfirm,
  Select,
  Space,
  Table,
} from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { IMedicineOrder } from '@shared/interfaces';
import ImagePreview from '@shared/components/ImagePreview';
import React, { useState } from 'react';
import { MedicineOrderService } from '@shared/services';
import { queryClient } from '@shared/config';
import { _ } from '@shared/utils';
import { getAccess } from '@modules/auth';

interface IProps {
  isLoading: boolean;
  data: IMedicineOrder[];
  pagination: PaginationProps;
}

const MedicineOrderList: React.FC<IProps> = ({
  isLoading,
  data,
  pagination,
}) => {
  const [form] = Form.useForm();
  const [editId, setEditId] = useState<string>();

  const updateOrder = useUpdateMedicineOrder({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          queryClient.invalidateQueries(MedicineOrderService.NAME);
          setEditId(null);
        }
      },
    },
  });

  const deleteMedicineOrder = useDeleteMedicineOrder();

  const dataSource = data?.map((x: IMedicineOrder) => ({
    key: x?.id,
    id: x?.id,
    prescription: x?.file,
    user: x?.user?.firstName,
    phone: x?.user?.phoneNumber,
    email: x?.user?.email,
    status: x?.status,
    createdAt: _.getStutteredDate(x?.createdAt),
  }));

  const columns = [
    {
      title: 'prescription',
      dataIndex: 'prescription',
      key: 'prescription',
      render: (prescription: string) => (
        <ImagePreview
          title="Prescription"
          src={prescription}
          alt="prescription"
        />
      ),
    },
    {
      title: 'user',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={`order-status ${status}`}>{status}</span>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string, record) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() =>
              getAccess(['ORDER_UPDATE', 'FORBIDDEN'], () => {
                setEditId(id);
                form.setFieldsValue({ status: record.status });
              })
            }
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it?"
            onConfirm={() =>
              getAccess(['ORDER_DELETE', 'FORBIDDEN'], () =>
                deleteMedicineOrder.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={pagination}
      />
      <Modal
        centered
        title="Update Status"
        width={250}
        visible={editId ? true : false}
        onCancel={() => setEditId(null)}
        onOk={() => setEditId(null)}
        footer={false}
      >
        <Form
          size="large"
          layout="vertical"
          form={form}
          onFinish={(val) => updateOrder.mutateAsync({ id: editId, ...val })}
        >
          <Form.Item name="status">
            <Select placeholder="Select a status">
              <Select.Option value="PENDING">PENDING</Select.Option>
              <Select.Option value="ACCEPTED">ACCEPTED</Select.Option>
              <Select.Option value="REJECTED">REJECTED</Select.Option>
              <Select.Option value="COMPLETED">COMPLETED</Select.Option>
            </Select>
          </Form.Item>
          <div className="text-right">
            <Button
              loading={updateOrder.isLoading}
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default MedicineOrderList;
