import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useDiagnosticPrescriptions } from '@shared/hooks';
import { _ } from '@shared/utils';
import { Button, PageHeader } from 'antd';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DiagnosticPrescriptionList from '../components/DiagnosticPrescriptionList';
import DiagnosticPrescriptionQueryForm from '../components/DiagnosticPrescriptionQueryForm';

const DiagnosticPrescriptionsRoute = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState({
    user: searchParams.get('user'),
    status: searchParams.get('status'),
  });

  const { data, isLoading } = useDiagnosticPrescriptions({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      s: _.toCleanObject({
        'user.id': query?.user,
        status: query?.status,
      }),
      sort: ['createdAt,DESC'],
    },
  });
  console.log(data);
  return (
    <Authorization allowedAccess={['ORDER_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Diagnostic Prescription List"
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.DiagnosticPrescriptionCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <DiagnosticPrescriptionQueryForm
          initialValues={_.toCleanObject({ ...query })}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        />
        <DiagnosticPrescriptionList
          isLoading={isLoading}
          data={data?.data?.payload}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};

export default DiagnosticPrescriptionsRoute;
