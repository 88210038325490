import { ISlot, ISlotCreate } from '@shared/interfaces';
import { CustomUpload } from '@shared/utils';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { useState } from 'react';

interface IProps {
  initialValues?: ISlot;
  onFinish?: (values: ISlotCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const SlotCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const [images, setImages] = useState({
    icon: '',
    image: '',
    backgroundImage: '',
  });

  const onSubmitForm = (values: ISlotCreate) => {
    values.icon = images.icon;
    values.image = images.image;
    values.backgroundImage = images.backgroundImage;
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter a title!',
              },
            ]}
          >
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Time"
            name="time"
            rules={[
              {
                required: true,
                message: 'Please enter a time slot!',
              },
            ]}
          >
            <Input placeholder="Example: 10:00 AM - 12:00 PM" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Icon" name="icon">
            <CustomUpload
              onChange={(url) => setImages({ ...images, icon: url })}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <CustomUpload
              onChange={(url) => setImages({ ...images, image: url })}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Background Image" name="backgroundImage">
            <CustomUpload
              onChange={(url) => setImages({ ...images, backgroundImage: url })}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SlotCreateForm;
