import { Authorization } from '@modules/auth';
import { usePackage, useUpdatePackage } from '@shared/hooks';
import { IPackageUpdate } from '@shared/interfaces';
import { Purify, _ } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import PackageUpdateForm from '../components/PackageUpdateForm';

const PackageUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = usePackage({ id });

  const updatePackage = useUpdatePackage({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  const initialValues: IPackageUpdate = {
    ...data?.data?.payload,
    department: data?.data?.payload?.department?.id,
    category: data?.data?.payload?.category?.id,
    subCategory: data?.data?.payload?.subCategory?.id,
    paymentMethod: _.jsonParser(data?.data?.payload?.paymentMethod),
    instructions: _.jsonParser(data?.data?.payload?.instructions),
    thisServiceCovers: _.jsonParser(data?.data?.payload?.thisServiceCovers),
  };

  return (
    <Authorization allowedAccess={['PRODUCT_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update Package">
          <PackageUpdateForm
            initialValues={initialValues}
            isLoading={updatePackage.isLoading}
            onFinish={(values) => updatePackage.mutateAsync({ ...values, id })}
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default PackageUpdateRoute;
