import { useDeleteFeaturedItem } from '@shared/hooks';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@shared/constant';
import { IFeaturedItem } from '@shared/interfaces';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import ImagePreview from '@shared/components/ImagePreview';
import { getAccess } from '@modules/auth';

interface IProps {
  isLoading: boolean;
  data: IFeaturedItem[];
  pagination: PaginationProps;
}
const FeaturedItemList: React.FC<IProps> = ({
  isLoading,
  data,
  pagination,
}) => {
  const navigate = useNavigate();

  const deleteFeaturedItem = useDeleteFeaturedItem();

  const dataSource = data?.map((x: IFeaturedItem) => ({
    key: x.id,
    id: x.id,
    isActive: x.isActive.toString(),
    type: x.type,
    provider: x.provider?.name,
    providerProduct: x.providerProduct?.title,
    providerPackage: x.providerPackage?.title,
    image: x.image,
  }));

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'ProviderProduct',
      dataIndex: 'providerProduct',
      key: 'providerProduct',
    },
    {
      title: 'ProviderPackage',
      dataIndex: 'providerPackage',
      key: 'providerPackage',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="thumbImage" />,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.FeaturedItemUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it?"
            onConfirm={() =>
              getAccess(['FEATURED_DELETE', 'FORBIDDEN'], () =>
                deleteFeaturedItem.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
    />
  );
};

export default FeaturedItemList;
