import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import PermissionCreateRoute from './route/PermissionCreateRoute';
import PermissionListRoute from './route/PermissionListRoute';
import PermissionUpdateRoute from './route/PermissionUpdateRoute';

export const PermissionRoutes = [
  {
    path: Paths.Permission,
    element: <Navigate to={Paths.PermissionList} />,
  },
  { path: Paths.PermissionList, element: <PermissionListRoute /> },
  { path: Paths.PermissionCreate, element: <PermissionCreateRoute /> },
  { path: Paths.PermissionUpdate(), element: <PermissionUpdateRoute /> },
];
