import { Authorization } from '@modules/auth';
import { useDistrict, useUpdateDistrict } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import DistrictUpdateForm from '../components/DistrictUpdateForm';

const DistrictUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useDistrict({ id });

  const updateDistrict = useUpdateDistrict({
    config: {
      onSuccess: (res) => {
        if (res.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update District">
          <DistrictUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateDistrict.isLoading}
            onFinish={(values) => updateDistrict.mutateAsync({ ...values, id })}
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default DistrictUpdateRoute;
