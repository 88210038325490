import { useFamilies, useProviders, useUsers } from '@shared/hooks';
import { IFamily, IProvider, IUser } from '@shared/interfaces';
import { _ } from '@shared/utils';
import { Col, Form, Row, Select } from 'antd';
import { useState } from 'react';

interface IProps {
  initialValues: object;
  onValuesChange: (c, v) => void;
}
const OrderQueryForm: React.FC<IProps> = ({
  initialValues,
  onValuesChange,
}) => {
  const [selectedUser, setSelectedUser] = useState('');

  const users: IUser[] = useUsers()?.data?.data?.payload;
  const providers: IProvider[] = useProviders()?.data?.data?.payload;
  const families: IFamily[] = useFamilies({
    options: {
      s: _.toCleanObject({
        'user.id': selectedUser,
      }),
    },
  }).data?.data?.payload;

  return (
    <Form
      size="large"
      layout="vertical"
      className="data-query-form"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="user" name="user">
            <Select
              placeholder="Select a user"
              showSearch
              allowClear
              onChange={(val) => setSelectedUser(val)}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users?.map((user) => (
                <Select.Option key={user?.id} value={user?.id}>
                  {`${user?.firstName} ${user?.lastName} - ${user?.phoneNumber}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Family" name="family">
            <Select
              allowClear
              showSearch
              disabled={!selectedUser}
              placeholder="Select  a family"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {families?.map((family) => (
                <Select.Option key={family.id} value={family.id}>
                  {`${family.firstName} ${family.lastName}(${family.relationship})-${family.phoneNumber}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="provider" name="provider">
            <Select
              showSearch
              allowClear
              placeholder="Select a provider"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {providers?.map((pv) => (
                <Select.Option key={pv?.id} value={pv?.id}>
                  {pv?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="status" name="status">
            <Select allowClear placeholder="Select a status">
              <Select.Option value="PLACED">PLACED</Select.Option>
              <Select.Option value="CONFIRMED">CONFIRMED</Select.Option>
              <Select.Option value="COMPLETED">COMPLETED</Select.Option>
              <Select.Option value="CANCELED">CANCELED</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderQueryForm;
