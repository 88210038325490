import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { Button, Input, PageHeader } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import ProviderProductList from '../components/ProviderProductList';

const ProviderProductsPage = () => {
  const navigate = useNavigate();
  return (
    <Authorization allowedAccess={['PROVIDER_PRODUCT_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Provider Product List"
        subTitle={
          <Input
            size="large"
            placeholder="Search"
            prefix={<AiOutlineSearch />}
            style={{ borderRadius: 30 }}
          />
        }
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.ProviderProductCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <ProviderProductList />
      </PageHeader>
    </Authorization>
  );
};

export default ProviderProductsPage;
