import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import ProviderCreatePage from './routes/ProviderCreatePage';
import ProviderDetailsPage from './routes/ProviderDetailsPage';
import ProvidersPage from './routes/ProvidersPage';
import ProviderUpdatePage from './routes/ProviderUpdatePage';

export const ProviderRoutes = [
  { path: Paths.ProviderList, element: <ProvidersPage /> },
  { path: Paths.ProviderCreate, element: <ProviderCreatePage /> },
  { path: Paths.ProviderUpdate(), element: <ProviderUpdatePage /> },
  { path: Paths.ProviderDetails(), element: <ProviderDetailsPage /> },
  { path: Paths.Provider, element: <Navigate to={Paths.ProviderList} /> },
];
