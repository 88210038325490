import { useEffect, useState } from 'react';
import { useUsers } from '@shared/hooks';
import { Button, Drawer, Space, Switch, Table } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IUser } from '@shared/interfaces';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  useCreateUserRole,
  useUpdateUserRole,
  useUserRoles,
} from '@shared/hooks';
import { _ } from '@shared/utils';
import { Paths } from '@shared/constant';

interface IProps {
  searchTerm?: string;
}
const UserRoleList: React.FC<IProps> = ({ searchTerm }) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const query = {
    page: _.toNumber(searchParams.get('page')) || 1,
    limit: _.toNumber(searchParams.get('limit')) || 10,
  };

  const { data, isLoading } = useUsers({
    options: {
      page: query.page,
      limit: query.limit,
      searchTerm: searchTerm,
      filter: ['role||$ne||Customer'],
    },
  });

  const [roleDrawerUID, setRoleDrawerUID] = useState('');

  const assignRole = useCreateUserRole();
  const anAssignRole = useUpdateUserRole();
  const roles = useUserRoles({
    userId: roleDrawerUID,
    options: {
      page: 1,
      limit: 300,
    },
    config: {},
  });

  useEffect(() => {
    roles.refetch();
  }, [roleDrawerUID, assignRole.isSuccess, anAssignRole.isSuccess, roles]);

  const dataSource = data?.data?.payload?.map((x: IUser) => ({
    key: x?.id,
    id: x?.id,
    name: `${x?.firstName} ${x?.lastName}`,
    phone: x?.phoneNumber,
    role: x.role,
  }));

  const columns: any = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <Space>
          <Button type="primary" onClick={() => setRoleDrawerUID(id)}>
            Assign Role
          </Button>
          <Button
            type="primary"
            onClick={() => navigate(`${Paths.userUpdate}/${id}`)}
          >
            Update
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={{
          pageSize: 10,
          current: query.page,
          total: data?.data?.total,
          onChange: (page: any, limit: any) => {
            setSearchParams({ page, limit });
          },
        }}
      />
      <Drawer
        title="Assign User Roles"
        placement="right"
        onClose={() => setRoleDrawerUID('')}
        visible={roleDrawerUID !== '' ? true : false}
      >
        {roles?.data?.data?.payload?.map((role) => (
          <div key={role?.id} className="flex justify-between py-4 border-b">
            <h3>{role.title}</h3>
            <Switch
              // loading={assignRole.isLoading || anAssignRole.isLoading}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={role?.isAssigned}
              onChange={() => {
                if (role?.isAssigned)
                  return anAssignRole.mutateAsync({
                    userId: roleDrawerUID,
                    role: role?.id,
                  });

                return assignRole.mutateAsync({
                  userId: roleDrawerUID,
                  role: role?.id,
                });
              }}
            />
          </div>
        ))}
      </Drawer>
    </>
  );
};

export default UserRoleList;
