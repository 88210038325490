import {
  Button,
  Col,
  Form,
  Row,
  Select,
  notification,
  Radio,
  InputNumber,
} from 'antd';
import { AxiosResponse } from 'axios';
import { ICreateBanner } from '@shared/interfaces';
import { queryClient } from '@shared/config';
import React, { useState } from 'react';
import { useCreateBanner } from '@shared/hooks';
import { BannerService } from '@shared/services';
import { CustomUpload } from '@shared/utils';

interface IFProps {
  onSubmit: () => void;
}

const BannerCreateForm: React.FC<IFProps> = ({ onSubmit }) => {
  const createBanner = useCreateBanner({
    config: {
      onSuccess: (res: AxiosResponse) => {
        if (res?.data?.success) {
          onSubmit();
          queryClient.invalidateQueries(BannerService.NAME);
          notification.success({
            type: 'success',
            message: res?.data?.message,
          });
        } else {
          notification.error({
            type: 'error',
            message: res?.data?.message || 'Something is wrong!',
          });
        }
      },
    },
  });

  const [image, setImage] = useState('');

  const [form] = Form.useForm();
  const onFinish = (values: ICreateBanner) => {
    values.link = image;
    createBanner.mutateAsync(values);
    form.resetFields();
    setImage('');
  };

  return (
    <div className="banner-create-form">
      <Form form={form} size="large" layout="vertical" onFinish={onFinish}>
        <Row gutter={{ xs: 10, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Banner Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please input a type!',
                },
              ]}
            >
              <Select placeholder="Select a type">
                <Select.Option value="HERO_SLIDER">HERO_SLIDER</Select.Option>
                <Select.Option value="OFFER_SLIDER">OFFER_SLIDER</Select.Option>
                <Select.Option value="COUPON_SLIDER">
                  COUPON_SLIDER
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Is Active"
              name="isActive"
              rules={[
                {
                  required: true,
                  message: 'Please chose a value!',
                },
              ]}
            >
              <Radio.Group buttonStyle="solid" className="w-full">
                <Radio.Button className="w-1/2 text-center" value={true}>
                  True
                </Radio.Button>
                <Radio.Button className="w-1/2 text-center" value={false}>
                  False
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label="Priority" name="priority">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Enter  priority"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Image" name="link">
              <CustomUpload onChange={(url) => setImage(url)} />
            </Form.Item>
          </Col>
        </Row>

        <div className="text-right">
          <Button
            loading={createBanner.isLoading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BannerCreateForm;
