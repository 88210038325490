import { Authorization } from '@modules/auth';
import { useCreateProviderDiscount } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import ProviderDiscountCreateForm from '../components/ProviderDiscountCreateForm';

const ProviderDiscountCreateRoute = () => {
  const navigate = useNavigate();

  const createProviderDiscount = useCreateProviderDiscount({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PROVIDER_DISCOUNT_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Provider Discount">
        <ProviderDiscountCreateForm
          isLoading={createProviderDiscount.isLoading}
          onFinish={(values) => createProviderDiscount.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default ProviderDiscountCreateRoute;
