import { DivisionService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useDivisions hook ------------------------------------
type IUseDivisions = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof DivisionService.filter>;
};
export const useDivisions = ({ options, config }: IUseDivisions = {}) => {
  return useQuery({
    ...config,
    queryKey: [DivisionService.NAME, options],
    queryFn: () => DivisionService.filter(options),
  });
};

//----------------------- useDivision hook --------------------------------------
type IUseDivision = {
  id: string;
  config?: QueryConfig<typeof DivisionService.filterById>;
};

export const useDivision = ({ id, config }: IUseDivision) => {
  return useQuery({
    ...config,
    queryFn: () => DivisionService.filterById(id),
  });
};

//------------------ useCreateDivision hook ---------------------------------
type IUseCreateDivision = {
  config?: MutationConfig<typeof DivisionService.create>;
};

export const useCreateDivision = ({ config }: IUseCreateDivision = {}) => {
  return useMutation({
    ...config,
    mutationFn: DivisionService.create,
  });
};

//------------------ useUpdateDivision hook ----------------------------------
type IUseUpdateDivision = {
  config?: MutationConfig<typeof DivisionService.update>;
};

export const useUpdateDivision = ({ config }: IUseUpdateDivision = {}) => {
  return useMutation({
    ...config,
    mutationFn: DivisionService.update,
  });
};

//------------------ useDeleteDivision hook ----------------------------------
type IUseDeleteDivision = {
  config?: MutationConfig<typeof DivisionService.delete>;
};

export const useDeleteDivision = ({ config }: IUseDeleteDivision = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(DivisionService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: DivisionService.delete,
  });
};
