import { Authorization } from '@modules/auth';
import { useSubcategory, useUpdateSubcategory } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import SubcategoryUpdateForm from '../components/SubcategoryUpdateForm';

const SubcategoryUpdateRoute = () => {
  const navigate = useNavigate();
  const { id }: any = useParams();
  const { data, isLoading } = useSubcategory({ id });

  const updateSubcategory = useUpdateSubcategory({
    config: {
      onSuccess: (res) => {
        if (res.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['CATEGORY_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading} empty={false}>
        <PageHeader onBack={() => navigate(-1)} title="Update subcategory">
          <SubcategoryUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateSubcategory.isLoading}
            onFinish={(values) =>
              updateSubcategory.mutateAsync({ ...values, id })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default SubcategoryUpdateRoute;
