import React from 'react';
import { Paths } from '@shared/constant';
import { useDeleteProduct, useProducts } from '@shared/hooks';
import { IProduct } from '@shared/interfaces';
import { Button, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit, AiOutlineBars } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import ImagePreview from '@shared/components/ImagePreview';
import { getAccess } from '@modules/auth';

interface IProps {
  searchTerm?: string;
}
const ProductList: React.FC<IProps> = ({ searchTerm }) => {
  const navigate = useNavigate();

  const deleteProduct = useDeleteProduct();

  const { search } = useLocation();
  const query = {
    page: parseInt(search.slice(6, 7)) || 1,
    limit: parseInt(search.slice(13)) || 10,
  };

  const { data, isLoading } = useProducts({
    options: {
      page: query.page,
      limit: query.limit,
      searchTerm: searchTerm,
      sort: ['createdAt,DESC'],
    },
  });

  const dataSource = data?.data?.payload?.map((x: IProduct) => ({
    key: x.id,
    id: x.id,
    title: x.title,
    description: x.description,
    sampleType: x.sampleType,
    priority: x.priority,
    isActive: x.isActive.toString(),
    isSingleOrder: x.isSingleOrder.toString(),
    isAddableToCart: x.isAddableToCart.toString(),
    isOrderAvailable: x.isOrderAvailable.toString(),
    department: x.department?.title,
    category: x.category?.title,
    subCategory: x.subCategory?.title,
    icon: x.icon,
    image: x.image,
    backgroundImage: x.backgroundImage,
    whatIs: x.whatIs,
    thisServiceCovers: x.thisServiceCovers,
    whyYouShouldTake: x.whyYouShouldTake,
    instructions: x.instructions,
    applicableFor: x.applicableFor,
    ageGroup: x.ageGroup,
  }));

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Subcategory',
      dataIndex: 'subCategory',
      key: 'subCategory',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon: string) => <ImagePreview src={icon} alt="icon" />,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="thumbImage" />,
    },
    {
      title: 'BG Image',
      dataIndex: 'backgroundImage',
      key: 'backgroundImage',
      render: (backgroundImage: string) => (
        <ImagePreview src={backgroundImage} alt="BgImage" />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.ProductUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Package?"
            onConfirm={() =>
              getAccess(['PRODUCT_DELETE', 'FORBIDDEN'], () =>
                deleteProduct.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.ProductDetails(id))}
          >
            <AiOutlineBars />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: query.page,
        total: data?.data?.total,
        onChange: (page: number, limit: number) => {
          navigate(`?page=${page}&limit=${limit}`);
        },
      }}
    />
  );
};

export default ProductList;
