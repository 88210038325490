import {
  MdOutlineFace,
  MdOutlineFeaturedPlayList,
  MdOutlineSubscriptions,
  MdSecurity,
} from 'react-icons/md';
import { MenuProps } from 'antd';
import {
  AiOutlineBars,
  AiOutlineBorderlessTable,
  AiOutlineDashboard,
  AiOutlineLogout,
  AiOutlineSafety,
  AiOutlineUser,
  AiOutlineWifi,
} from 'react-icons/ai';
import { BiCategory } from 'react-icons/bi';
import { FiPackage } from 'react-icons/fi';
import { FaRegAddressCard } from 'react-icons/fa';
import { Paths } from '@shared/constant';
import { Link } from 'react-router-dom';
import { _, storage } from '@shared/utils';

const welcomeMenu: MenuProps['items'] = [
  {
    key: 'Profile',
    icon: <AiOutlineUser />,
    label: 'Profile',
  },
  {
    key: 'Security',
    icon: <AiOutlineSafety />,
    label: 'Security',
  },
  {
    key: 'Logout',
    icon: <AiOutlineLogout />,
    label: 'Logout',
    onClick: () => {
      storage.removeToken();
      window.location.reload();
    },
  },
];

// main menu items
const mainMenu: MenuProps['items'] = [
  {
    key: Paths.defaultDashboard,
    icon: <AiOutlineDashboard />,
    label: <Link to={Paths.defaultDashboard}>Dashboard</Link>,
  },
  {
    key: 'Authorization',
    icon: <MdSecurity />,
    label: 'Authorization',
    children: [
      {
        key: Paths.RoleList,
        label: <Link to={_.appendPagination(Paths.RoleList)}>Roles</Link>,
      },
      {
        key: Paths.UserRoleList,
        label: (
          <Link to={_.appendPagination(Paths.UserRoleList)}>User Roles</Link>
        ),
      },
      {
        key: Paths.PermissionList,
        label: (
          <Link to={_.appendPagination(Paths.PermissionList)}>Permissions</Link>
        ),
      },
    ],
  },
  {
    key: Paths.users,
    icon: <MdOutlineFace />,
    label: <Link to={_.appendPagination(Paths.users)}>Users</Link>,
  },
  {
    key: Paths.subscription,
    icon: <MdOutlineSubscriptions />,
    label: (
      <Link to={_.appendPagination(Paths.subscriptionList)}>Subscriptions</Link>
    ),
  },
  {
    key: 'Department',
    icon: <AiOutlineWifi />,
    label: 'Department',
    children: [
      {
        key: Paths.BaseDepartmentList,
        label: (
          <Link to={_.appendPagination(Paths.BaseDepartmentList)}>
            Base Department
          </Link>
        ),
      },
      {
        key: Paths.DepartmentList,
        label: (
          <Link to={_.appendPagination(Paths.DepartmentList)}>Departments</Link>
        ),
      },
      {
        key: Paths.DepartmentImageList,
        label: (
          <Link to={_.appendPagination(Paths.DepartmentImageList)}>
            Department Images
          </Link>
        ),
      },
    ],
  },
  {
    key: 'Category',
    icon: <BiCategory />,
    label: 'Category',
    children: [
      {
        key: Paths.CategorieList,
        label: (
          <Link to={_.appendPagination(Paths.CategorieList)}>Categories</Link>
        ),
      },
      {
        key: Paths.SubCategoryList,
        label: (
          <Link to={_.appendPagination(Paths.SubCategoryList)}>
            Sub Categories
          </Link>
        ),
      },
    ],
  },
  {
    key: 'Featured',
    icon: <MdOutlineFeaturedPlayList />,
    label: 'Featured',
    children: [
      {
        key: Paths.FeaturedItemList,
        label: (
          <Link to={_.appendPagination(Paths.FeaturedItemList)}>
            Featured Item
          </Link>
        ),
      },
      {
        key: Paths.BannerList,
        label: <Link to={_.appendPagination(Paths.BannerList)}>Banner</Link>,
      },
      {
        key: Paths.OfferList,
        label: <Link to={_.appendPagination(Paths.OfferList)}>Offers</Link>,
      },
    ],
  },
  {
    key: Paths.PackageList,
    icon: <FiPackage />,
    label: <Link to={_.appendPagination(Paths.PackageList)}>Packages</Link>,
  },
  {
    key: Paths.ProductList,
    icon: <FiPackage />,
    label: <Link to={_.appendPagination(Paths.ProductList)}>Products</Link>,
  },
  {
    key: 'Providers',
    icon: <AiOutlineBorderlessTable />,
    label: 'Providers',
    children: [
      {
        key: Paths.ProviderList,
        label: (
          <Link to={_.appendPagination(Paths.ProviderList)}>Providers</Link>
        ),
      },
      {
        key: Paths.ProviderProductList,
        label: (
          <Link to={_.appendPagination(Paths.ProviderProductList)}>
            Provider Products
          </Link>
        ),
      },
      {
        key: Paths.ProviderPackageList,
        label: (
          <Link to={_.appendPagination(Paths.ProviderPackageList)}>
            Provider Packages
          </Link>
        ),
      },
      {
        key: Paths.ProviderDiscounList,
        label: (
          <Link to={_.appendPagination(Paths.ProviderDiscounList)}>
            Provider Discounts
          </Link>
        ),
      },
      {
        key: Paths.ProviderDepartmentList,
        label: (
          <Link to={_.appendPagination(Paths.ProviderDepartmentList)}>
            Provider Departments
          </Link>
        ),
      },
    ],
  },
  {
    key: 'Address',
    icon: <FaRegAddressCard />,
    label: 'Address',
    children: [
      {
        key: Paths.slots,
        label: <Link to={_.appendPagination(Paths.slots)}>Slots</Link>,
      },
      {
        key: Paths.DivisionList,
        label: (
          <Link to={_.appendPagination(Paths.DivisionList)}>Divisions</Link>
        ),
      },
      {
        key: Paths.DistrictList,
        label: (
          <Link to={_.appendPagination(Paths.DistrictList)}>Districts</Link>
        ),
      },
      {
        key: Paths.ZoneList,
        label: <Link to={_.appendPagination(Paths.ZoneList)}>Zones</Link>,
      },
      {
        key: Paths.AddressList,
        label: (
          <Link to={_.appendPagination(Paths.AddressList)}>Addresses</Link>
        ),
      },
    ],
  },
  {
    key: 'Order',
    icon: <AiOutlineBars />,
    label: 'Order',
    children: [
      {
        key: Paths.OrderList,
        label: <Link to={_.appendPagination(Paths.OrderList)}>Orders</Link>,
      },
      {
        key: Paths.CallRequestList,
        label: (
          <Link to={_.appendPagination(Paths.CallRequestList)}>
            Call Requests
          </Link>
        ),
      },
      {
        key: Paths.MedicineOrderList,
        label: (
          <Link to={_.appendPagination(Paths.MedicineOrderList)}>
            Medicine Orders
          </Link>
        ),
      },
      {
        key: Paths.DiagnosticPrescriptionList,
        label: (
          <Link to={_.appendPagination(Paths.DiagnosticPrescriptionList)}>
            Diagnostic Prescriptions
          </Link>
        ),
      },
      {
        key: Paths.AmbulanceRequests,
        label: (
          <Link to={_.appendPagination(Paths.AmbulanceRequests)}>
            Ambulance Requests
          </Link>
        ),
      },
      {
        key: Paths.BloodRequests,
        label: (
          <Link to={_.appendPagination(Paths.BloodRequests)}>
            Blood Requests
          </Link>
        ),
      },
    ],
  },
];

const menuItems = {
  welcomeMenu,
  mainMenu,
};

export default menuItems;
