import { IFamily } from '@shared/interfaces';
import { Table } from 'antd';

interface IProps {
  user?: string;
  families?: IFamily[];
}
const FamiliesList: React.FC<IProps> = ({ user, families }) => {
  const dataSource = families?.map((x: IFamily) => ({
    key: x.id,
    id: x.id,
    firstName: x.firstName,
    lastName: x.lastName,
    phoneNumber: x.phoneNumber,
    relationship: x.relationship,
    gender: x.gender,
    dob: x.dob,
    weight: x.weight,
    height: x.height,
  }));

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'relationship',
      dataIndex: 'relationship',
      key: 'relationship',
    },
    {
      title: 'gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'dob',
      dataIndex: 'dob',
      key: 'dob',
    },
    {
      title: 'weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'height',
      dataIndex: 'height',
      key: 'height',
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      title={() => <h1 className="text-center">{user}'s families</h1>}
    />
  );
};
export default FamiliesList;
