import { Authorization } from '@modules/auth';
import { useCreateBaseDepartment } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import BaseDepartmentCreateForm from '../components/BaseDepartmentCreateForm';

const BaseDepartmentCreatePage = () => {
  const navigate = useNavigate();

  const createBaseDepartment = useCreateBaseDepartment({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['DEPARTMENT_CREATE', 'FORBIDDEN']}>
      <PageHeader
        onBack={() => window.history.back()}
        title="Create Base Department"
      >
        <BaseDepartmentCreateForm
          isLoading={createBaseDepartment.isLoading}
          onFinish={(values) => createBaseDepartment.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default BaseDepartmentCreatePage;
