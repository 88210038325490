import { Authorization } from '@modules/auth';
import { useCreatePackage } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import PackageCreateForm from '../components/PackageCreateForm';

const PackageCreateRoute = () => {
  const navigate = useNavigate();

  const createPackage = useCreatePackage({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PACKAGE_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Package">
        <PackageCreateForm
          isLoading={createPackage.isLoading}
          onFinish={(values) => createPackage.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default PackageCreateRoute;
