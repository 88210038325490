import { Paths } from '@shared/constant';
import CallRequestCreatePage from './routes/CallRequestCreatePage';
import CallRequestDetailsRoute from './routes/CallRequestDetailsRoute';
import CallRequestListRoute from './routes/CallRequestListRoute';
import CallRequestUpdatePage from './routes/CallRequestUpdatePage';

export const CallRequestsRoutes = [
  { path: Paths.CallRequestList, element: <CallRequestListRoute /> },
  { path: Paths.CallRequestCreate, element: <CallRequestCreatePage /> },
  { path: Paths.CallRequestUpdate(), element: <CallRequestUpdatePage /> },
  { path: Paths.CallRequestDetails(), element: <CallRequestDetailsRoute /> },
];
