import { Authorization } from '@modules/auth';
import { usePackage } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import PackageDetails from '../components/PackageDetails';

const PackageDetailsRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = usePackage({ id });

  return (
    <Authorization allowedAccess={['PACKAGE_VIEW', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Package details">
          <PackageDetails Package={data?.data?.payload} />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default PackageDetailsRoute;
