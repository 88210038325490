import { useAddresses, useProviders, useUsers } from '@shared/hooks';
import {
  IAddress,
  IDiagnosticPrescriptionCreate,
  IProvider,
  IUser,
} from '@shared/interfaces';
import { CustomUpload, _ } from '@shared/utils';
import { Button, Col, Form, Row, Select } from 'antd';
import { useState } from 'react';
const { Option } = Select;

interface IProps {
  initialValues?: IDiagnosticPrescriptionCreate;
  onFinish?: (values: IDiagnosticPrescriptionCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const DiagnosticPrescriptionCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const users: IUser[] = useUsers().data?.data?.payload;
  const providers: IProvider[] = useProviders().data?.data?.payload;
  //   const [selectedUser, setSelectedUser] = useState('');
  const addressess = useAddresses({
    options: {
      s: _.toCleanObject({
        // user: selectedUser,
      }),
    },
  }).data?.data?.payload;

  const [images, setImages] = useState({
    file: '',
  });

  const onSubmitForm = (values: IDiagnosticPrescriptionCreate) => {
    values.file = images?.file;

    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 24, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="user"
            name="user"
            rules={[
              {
                required: true,
                message: 'Please Select user!',
              },
            ]}
          >
            <Select
              placeholder="Select a user"
              showSearch
              allowClear
              //   onChange={(val) => setSelectedUser(val)}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users?.map((user) => (
                <Option key={user?.id} value={user?.id}>
                  {`${user?.firstName} ${user?.lastName} - ${user?.phoneNumber}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="address"
            name="address"
            rules={[
              {
                required: true,
                message: 'Please Select address!',
              },
            ]}
          >
            <Select
              placeholder="Select address"
              showSearch
              allowClear
              //   disabled={!selectedUser}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {addressess?.map((add: IAddress) => (
                <Option key={add?.id} value={add?.id}>
                  {`${add?.contactName} - ${add?.contactNumber} - ${add?.level} `}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="provider"
            name="provider"
            rules={[
              {
                required: true,
                message: 'Please Select provider!',
              },
            ]}
          >
            <Select
              placeholder="Select a provider"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {providers?.map((pv) => (
                <Option key={pv?.id} value={pv?.id}>
                  {pv?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="file">
            <CustomUpload
              onChange={(url) => setImages({ ...images, file: url })}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DiagnosticPrescriptionCreateForm;
