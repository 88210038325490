import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import ProviderDepartmentCreateRoute from './routes/ProviderDepartmentCreateRoute';
import ProviderDepartmentsRoute from './routes/ProviderDepartmentsRoute';
import ProviderDepartmentUpdateRoute from './routes/ProviderDepartmentUpdateRoute';

export const ProviderDepartmentRoutes = [
  { path: Paths.ProviderDepartmentList, element: <ProviderDepartmentsRoute /> },
  {
    path: Paths.ProviderDepartmentCreate,
    element: <ProviderDepartmentCreateRoute />,
  },
  {
    path: Paths.ProviderDepartmentUpdate(),
    element: <ProviderDepartmentUpdateRoute />,
  },
  {
    path: Paths.ProviderDepartment,
    element: <Navigate to={Paths.ProviderDepartmentList} />,
  },
];
