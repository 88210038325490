import { DiagnosticPrescriptionService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useDiagnosticPrescriptions hook ------------------------------------
type IUseDiagnosticPrescriptions = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof DiagnosticPrescriptionService.filter>;
};
export const useDiagnosticPrescriptions = ({
  options,
  config,
}: IUseDiagnosticPrescriptions = {}) => {
  return useQuery({
    ...config,
    queryKey: [DiagnosticPrescriptionService.NAME, options],
    queryFn: () => DiagnosticPrescriptionService.filter(options),
  });
};

//----------------------- useDiagnosticPrescription hook --------------------------------------
type IUseDiagnosticPrescription = {
  id: string;
  config?: QueryConfig<typeof DiagnosticPrescriptionService.filterById>;
};

export const useDiagnosticPrescription = ({
  id,
  config,
}: IUseDiagnosticPrescription) => {
  return useQuery({
    ...config,
    queryFn: () => DiagnosticPrescriptionService.filterById(id),
  });
};

//------------------ useCreateDiagnosticPrescription hook ---------------------------------
type IUseCreateDiagnosticPrescription = {
  config?: MutationConfig<typeof DiagnosticPrescriptionService.create>;
};

export const useCreateDiagnosticPrescription = ({
  config,
}: IUseCreateDiagnosticPrescription = {}) => {
  return useMutation({
    ...config,
    mutationFn: DiagnosticPrescriptionService.create,
  });
};

//------------------ useUpdateDiagnosticPrescription hook ----------------------------------
type IUseUpdateDiagnosticPrescription = {
  config?: MutationConfig<typeof DiagnosticPrescriptionService.update>;
};

export const useUpdateDiagnosticPrescription = ({
  config,
}: IUseUpdateDiagnosticPrescription = {}) => {
  return useMutation({
    ...config,
    mutationFn: DiagnosticPrescriptionService.update,
  });
};

//------------------ useDeleteDiagnosticPrescription hook ----------------------------------
type IUseDeleteDiagnosticPrescription = {
  config?: MutationConfig<typeof DiagnosticPrescriptionService.delete>;
};

export const useDeleteDiagnosticPrescription = ({
  config,
}: IUseDeleteDiagnosticPrescription = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(DiagnosticPrescriptionService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: DiagnosticPrescriptionService.delete,
  });
};
