import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useOffers } from '@shared/hooks';
import { _ } from '@shared/utils';
import { useDebounce, useGetState } from 'ahooks';
import { Button, PageHeader } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OfferList from '../components/OfferList';
import OfferQueryForm from '../components/OfferQueryForm';

const OfferListRoute = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useGetState(
    _.toCleanObject({
      title: searchParams.get('title'),
      // 'family.id': searchParams.get('family'),
    })
  );

  let pureQuery = _.toCleanObject({ ...query });
  Object.keys(query)?.map(
    (key) => (pureQuery[key] = { $contL: pureQuery[key] })
  );
  const debouncedQuery = useDebounce(pureQuery, { wait: 1000 });

  const { data, isLoading } = useOffers({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      s: _.toCleanObject({
        title: debouncedQuery?.title,
        // 'family.id': query?.family,
      }),
      sort: ['createdAt,DESC'],
    },
  });

  return (
    <Authorization allowedAccess={['FEATURED_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Offers"
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={() => navigate(Paths.OfferCreate)}
          >
            Create
          </Button>,
        ]}
      >
        <OfferQueryForm
          initialValues={_.toCleanObject({ ...query })}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        />
        <OfferList
          isLoading={isLoading}
          data={data?.data?.payload}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};
export default OfferListRoute;
