import { CoreAxiosInstance } from '@shared/config';
import {
  IBaseFilter,
  ICallRequestCreate,
  ICallRequestResponse,
  ICallRequestsResponse,
  ICallRequestUpdate,
} from '@shared/interfaces';
import { _ } from '@shared/utils';
import { AxiosResponse } from 'axios';

const END_POINT: string = '/call-requests';

export const CallRequestService = {
  NAME: END_POINT,
  create(
    payload: ICallRequestCreate
  ): Promise<AxiosResponse<ICallRequestResponse>> {
    return CoreAxiosInstance.post(END_POINT, payload);
  },
  filter(options: IBaseFilter): Promise<AxiosResponse<ICallRequestsResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}?${_.queryNormalizer(options)}`);
  },
  filterById(id: string): Promise<AxiosResponse<ICallRequestResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}/${id}`);
  },
  update(
    payload: ICallRequestUpdate
  ): Promise<AxiosResponse<ICallRequestResponse>> {
    const { id } = payload;
    delete payload.id;
    return CoreAxiosInstance.patch(`${END_POINT}/${id}`, payload);
  },
  delete(id: string): Promise<AxiosResponse<ICallRequestResponse>> {
    return CoreAxiosInstance.delete(`${END_POINT}/${id}`);
  },
};
