import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { Button, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import PermissionList from '../components/PermissionList';

const PermissionListRoute = () => {
  const navigate = useNavigate();

  return (
    <Authorization allowedAccess={['PACKAGE_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Permission List"
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.PermissionCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <PermissionList />
      </PageHeader>
    </Authorization>
  );
};

export default PermissionListRoute;
