import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { Button, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import UserRoleList from '../Components/UserRoleList';

const UserRoleListRoute = () => {
  const navigate = useNavigate();

  return (
    <Authorization allowedAccess={['USER_ROLE_MANAGEMENT', 'FORBIDDEN']}>
      <PageHeader
        title="Role List"
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.userCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <UserRoleList />
      </PageHeader>
    </Authorization>
  );
};

export default UserRoleListRoute;
