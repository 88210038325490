import { SubscriptionService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useSubscriptions hook ------------------------------------
type IUseSubscriptions = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof SubscriptionService.filter>;
};
export const useSubscriptions = ({
  options,
  config,
}: IUseSubscriptions = {}) => {
  return useQuery({
    ...config,
    queryKey: [SubscriptionService.NAME, options],
    queryFn: () => SubscriptionService.filter(options),
  });
};

//----------------------- useSubscription hook --------------------------------------
type IUseSubscription = {
  id: string;
  config?: QueryConfig<typeof SubscriptionService.filterById>;
};

export const useSubscription = ({ id, config }: IUseSubscription) => {
  return useQuery({
    ...config,
    queryFn: () => SubscriptionService.filterById(id),
  });
};

//------------------ useCreateSubscription hook ---------------------------------
type IUseCreateSubscription = {
  config?: MutationConfig<typeof SubscriptionService.create>;
};

export const useCreateSubscription = ({
  config,
}: IUseCreateSubscription = {}) => {
  return useMutation({
    ...config,
    mutationFn: SubscriptionService.create,
  });
};

//------------------ useUpdateSubscription hook ----------------------------------
type IUseUpdateSubscription = {
  config?: MutationConfig<typeof SubscriptionService.update>;
};

export const useUpdateSubscription = ({
  config,
}: IUseUpdateSubscription = {}) => {
  return useMutation({
    ...config,
    mutationFn: SubscriptionService.update,
  });
};

//------------------ useDeleteSubscription hook ----------------------------------
type IUseDeleteSubscription = {
  config?: MutationConfig<typeof SubscriptionService.delete>;
};

export const useDeleteSubscription = ({
  config,
}: IUseDeleteSubscription = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(SubscriptionService.NAME);
        notification.success({
          message: 'Subscription successfully deleted',
        });
      }
    },
    mutationFn: SubscriptionService.delete,
  });
};
