import ImagePreview from '@shared/components/ImagePreview';
import { useCategories, useDepartments, useSubcategories } from '@shared/hooks';
import {
  ICategory,
  IDepartment,
  IPackageUpdate,
  ISubcategory,
} from '@shared/interfaces';
import { CustomUpload } from '@shared/utils';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlus } from 'react-icons/ai';
const { Option } = Select;

interface IProps {
  initialValues?: IPackageUpdate;
  onFinish?: (values: IPackageUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const PackageUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const departments: IDepartment[] = useDepartments().data?.data?.payload;

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const categories: ICategory[] = useCategories({
    options: {
      filter: selectedDepartment && [
        `department.id||$eq||${selectedDepartment}`,
      ],
    },
  }).data?.data?.payload;

  const [selectedCategory, setSelectedCategory] = useState(null);
  const subcategories: ISubcategory[] = useSubcategories({
    options: {
      filter: selectedCategory && [`category.id||$eq||${selectedCategory}`],
    },
  }).data?.data?.payload;

  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  // package update data purify
  const [images, setImages] = useState({
    icon: '',
    image: '',
    backgroundImage: '',
  });

  const onSubmitForm = (values: IPackageUpdate) => {
    values.icon = images.icon || initialValues.icon;
    values.image = images.image || initialValues.image;
    values.backgroundImage =
      images.backgroundImage || initialValues.backgroundImage;

    values.paymentMethod = JSON.stringify(values.paymentMethod);
    values.instructions = JSON.stringify(values.instructions);
    values.thisServiceCovers = JSON.stringify(values.thisServiceCovers);

    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter a title!',
              },
            ]}
          >
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Department"
            name="department"
            rules={[
              {
                required: true,
                message: 'Please select a department!',
              },
            ]}
          >
            <Select
              placeholder="Select a department"
              showSearch
              allowClear
              onChange={(val) => setSelectedDepartment(val)}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {departments?.map((d) => (
                <Option key={d.id} value={d.id}>
                  {d.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Category" name="category">
            <Select
              placeholder="Select a category"
              showSearch
              allowClear
              disabled={!selectedDepartment}
              onChange={(val) => setSelectedCategory(val)}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {categories?.map((ct) => (
                <Option key={ct.id} value={ct.id}>
                  {ct.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Sub Category" name="subCategory">
            <Select
              placeholder="Select a sub category"
              showSearch
              allowClear
              disabled={!selectedCategory}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {subcategories?.map((sct) => (
                <Option key={sct.id} value={sct.id}>
                  {sct.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Profile" name="profile">
            <Input placeholder="Enter profile" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Applicable For" name="applicableFor">
            <Input placeholder="Applicable For" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Age Group" name="ageGroup">
            <Input placeholder="Enter Age Group" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Available Offer" name="availableOffer">
            <Input placeholder="Enter Available Offer" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Payment Method" name="paymentMethod">
            <Select mode="multiple" placeholder="Select Payment Method">
              <Option value="cash">Cash</Option>
              <Option value="online">Online</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Available Provider" name="availableProvider">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter Available Provider"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Starting From" name="startingFrom">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Starting From"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item label="Priority" name="priority">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter priority"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Is Doctor Consultancy Available"
            name="isDoctorConsultancyAvailable"
          >
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Addable To Cart" name="isAddableToCart">
            <Radio.Group disabled buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Order Available" name="isOrderAvailable">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="What is this" name="whatIs">
            <Input.TextArea placeholder="Write something" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Why You Should take" name="whyYouShouldTake">
            <Input.TextArea placeholder="Write something" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Description" name="description">
            <Input.TextArea placeholder="Enter description" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.List name="thisServiceCovers">
            {(fields, { add, remove }) => (
              <div className="mt-4">
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className="flex gap-2 items-baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'icon']}
                      rules={[{ required: true, message: 'Missing icon' }]}
                    >
                      <Input placeholder="Icon Url" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'content']}
                      rules={[{ required: true, message: 'Missing content' }]}
                      className="w-full"
                    >
                      <Input placeholder="Enter  content" />
                    </Form.Item>
                    <AiOutlineMinusCircle
                      className="text-xl cursor-pointer"
                      onClick={() => remove(name)}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    block
                    type="dashed"
                    onClick={() =>
                      add({ icon: 'https://healthbondhu.com/images/radio.svg' })
                    }
                    icon={<AiOutlinePlus className="inline mr-1 mb-1" />}
                  >
                    Add Service Covers
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.List name="instructions">
            {(fields, { add, remove }) => (
              <div className="mt-4">
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className="flex gap-2 items-baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'icon']}
                      rules={[{ required: true, message: 'Missing icon' }]}
                    >
                      <Input placeholder="Icon Url" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'content']}
                      rules={[{ required: true, message: 'Missing content' }]}
                      className="w-full"
                    >
                      <Input placeholder="Enter  content" />
                    </Form.Item>
                    <AiOutlineMinusCircle
                      className="text-xl cursor-pointer"
                      onClick={() => remove(name)}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    block
                    type="dashed"
                    onClick={() =>
                      add({ icon: 'https://healthbondhu.com/images/radio.svg' })
                    }
                    icon={<AiOutlinePlus className="inline mr-1 mb-1" />}
                  >
                    Add Instructions
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Icon" name="icon">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.icon || initialValues.icon}
                  alt="icon"
                />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImages({ ...images, icon: url })}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.image || initialValues.image}
                  alt="Thumb"
                />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImages({ ...images, image: url })}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Background Image" name="backgroundImage">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.backgroundImage || initialValues.backgroundImage}
                  alt="backgroundImage"
                />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) =>
                  setImages({ ...images, backgroundImage: url })
                }
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PackageUpdateForm;
