import { useDeleteRole, useRoles } from '@shared/hooks';
import { Button, Popconfirm, Space, Table } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Paths } from '@shared/constant';
import { IRole } from '@shared/interfaces';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { _ } from '@shared/utils';
import { getAccess } from '@modules/auth/lib';

interface IProps {
  searchTerm?: string;
}
const RoleList: React.FC<IProps> = ({ searchTerm }) => {
  const navigate = useNavigate();

  const deleteRole = useDeleteRole();

  const [searchParams, setSearchParams] = useSearchParams();
  const query = {
    page: _.toNumber(searchParams.get('page')) || 1,
    limit: _.toNumber(searchParams.get('limit')) || 10,
  };

  const { data, isLoading } = useRoles({
    options: {
      page: query.page,
      limit: query.limit,
      searchTerm: searchTerm,
    },
  });

  const dataSource = data?.data?.payload?.map((x: IRole) => ({
    key: x.id,
    id: x.id,
    title: x.title,
  }));

  const columns: any = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(`${Paths.RoleUpdate(id)}`)}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it?"
            onConfirm={() =>
              getAccess(['ROLE_DELETE', 'FORBIDDEN'], () =>
                deleteRole.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: query.page,
        total: data?.data?.total,
        onChange: (page: any, limit: any) => {
          setSearchParams({ page, limit });
        },
      }}
    />
  );
};

export default RoleList;
