import { CoreAxiosInstance } from '@shared/config';
import { AxiosResponse } from 'axios';
import { _ } from '@shared/utils';
import {
  IBaseFilter,
  IProviderDepartmentCreate,
  IProviderDepartmentResponse,
  IProviderDepartmentsResponse,
  IProviderDepartmentUpdate,
} from '@shared/interfaces';

const END_POINT: string = '/provider-departments';

export const ProviderDepartmentService = {
  NAME: END_POINT,
  create(
    payload: IProviderDepartmentCreate
  ): Promise<AxiosResponse<IProviderDepartmentResponse>> {
    return CoreAxiosInstance.post(END_POINT, payload);
  },
  filter(
    options: IBaseFilter
  ): Promise<AxiosResponse<IProviderDepartmentsResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}?${_.queryNormalizer(options)}`);
  },
  filterById(id: string): Promise<AxiosResponse<IProviderDepartmentResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}/${id}`);
  },
  update(
    payload: IProviderDepartmentUpdate
  ): Promise<AxiosResponse<IProviderDepartmentResponse>> {
    const { id } = payload;
    delete payload.id;
    return CoreAxiosInstance.patch(`${END_POINT}/${id}`, payload);
  },
  delete(id: string): Promise<AxiosResponse<IProviderDepartmentResponse>> {
    return CoreAxiosInstance.delete(`${END_POINT}/${id}`);
  },
};
