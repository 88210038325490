import { useDistricts, useDivisions, useZones } from '@shared/hooks';
import {
  IDistrict,
  IAddress,
  IDivision,
  IZone,
  IAddressUpdate,
} from '@shared/interfaces';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { useEffect, useState } from 'react';

interface IProps {
  initialValues?: IAddress;
  onFinish?: (values: IAddressUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const AddressUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const divisions: IDivision[] = useDivisions().data?.data?.payload;

  const [selectedDivision, setSelectedDivision] = useState(null);
  const districts: IDistrict[] = useDistricts({
    options: {
      filter: selectedDivision && [`division.id||$eq||${selectedDivision}`],
    },
  }).data?.data?.payload;

  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const zones: IZone[] = useZones({
    options: {
      filter: selectedDistrict && [`district.id||$eq||${selectedDistrict}`],
    },
  }).data?.data?.payload;

  // initial form value
  const customInitialValues: IAddressUpdate = {
    ...initialValues,
    isActive: initialValues?.isActive,
    title: initialValues?.title,
    division: initialValues?.division?.id,
    district: initialValues?.district?.id,
    zone: initialValues?.zone?.id,
    user: initialValues?.user?.id,
  };

  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  return (
    <div className="lg:w-1/2 mt-20 mx-auto p-6 shadow-lg rounded-lg border">
      <Form
        size="large"
        layout="vertical"
        form={form}
        initialValues={customInitialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter a title!',
                },
              ]}
            >
              <Input placeholder="Enter a title" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Division"
              name="division"
              rules={[
                {
                  required: true,
                  message: 'Please select a division!',
                },
              ]}
            >
              <Select
                placeholder="Select a division"
                showSearch
                allowClear
                onChange={(val) => setSelectedDivision(val)}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {divisions?.map((dv) => (
                  <Select.Option key={dv.id} value={dv.id}>
                    {dv.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="District"
              name="district"
              rules={[
                {
                  required: true,
                  message: 'Please select a District!',
                },
              ]}
            >
              <Select
                placeholder="Select a District"
                showSearch
                allowClear
                disabled={!selectedDivision}
                onChange={(val) => setSelectedDistrict(val)}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {districts?.map((dt) => (
                  <Select.Option key={dt.id} value={dt.id}>
                    {dt.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Zone"
              name="zone"
              rules={[
                {
                  required: true,
                  message: 'Please select a zone!',
                },
              ]}
            >
              <Select
                placeholder="Select a zone"
                showSearch
                allowClear
                disabled={!selectedDistrict}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {zones?.map((zn) => (
                  <Select.Option key={zn.id} value={zn.id}>
                    {zn.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Is Active"
              name="isActive"
              rules={[
                {
                  required: true,
                  message: 'Please chose a value!',
                },
              ]}
            >
              <Radio.Group buttonStyle="solid" className="w-full">
                <Radio.Button className="w-1/2 text-center" value={true}>
                  True
                </Radio.Button>
                <Radio.Button className="w-1/2 text-center" value={false}>
                  False
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="User"
              name="user"
              rules={[
                {
                  required: true,
                  message: 'Please select a user!',
                },
              ]}
            >
              <Select
                placeholder="Select a user"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {users?.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.firstName + ' ' + user.lastName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item className="text-right">
              <Button loading={isLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddressUpdateForm;
