import { useRoles } from '@shared/hooks';
import { IPermissionCreate } from '@shared/interfaces';
import { Button, Col, Form, Input, Row, Select } from 'antd';

interface IProps {
  initialValues?: IPermissionCreate;
  onFinish?: (values: IPermissionCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const PermissionCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const roles = useRoles()?.data?.data?.payload;

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter a title!',
              },
            ]}
          >
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="role"
            name="role"
            rules={[
              {
                required: true,
                message: 'Please Select a role!',
              },
            ]}
          >
            <Select
              placeholder="Select a role"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {roles?.map((role) => (
                <Select.Option key={role.id} value={role.id}>
                  {role.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PermissionCreateForm;
