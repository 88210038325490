import { IPackage } from '@shared/interfaces';
import { Col, Row } from 'antd';
import React from 'react';

interface IProps {
  Package: IPackage;
}
const PackageDetails: React.FC<IProps> = ({ Package }) => {
  return (
    <Row
      gutter={[
        { sm: 16, md: 20, lg: 30 },
        { sm: 30, md: 30, lg: 30 },
      ]}
    >
      <Col span={8}>
        <ul>
          <li>
            <b>Title:</b> {Package?.title}
          </li>
          <li>
            <b>Active:</b> {Package?.isActive?.toString()}
          </li>
          <li>
            <b>Doctor Available:</b>{' '}
            {Package?.isDoctorConsultancyAvailable?.toString()}
          </li>
          <li>
            <b>Order Available:</b> {Package?.isOrderAvailable?.toString()}
          </li>
          <li>
            <b>AddableToCart:</b> {Package?.isAddableToCart?.toString()}
          </li>
          <li>
            <b>Department:</b> {Package?.department?.title}
          </li>
          <li>
            <b>Category:</b> {Package?.category?.title}
          </li>
          <li>
            <b>Subcategory:</b> {Package?.subCategory?.title}
          </li>
        </ul>
      </Col>
      <Col span={8}>
        <ul>
          <li>
            <b>Profile:</b> {Package?.profile}
          </li>
          <li>
            <b>Applicable For:</b> {Package?.applicableFor}
          </li>
          <li>
            <b>Payment Method:</b> {Package?.paymentMethod}
          </li>
          <li>
            <b>Age Group:</b> {Package?.ageGroup}
          </li>
          <li>
            <b>What Is:</b> {Package?.whatIs}
          </li>
          <li>
            <b>Why You Should Take:</b> {Package?.whyYouShouldTake}
          </li>
          <li>
            <b>Description:</b> {Package?.description}
          </li>
        </ul>
      </Col>
      <Col span={8}>
        <ul>
          <li>
            <b>Instructions:</b>{' '}
            <ul>
              {JSON.parse(Package.instructions)?.map((item, i) => (
                <li key={i} className="flex items-center gap-2">
                  <img src={item.icon} alt="radio-icon" /> {item.content}
                </li>
              ))}
            </ul>
          </li>
          <li>
            <b>This Service Covers:</b>
            <ul>
              {JSON.parse(Package.thisServiceCovers)?.map((item, i) => (
                <li key={i} className="flex items-center gap-2">
                  <img src={item.icon} alt="radio-icon" /> {item.content}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </Col>
      <Col span={24}>
        <Row
          justify="center"
          gutter={[
            { sm: 16, md: 30, lg: 50 },
            { sm: 16, md: 30, lg: 50 },
          ]}
        >
          <Col span={6}>
            <h3 className="mb-2">Icon</h3>
            <img
              className="h-60 w-full object-cover shadow"
              src={Package.icon}
              alt="Package-icon"
            />
          </Col>
          <Col span={6}>
            <h3 className="mb-2">Image</h3>
            <img
              className="h-60 w-full object-cover shadow"
              src={Package.image}
              alt="PackageImage"
            />
          </Col>
          <Col span={6}>
            <h3 className="mb-2">Background Image</h3>
            <img
              className="h-60 w-full object-cover shadow"
              src={Package.backgroundImage}
              alt="Package-bgImage"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default PackageDetails;
