import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useDepartments, useProviders } from '@shared/hooks';
import { IDepartment } from '@shared/interfaces';
import { _ } from '@shared/utils';
import { useDebounce } from 'ahooks';
import { Button, Col, Form, Input, PageHeader, Row, Select, Tag } from 'antd';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ProviderList from '../components/ProviderList';

const ProvidersPage = () => {
  const navigate = useNavigate();

  const departments: IDepartment[] = useDepartments()?.data?.data?.payload;

  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    name: searchParams.get('name'),
    department: searchParams.get('department'),
  });

  let pureQuery = _.toCleanObject({ ...query });
  Object.keys(query)?.map(
    (key) => (pureQuery[key] = { $contL: pureQuery[key] })
  );
  const debouncedQuery = useDebounce(pureQuery, { wait: 1000 });
  const { data, isLoading } = useProviders({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      s: _.toCleanObject({
        name: debouncedQuery?.name,
        'department.id': query?.department,
      }),
      sort: ['createdAt,DESC'],
    },
  });

  return (
    <Authorization allowedAccess={['PROVIDER_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Provider List"
        subTitle={<Tag key={1}>Total Provider: {data?.data?.total}</Tag>}
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.ProviderCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <Form
          size="large"
          layout="vertical"
          className="data-query-form"
          initialValues={_.toCleanObject(query)}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              <Form.Item label="name" name="name">
                <Input allowClear placeholder="Search by name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              <Form.Item label="department" name="department">
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a department"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {departments?.map((dp) => (
                    <Select.Option key={dp?.icon} value={dp?.id}>
                      {dp?.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <ProviderList
          isLoading={isLoading}
          provirders={data?.data?.payload}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};
export default ProvidersPage;
