import { Authorization } from '@modules/auth';
import { useCreateOrderRequest } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import OrderRequestCreateForm from '../components/OrderRequestCreateForm';

const OrderRequestCreatePage = () => {
  const navigate = useNavigate();

  const createCreateOrderRequest = useCreateOrderRequest({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ORDER_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Emergency Request">
        <OrderRequestCreateForm
          isLoading={createCreateOrderRequest.isLoading}
          onFinish={(values) => createCreateOrderRequest.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default OrderRequestCreatePage;
