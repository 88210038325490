import { Authorization } from '@modules/auth';
import { useDivision, useUpdateDivision } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import DivisionUpdateForm from '../components/DivisionUpdateForm';

const DivisionUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useDivision({ id });

  const updateDivision = useUpdateDivision({
    config: {
      onSuccess: (res) => {
        if (res.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader
          onBack={() => window.history.back()}
          title="Update Division"
        >
          <DivisionUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateDivision.isLoading}
            onFinish={(values) => updateDivision.mutateAsync({ ...values, id })}
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default DivisionUpdateRoute;
