import AddressCreateForm from '@modules/address/addresses/components/AddressCreateForm';
import { queryClient } from '@shared/config';
import {
  useAddresses,
  useCreateAddress,
  useFamilies,
  usePackageProviderPackages,
  usePackages,
  useProducts,
  useSlots,
  useUsers,
} from '@shared/hooks';
import {
  IAddress,
  IFamily,
  IOrderCreate,
  IPackage,
  IProduct,
  IProvider,
  ISlot,
  IUser,
} from '@shared/interfaces';
import { AddressService } from '@shared/services';
import { _ } from '@shared/utils';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import { useState } from 'react';

interface IProps {
  initialValues?: IOrderCreate;
  onFinish?: (values: IOrderCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const OrderCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  // Selected state for data fetching
  const [selectedPackage, setSelectedPackage] = useState('');
  const [selectedUser, setSelectedUser] = useState('');

  //necessary data fetching
  // const providers: IProvider[] = useProviders()?.data?.data?.payload;
  const packages: IPackage[] = usePackages()?.data?.data?.payload;
  const products: IProduct[] = useProducts()?.data?.data?.payload;
  const users: IUser[] = useUsers()?.data?.data?.payload;
  const slots: ISlot[] = useSlots()?.data?.data?.payload;

  const providers: IProvider[] = usePackageProviderPackages({
    id: selectedPackage,
    options: {},
  }).data?.data?.payload?.providerPackages?.map((item) => item?.provider);

  const families: IFamily[] = useFamilies({
    options: {
      s: _.toCleanObject({
        'user.id': selectedUser,
      }),
    },
  }).data?.data?.payload;

  const address: IAddress[] = useAddresses({
    options: {
      s: _.toCleanObject({
        'user.id': selectedUser,
      }),
    },
  }).data?.data?.payload;

  const onSubmitForm = (values) => {
    values.packages = values.packages && [values.packages];
    values.products = values.products && [values.products];
    if (_.isEmpty(values.packages)) delete values.packages;
    if (_.isEmpty(values.products)) delete values.products;
    values.deliveryDate = moment(values.deliveryDate).format('YYYY-MM-DD');
    // console.log(values);
    onFinish(values);
  };

  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const createAddress = useCreateAddress({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          setAddressModalOpen(false);
          queryClient.invalidateQueries(AddressService.NAME);
          notification.success({
            message: 'Address created successfully',
          });
        }
      },
    },
  });

  return (
    <>
      <Form
        size="large"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onSubmitForm}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item label="Packages" name="packages">
              <Select
                allowClear
                showSearch
                placeholder="Select a Package"
                onChange={(val) => setSelectedPackage(val)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {packages?.map((pk) => (
                  <Select.Option key={pk.id} value={pk.id}>
                    {pk.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item label="Products" name="products">
              <Select
                allowClear
                showSearch
                placeholder="Select a product"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {products?.map((pd) => (
                  <Select.Option key={pd.id} value={pd.id}>
                    {pd.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="Provider"
              name="provider"
              rules={[
                {
                  required: true,
                  message: 'Please select a provider!',
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                disabled={!selectedPackage}
                placeholder="Select  a provider"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {providers?.map((pv) => (
                  <Select.Option key={pv?.id} value={pv?.id}>
                    {pv?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="User"
              name="user"
              rules={[
                {
                  required: true,
                  message: 'Please select a user!',
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select  a user"
                onChange={(val) => setSelectedUser(val)}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {users?.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {`${user.firstName} ${user.lastName}-${user.phoneNumber}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="Family"
              name="family"
              rules={[
                {
                  required: true,
                  message: 'Please select a family!',
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                disabled={!selectedUser}
                placeholder="Select  a family"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {families?.map((family) => (
                  <Select.Option key={family.id} value={family.id}>
                    {`${family.firstName} ${family.lastName}-${family.phoneNumber}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="DeliveryDate"
              name="deliveryDate"
              rules={[
                {
                  required: true,
                  message: 'Please enter deliveryDate',
                },
              ]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item label="Slot" name="slot">
              <Select
                allowClear
                showSearch
                placeholder="Select  a slot"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {slots?.map((slot) => (
                  <Select.Option key={slot.id} value={slot.id}>
                    {slot.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item label="Address" name="address">
              <Select
                allowClear
                showSearch
                disabled={!selectedUser}
                placeholder="Select  a address"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {address?.map((ad) => (
                  <Select.Option key={ad.id} value={ad.id}>
                    {ad.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {_.isEmpty(address) && (
              <div className="absolute top-0 right-4">
                <Button
                  type="primary"
                  onClick={() => setAddressModalOpen(true)}
                >
                  Create
                </Button>
              </div>
            )}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="instructions" name="instructions">
              <Input.TextArea placeholder="Enter instructions" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item className="text-right">
              <Button loading={isLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        centered
        title="Crate an address"
        width="max-content"
        open={addressModalOpen}
        onCancel={() => setAddressModalOpen(false)}
        onOk={() => setAddressModalOpen(false)}
        footer={false}
      >
        <AddressCreateForm
          initialValues={{ user: selectedUser }}
          isLoading={createAddress.isLoading}
          onFinish={(values) => createAddress.mutateAsync(values)}
        />
      </Modal>
    </>
  );
};

export default OrderCreateForm;
