import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { Button, Input, PageHeader } from 'antd';
import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import ProductList from '../components/ProductList';

const ProductsPage = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>();

  return (
    <Authorization allowedAccess={['PRODUCT_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Product List"
        subTitle={
          <Input
            size="large"
            placeholder="Search"
            prefix={<AiOutlineSearch />}
            style={{ borderRadius: 30 }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        }
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.ProductCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <ProductList searchTerm={searchTerm} />
      </PageHeader>
    </Authorization>
  );
};
export default ProductsPage;
