import { usePackages, useUsers } from '@shared/hooks';
import { IPackage, IUser } from '@shared/interfaces';
import { Col, Form, Row, Select } from 'antd';

interface IProps {
  initialValues: object;
  onValuesChange: (c, v) => void;
}
const CallRequestQueryForm: React.FC<IProps> = ({
  initialValues,
  onValuesChange,
}) => {
  const users: IUser[] = useUsers()?.data?.data?.payload;
  const packages: IPackage[] = usePackages()?.data?.data?.payload;

  return (
    <Form
      size="large"
      layout="vertical"
      className="data-query-form"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="user" name="user">
            <Select
              placeholder="Select a user"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users?.map((user) => (
                <Select.Option key={user?.id} value={user?.id}>
                  {`${user?.firstName} ${user?.lastName} - ${user?.phoneNumber}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="package" name="package">
            <Select
              showSearch
              allowClear
              placeholder="Select a package"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {packages?.map((pkg) => (
                <Select.Option key={pkg?.id} value={pkg?.id}>
                  {pkg?.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CallRequestQueryForm;
