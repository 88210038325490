import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import RoleCreateRoute from './routes/RoleCreateRoute';
import RoleListRoute from './routes/RoleListRoute';
import RoleUpdateRoute from './routes/RoleUpdateRoute';

export const RoleRoutes = [
  {
    path: Paths.Role,
    element: <Navigate to={Paths.RoleList} />,
  },
  { path: Paths.RoleList, element: <RoleListRoute /> },
  { path: Paths.RoleCreate, element: <RoleCreateRoute /> },
  { path: Paths.RoleUpdate(), element: <RoleUpdateRoute /> },
];
