import { Authorization } from '@modules/auth';
import { useCallRequest } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import CallRequestDetails from '../components/CallRequestDetails';

const CallRequestDetailsRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useCallRequest({ id });

  return (
    <Authorization allowedAccess={['ORDER_VIEW', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Call Request details">
          <CallRequestDetails CallRequest={data?.data.payload} />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default CallRequestDetailsRoute;
