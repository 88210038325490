import { Authorization } from '@modules/auth';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useCreateDiagnosticPrescription } from '@shared/hooks';
import DiagnosticPrescriptionCreateForm from '../components/DiagnosticPrescriptionCreateFrom';

const DiagnosticPrescriptionCreatePage = () => {
  const navigate = useNavigate();

  const createDiagnosticPrescription = useCreateDiagnosticPrescription({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: res?.data?.message || 'Call Request Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ORDER_CREATE', 'FORBIDDEN']}>
      <PageHeader
        onBack={() => navigate(-1)}
        title="Create Diagnostic Prescription"
      >
        <DiagnosticPrescriptionCreateForm
          isLoading={createDiagnosticPrescription.isLoading}
          onFinish={(values) =>
            createDiagnosticPrescription.mutateAsync(values)
          }
        />
      </PageHeader>
    </Authorization>
  );
};
export default DiagnosticPrescriptionCreatePage;
