import { DepartmentImageService } from '@shared/services';
import { IDepartmentImageFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useDepartmentImages hook ------------------------------------
type IUseDepartmentImages = {
  options?: IDepartmentImageFilter;
  config?: QueryConfig<typeof DepartmentImageService.filter>;
};
export const useDepartmentImages = ({
  options,
  config,
}: IUseDepartmentImages = {}) => {
  return useQuery({
    ...config,
    queryKey: [DepartmentImageService.NAME, options],
    queryFn: () => DepartmentImageService.filter(options),
  });
};

//----------------------- useDepartmentImage hook --------------------------------------
type IUseDepartmentImage = {
  id: string;
  config?: QueryConfig<typeof DepartmentImageService.filterById>;
};

export const useDepartmentImage = ({ id, config }: IUseDepartmentImage) => {
  return useQuery({
    ...config,
    queryFn: () => DepartmentImageService.filterById(id),
  });
};

//------------------ useCreateDepartmentImage hook ---------------------------------
type IUseCreateDepartmentImage = {
  config?: MutationConfig<typeof DepartmentImageService.create>;
};

export const useCreateDepartmentImage = ({
  config,
}: IUseCreateDepartmentImage = {}) => {
  return useMutation({
    ...config,
    mutationFn: DepartmentImageService.create,
  });
};

//------------------ useUpdateDepartmentImage hook ----------------------------------
type IUseUpdateDepartmentImage = {
  config?: MutationConfig<typeof DepartmentImageService.update>;
};

export const useUpdateDepartmentImage = ({
  config,
}: IUseUpdateDepartmentImage = {}) => {
  return useMutation({
    ...config,
    mutationFn: DepartmentImageService.update,
  });
};

//------------------ useDeleteDepartmentImage hook ----------------------------------
type IUseDeleteDepartmentImage = {
  config?: MutationConfig<typeof DepartmentImageService.delete>;
};

export const useDeleteDepartmentImage = ({
  config,
}: IUseDeleteDepartmentImage = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(DepartmentImageService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: DepartmentImageService.delete,
  });
};
