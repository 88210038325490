import { Authorization } from '@modules/auth';
import { useCreateDistrict } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import DistrictCreateForm from '../components/DistrictCreateForm';

const DistrictCreateRoute = () => {
  const navigate = useNavigate();
  const createDistrict = useCreateDistrict({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create District">
        <DistrictCreateForm
          isLoading={createDistrict.isLoading}
          onFinish={(values) => createDistrict.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};

export default DistrictCreateRoute;
