import { MedicineOrderService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useMedicineOrders hook ------------------------------------
type IUseMedicineOrders = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof MedicineOrderService.filter>;
};
export const useMedicineOrders = ({
  options,
  config,
}: IUseMedicineOrders = {}) => {
  return useQuery({
    ...config,
    queryKey: [MedicineOrderService.NAME, options],
    queryFn: () => MedicineOrderService.filter(options),
  });
};

//----------------------- useMedicineOrder hook --------------------------------------
type IUseMedicineOrder = {
  id: string;
  config?: QueryConfig<typeof MedicineOrderService.filterById>;
};

export const useMedicineOrder = ({ id, config }: IUseMedicineOrder) => {
  return useQuery({
    ...config,
    queryFn: () => MedicineOrderService.filterById(id),
  });
};

//------------------ useCreateMedicineOrder hook ---------------------------------
type IUseCreateMedicineOrder = {
  config?: MutationConfig<typeof MedicineOrderService.create>;
};

export const useCreateMedicineOrder = ({
  config,
}: IUseCreateMedicineOrder = {}) => {
  return useMutation({
    ...config,
    mutationFn: MedicineOrderService.create,
  });
};

//------------------ useUpdateMedicineOrder hook ----------------------------------
type IUseUpdateMedicineOrder = {
  config?: MutationConfig<typeof MedicineOrderService.update>;
};

export const useUpdateMedicineOrder = ({
  config,
}: IUseUpdateMedicineOrder = {}) => {
  return useMutation({
    ...config,
    mutationFn: MedicineOrderService.update,
  });
};

//------------------ useDeleteMedicineOrder hook ----------------------------------
type IUseDeleteMedicineOrder = {
  config?: MutationConfig<typeof MedicineOrderService.delete>;
};

export const useDeleteMedicineOrder = ({
  config,
}: IUseDeleteMedicineOrder = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(MedicineOrderService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: MedicineOrderService.delete,
  });
};
