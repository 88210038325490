import ImagePreview from '@shared/components/ImagePreview';
import { Paths } from '@shared/constant';
import { useDeleteProviderDiscount, useProviderDiscounts } from '@shared/hooks';
import { IProviderDiscount } from '@shared/interfaces';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { _ } from '@shared/utils';
import { getAccess } from '@modules/auth';

const ProviderDiscountList = () => {
  const navigate = useNavigate();

  const deleteProviderDiscount = useDeleteProviderDiscount();

  const { search } = useLocation();
  const query = {
    page: parseInt(search.slice(6, 7)) || 1,
    limit: parseInt(search.slice(13)) || 10,
  };
  const { data, isLoading } = useProviderDiscounts({
    options: {
      page: query.page,
      limit: query.limit,
      sort: ['createdAt,DESC'],
    },
  });

  const dataSource = data?.data?.payload?.map((x: IProviderDiscount) => ({
    key: x?.id,
    id: x?.id,
    title: x?.title,
    code: x?.code,
    image: x?.image,
    provider: x?.provider?.name,
    priority: x?.priority,
    isActive: x?.isActive.toString(),
    isFeatured: x?.isFeatured.toString(),
    discountType: x?.discountType,
    usagesType: x?.usagesType,
    validity: _.getStutteredDate(x?.validity),
    description: x?.description,
  }));

  const columns = [
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="image" />,
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: {
        showTitle: false,
      },
      render: (title) => (
        <Tooltip placement="topLeft" title={title}>
          {title}
        </Tooltip>
      ),
    },
    {
      title: 'code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Featured',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
    },
    {
      title: 'Discount Type',
      dataIndex: 'discountType',
      key: 'discountType',
    },
    {
      title: 'Usages Type',
      dataIndex: 'usagesType',
      key: 'usagesType',
    },
    {
      title: 'validity',
      dataIndex: 'validity',
      key: 'validity',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(`${Paths.ProviderDiscountsUpdate}/${id}`)}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Package?"
            onConfirm={() =>
              getAccess(['PROVIDER_DISCOUNT_DELETE', 'FORBIDDEN'], () =>
                deleteProviderDiscount.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: query.page,
        total: data?.data?.total,
        onChange: (page: number, limit: number) => {
          navigate(`?page=${page}&limit=${limit}`);
        },
      }}
    />
  );
};
export default ProviderDiscountList;
