import { IBaseFilter } from '@shared/interfaces';

import { CoreAxiosInstance } from '@shared/config';
import { _ } from '@shared/utils';
import {
  IDiagnosticPrescriptionCreate,
  IDiagnosticPrescriptionUpdate,
} from '@shared/interfaces/_diagnostic-prescriptions.interfaces';

const END_POINT: string = '/diagnostic-prescriptions';

export const DiagnosticPrescriptionService = {
  NAME: END_POINT,
  create(payload: IDiagnosticPrescriptionCreate) {
    return CoreAxiosInstance.post(END_POINT, payload);
  },
  filter(options: IBaseFilter) {
    return CoreAxiosInstance.get(`${END_POINT}?${_.queryNormalizer(options)}`);
  },
  filterById(id: string) {
    return CoreAxiosInstance.get(`${END_POINT}/${id}`);
  },
  update(payload: IDiagnosticPrescriptionUpdate) {
    const { id } = payload;
    delete payload.id;
    return CoreAxiosInstance.patch(`${END_POINT}/${id}`, payload);
  },
  delete(id: string) {
    return CoreAxiosInstance.delete(`${END_POINT}/${id}`);
  },
};
