import { useDepartments } from '@shared/hooks';
import { IDepartment, IProviderCreate } from '@shared/interfaces';
import { CustomUpload } from '@shared/utils';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import { useState } from 'react';
const { Option } = Select;

interface IProps {
  initialValues?: IProviderCreate;
  onFinish?: (values: IProviderCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const ProviderCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const departments: IDepartment[] = useDepartments().data?.data?.payload;

  const [logo, setLogo] = useState<string>();

  const onSubmitForm = (values: IProviderCreate) => {
    values.logo = logo;
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter provider name!',
              },
            ]}
          >
            <Input placeholder="Enter provider name" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Department"
            name="department"
            rules={[
              {
                required: true,
                message: 'Please select a department!',
              },
            ]}
          >
            <Select
              placeholder="Select a department"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {departments?.map((d) => (
                <Option key={d.id} value={d.id}>
                  {d.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter provider email!',
              },
              { type: 'email', message: 'Invalid email' },
            ]}
          >
            <Input placeholder="Enter provider email" type="email" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item label="Priority" name="priority">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter a priority"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Nid number"
            name="nid"
            rules={[
              {
                required: true,
                message: 'Please enter provider nid number!',
              },
            ]}
          >
            <Input placeholder="Enter provider nid number" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Passport"
            name="passport"
            rules={[
              {
                required: true,
                message: 'Please enter provider nid number!',
              },
            ]}
          >
            <Input placeholder="Enter a passport number" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Trade license"
            name="tradeLicense"
            rules={[
              {
                required: true,
                message: 'Please enter Trade license!',
              },
            ]}
          >
            <Input placeholder="Enter a Trade license" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Location" required>
            <Row>
              <Col span={12}>
                <Form.Item
                  name={['location', 'lat']}
                  rules={[{ required: true, message: 'lat is required' }]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder="lat" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['location', 'lng']}
                  rules={[{ required: true, message: 'lng is required' }]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder="lng" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={15} xl={18}>
          <Form.Item
            label="Address"
            name="address"
            rules={[
              {
                required: true,
                message: 'Please enter address!',
              },
            ]}
          >
            <Input.TextArea rows={1} placeholder="Enter address" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={24} xl={24}>
          <Form.Item
            label="Memorandum"
            name="memorandum"
            rules={[
              {
                required: true,
                message: 'Please enter memorandum !',
              },
            ]}
          >
            <Input.TextArea placeholder="Enter Memorandum " />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item label="Logo" name="logo">
            <CustomUpload onChange={(url) => setLogo(url)} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default ProviderCreateForm;
