import { getAccess } from '@modules/auth';
import ImagePreview from '@shared/components/ImagePreview';
import { Paths } from '@shared/constant';
import { useDeleteSubcategory } from '@shared/hooks';
import { ISubcategory } from '@shared/interfaces';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

interface IProps {
  isLoading: boolean;
  subCategories: ISubcategory[];
  pagination: PaginationProps;
}
const SubcategoryList: React.FC<IProps> = ({
  isLoading,
  subCategories,
  pagination,
}) => {
  const navigate = useNavigate();

  const deleteSubcategory = useDeleteSubcategory();

  const dataSource = subCategories?.map((x: ISubcategory) => ({
    key: x?.id,
    id: x?.id,
    title: x?.title,
    category: x?.category?.title,
    description: x?.description && x?.description?.slice(0, 70) + '...',
    isActive: x?.isActive?.toString(),
    isFeatured: x?.isFeatured?.toString(),
    priority: x?.priority,
    icon: x?.icon,
    image: x?.image,
    backgroundImage: x?.backgroundImage,
  }));

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'IsActive',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'IsFeatured',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon: string) => <ImagePreview src={icon} alt="icon" />,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="thumbImage" />,
    },
    {
      title: 'BgImage',
      dataIndex: 'backgroundImage',
      key: 'backgroundImage',
      render: (backgroundImage: string) => (
        <ImagePreview src={backgroundImage} alt="BgImage" />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.SubCategoryUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it?"
            onConfirm={() =>
              getAccess(['CATEGORY_DELETE', 'FORBIDDEN'], () =>
                deleteSubcategory.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
    />
  );
};
export default SubcategoryList;
