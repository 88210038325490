import { Paths } from '@shared/constant';
import { useDeleteOrderRequest, useOrderRequests } from '@shared/hooks';
import { IOrderRequest } from '@shared/interfaces';
import { Button, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { _ } from '@shared/utils';
import { getAccess } from '@modules/auth';

interface IProps {
  searchTerm?: string;
}

const OrderRequestList: React.FC<IProps> = ({ searchTerm }) => {
  const navigate = useNavigate();

  const deleteOrderRequest = useDeleteOrderRequest();

  const { search } = useLocation();
  const query = {
    page: parseInt(search.slice(6, 7)) || 1,
    limit: parseInt(search.slice(13)) || 10,
  };

  const { data, isLoading } = useOrderRequests({
    options: {
      page: query.page,
      limit: query.limit,
      searchTerm: searchTerm,
    },
  });

  const dataSource = data?.data?.payload?.map((x: IOrderRequest) => ({
    key: x?.id,
    id: x?.id,
    name: x?.firstName + ' ' + x?.lastName,
    bloodGroup: x?.bloodGroup,
    phoneNumber: x?.phoneNumber,
    requestType: x?.requestType,
    description: x?.description,
    isActive: x?.isActive.toString(),
    user: x?.user?.firstName + ' ' + x?.user?.lastName,
    createdAt: _.getStutteredDate(x?.createdAt),
  }));

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'bloodGroup',
      dataIndex: 'bloodGroup',
      key: 'bloodGroup',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Request Type',
      dataIndex: 'requestType',
      key: 'requestType',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      // sorter: (a, b) => a.createdAt.length - b.createdAt.length,
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(`${Paths.orderRequestUpdate}/${id}`)}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Department?"
            onConfirm={() =>
              getAccess(['ORDER_DELETE', 'FORBIDDEN'], () =>
                deleteOrderRequest.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: query.page,
        total: data?.data?.total,
        onChange: (page: number, limit: number) => {
          navigate(`?page=${page}&limit=${limit}`);
        },
      }}
    />
  );
};
export default OrderRequestList;
