import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useDepartments } from '@shared/hooks';
import { Button, PageHeader, Tag } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DepartmentList from '../components/DepartmentList';
import { useDebounce } from 'ahooks';
import { _ } from '@shared/utils';
import { useState } from 'react';
import DepartmentQueryForm from '../components/DepartmentQueryForm';

const DepartmentsPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState(
    _.toCleanObject({
      title: searchParams.get('title'),
      uiType: searchParams.get('uiType'),
      serviceType: searchParams.get('serviceType'),
    })
  );

  let pureQuery = _.toCleanObject({ ...query });
  Object.keys(query)?.map(
    (key) => (pureQuery[key] = { $contL: pureQuery[key] })
  );
  const debouncedQuery = useDebounce(pureQuery, { wait: 1000 });

  const { data, isLoading } = useDepartments({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      s: _.toCleanObject({
        title: debouncedQuery?.title,
        uiType: query?.uiType,
        'serviceType.id': query?.serviceType,
      }),
      sort: ['createdAt,DESC'],
    },
  });

  return (
    <Authorization allowedAccess={['DEPARTMENT_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Department List"
        subTitle={
          <>
            <Tag key={1}>Total Department: {data?.data?.total}</Tag>
          </>
        }
        extra={[
          <Button
            key={1}
            onClick={() => navigate(Paths.DepartmentCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <DepartmentQueryForm
          initialValues={_.toCleanObject({ ...query })}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        />

        <DepartmentList
          isLoading={isLoading}
          departments={data?.data?.payload}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};

export default DepartmentsPage;
