import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectToken } from '@shared/redux';

const AppRoutes = () => {
  const { isAuthenticated } = useSelector(
    createStructuredSelector({
      isAuthenticated: selectToken,
    })
  );

  return isAuthenticated ? <ProtectedRoutes /> : <PublicRoutes />;
};

export default AppRoutes;
