import ImagePreview from '@shared/components/ImagePreview';
import {
  useProviderPackages,
  useProviderProducts,
  useProviders,
} from '@shared/hooks';
import {
  IFeaturedItem,
  IFeaturedItemUpdate,
  IProvider,
  IProviderPackage,
  IProviderProduct,
} from '@shared/interfaces';
import { CustomUpload } from '@shared/utils';
import { Button, Col, Form, Radio, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
const { Option } = Select;

interface IProps {
  initialValues?: IFeaturedItem;
  onFinish?: (values: IFeaturedItemUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const FeaturedItemUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  // fetch the initial data
  const [selectedProvider, setSelectedProvider] = useState<string>();
  const providers: IProvider[] = useProviders().data?.data?.payload;
  const providerProducts: IProviderProduct[] = useProviderProducts({
    options: {
      filter: selectedProvider && [`provider.id||$eq||${selectedProvider}`],
    },
  }).data?.data?.payload;

  const providerPackages: IProviderPackage[] = useProviderPackages({
    options: {
      filter: selectedProvider && [`provider.id||$eq||${selectedProvider}`],
    },
  }).data?.data?.payload;
  // fetch the initial data end

  // purify initial values
  const puriFiedInitialValues: IFeaturedItemUpdate = {
    ...initialValues,
    provider: initialValues.provider?.id,
    providerPackage: initialValues.providerPackage?.id,
    providerProduct: initialValues.providerProduct?.id,
  };
  // purify initial values end

  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    setSelectedProvider(initialValues.provider?.id);
  }, [form, initialValues]);
  // reset form value end

  // from value and submission
  const [image, setImage] = useState('');
  const onSubmitForm = (values: IFeaturedItemUpdate) => {
    values.image = image || initialValues.image;
    onFinish(values);
  };
  // from value and submission end
  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={puriFiedInitialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: 'Please Select type!',
              },
            ]}
          >
            <Select placeholder="Select type">
              <Option value="PRODUCT">PRODUCT</Option>
              <Option value="PACKAGE">PACKAGE</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Provider"
            name="provider"
            rules={[
              {
                required: true,
                message: 'Please Select provider!',
              },
            ]}
          >
            <Select
              placeholder="Select a  provider"
              showSearch
              allowClear
              onChange={(val) => setSelectedProvider(val)}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {providers?.map((provider) => (
                <Option key={provider.id} value={provider.id}>
                  {provider.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Provider Product" name="providerProduct">
            <Select
              placeholder="Select a  provider product"
              showSearch
              allowClear
              disabled={!selectedProvider}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {providerProducts?.map((product) => (
                <Option key={product.id} value={product.id}>
                  {product.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Provider Package" name="providerPackage">
            <Select
              placeholder="Select a  provider product"
              showSearch
              allowClear
              disabled={!selectedProvider}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {providerPackages?.map((pk) => (
                <Option key={pk.id} value={pk.id}>
                  {pk.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview src={image || initialValues.image} alt="thumb" />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImage(url)}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FeaturedItemUpdateForm;
