import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import FeaturedItemCreateRoute from './routes/FeaturedItemCreateRoute';
import FeaturedItemsRoute from './routes/FeaturedItemsRoute';
import FeaturedItemUpdateRoute from './routes/FeaturedItemUpdateRoute';

export const FeaturedItemRoutes = [
  { path: Paths.FeaturedItemList, element: <FeaturedItemsRoute /> },
  { path: Paths.FeaturedItemCreate, element: <FeaturedItemCreateRoute /> },
  { path: Paths.FeaturedItemUpdate(), element: <FeaturedItemUpdateRoute /> },
  {
    path: Paths.FeaturedItem,
    element: <Navigate to={Paths.FeaturedItemList} />,
  },
];
