import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import AddressCreateRoute from './addresses/routes/AddressCreateRoute';
import AddressesRoute from './addresses/routes/AddressesRoute';
import AddressUpdateRoute from './addresses/routes/AddressUpdateRoute';
import DistrictCreateRoute from './district/routes/DistrictCreateRoute';
import DistrictsRoute from './district/routes/DistrictsRoute';
import DistrictUpdateRoute from './district/routes/DistrictUpdateRoute';
import DivisionCreateRoute from './division/routes/DivisionCreateRoute';
import DivisionsRoute from './division/routes/DivisionsRoute';
import DivisionUpdateRoute from './division/routes/DivisionUpdateRoute';
import ZoneCreateRoute from './zone/routes/ZoneCreateRoute';
import ZonesRoute from './zone/routes/ZonesRoute';
import ZoneUpdateRoute from './zone/routes/ZoneUpdateRoute';

export const AddressRoutes = [
  { path: Paths.DivisionList, element: <DivisionsRoute /> },
  { path: Paths.DivisionCreate, element: <DivisionCreateRoute /> },
  { path: Paths.DivisionUpdate(), element: <DivisionUpdateRoute /> },
  { path: Paths.Division, element: <Navigate to={Paths.DivisionList} /> },

  { path: Paths.DistrictList, element: <DistrictsRoute /> },
  { path: Paths.DistrictCreate, element: <DistrictCreateRoute /> },
  { path: Paths.DistrictUpdate(), element: <DistrictUpdateRoute /> },
  { path: Paths.District, element: <Navigate to={Paths.DistrictList} /> },

  { path: Paths.ZoneList, element: <ZonesRoute /> },
  { path: Paths.ZoneCreate, element: <ZoneCreateRoute /> },
  { path: Paths.ZoneUpdate(), element: <ZoneUpdateRoute /> },
  { path: Paths.Zone, element: <Navigate to={Paths.ZoneList} /> },

  { path: Paths.AddressList, element: <AddressesRoute /> },
  { path: Paths.AddressCreate, element: <AddressCreateRoute /> },
  { path: Paths.AddressUpdate(), element: <AddressUpdateRoute /> },
  { path: Paths.Address, element: <Navigate to={Paths.AddressList} /> },
];
