import { useDeleteDepartmentImage, useDepartments } from '@shared/hooks';
import { Button, Popconfirm, Select, Space, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@shared/constant';
import {
  IDepartment,
  IDepartmentImage,
  IDepartmentImageFilter,
} from '@shared/interfaces';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import ImagePreview from '@shared/components/ImagePreview';
import { getAccess } from '@modules/auth';

interface IProps {
  total: number;
  departmentImages: IDepartmentImage[];
  isLoading: boolean;
  query: IDepartmentImageFilter;
  setQuery: any;
}
const DepartmentImageList: React.FC<IProps> = ({
  total,
  departmentImages,
  isLoading,
  query,
  setQuery,
}) => {
  const navigate = useNavigate();

  const departments: IDepartment[] = useDepartments().data?.data?.payload;

  const deleteDepartmentImage = useDeleteDepartmentImage();

  const dataSource = departmentImages?.map((x: IDepartmentImage) => ({
    key: x.id,
    id: x.id,
    department: x.department?.title,
    isActive: x.isActive?.toString(),
    priority: x.priority,
    image: x.link,
  }));

  const columns = [
    {
      title: 'department',
      dataIndex: 'department',
      key: 'department',
      filterDropdown: () => (
        <Select
          showSearch
          allowClear
          placeholder="Select a department"
          style={{ width: 150 }}
          defaultValue={
            query?.filter?.indexOf('serviceType') !== -1 && query.filter
          }
          onChange={(val) => setQuery({ ...query, filter: val })}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {departments?.map((dp) => (
            <Select.Option key={dp.id} value={`department.id||$eq||${dp.id}`}>
              {dp.title}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="image" />,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.DepartmentImageUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it?"
            onConfirm={() =>
              getAccess(['DEPARTMENT_DELETE', 'FORBIDDEN'], () =>
                deleteDepartmentImage.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: Number(query.page) || 1,
        total: total,
        onChange: (page: number, limit: number) => {
          setQuery({
            ...query,
            page: page,
            limit: limit,
          });
        },
      }}
    />
  );
};

export default DepartmentImageList;
