import React, { useState } from 'react';
import { ICategoryCreate, IDepartment } from '@shared/interfaces';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import { CustomUpload } from '@shared/utils';
import { useDepartments } from '@shared/hooks';
const { Option } = Select;

interface IProps {
  initialValues?: ICategoryCreate;
  onFinish?: (values: ICategoryCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const CategoryCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const departments: IDepartment[] = useDepartments().data?.data?.payload;

  const [images, setImages] = useState({
    icon: '',
    image: '',
    backgroundImage: '',
  });

  const onSubmitForm = (values: ICategoryCreate) => {
    values.icon = images.icon;
    values.image = images.image;
    values.backgroundImage = images.backgroundImage;
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter a title!',
              },
            ]}
          >
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Department"
            name="department"
            rules={[
              {
                required: true,
                message: 'Please select a department!',
              },
            ]}
          >
            <Select
              placeholder="Select a department"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {departments?.map((d) => (
                <Option key={d.id} value={d.id}>
                  {d.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Featured" name="isFeatured">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item label="Priority" name="priority">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter priority"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={1} placeholder="Enter description" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Icon" name="icon">
            <CustomUpload
              onChange={(url) => setImages({ ...images, icon: url })}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <CustomUpload
              onChange={(url) => setImages({ ...images, image: url })}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Background Image" name="backgroundImage">
            <CustomUpload
              onChange={(url) => setImages({ ...images, backgroundImage: url })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CategoryCreateForm;
