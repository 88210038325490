import React from 'react';
import jwtDecode from 'jwt-decode';
import { storage } from '@shared/utils';
import { ISession } from '../interfaces';
import { PermissionsTypes } from '@shared/constant';
import { Navigate, useLocation } from 'react-router-dom';
import { notification } from 'antd';

export const getSession = (): ISession | boolean => {
  try {
    const token: string | boolean = storage.getToken();
    if (token) {
      return jwtDecode(token);
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getPermissions = (): PermissionsTypes[] | [] => {
  try {
    const token = storage.getData('_permission_token');
    if (token) {
      const decodeAccessToken: any = jwtDecode(token);
      return decodeAccessToken?.permissions;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export const hasAccessPermission = (
  allowedAccess: PermissionsTypes[],
  userPermissions: PermissionsTypes[]
): boolean => {
  const hasAccess = userPermissions.filter((role: PermissionsTypes) =>
    allowedAccess.includes(role)
  );
  return hasAccess.length > 0;
};

interface IRouteAuthorization {
  allowedAccess: PermissionsTypes[];
  component: any;
}
export const RouteAuthorization: React.FC<IRouteAuthorization> = ({
  allowedAccess,
  component: Component,
}) => {
  let location = useLocation();
  const hasAccess: boolean = hasAccessPermission(
    allowedAccess,
    getPermissions()
  );
  return hasAccess ? (
    <Component />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export const Authorization: React.FC<{
  allowedAccess: PermissionsTypes[];
  children?: any;
  forbiddenFallback?: React.ReactNode;
}> = ({
  allowedAccess,
  children,
  forbiddenFallback = (
    <div className="h-full flex items-center justify-center">
      <h4 className="text-center text-8xl font-bold text-red-700">
        Unauthorized Access
      </h4>
    </div>
  ),
}) => {
  const hasAccess: boolean = hasAccessPermission(
    allowedAccess,
    getPermissions()
  );
  //Temporary
  // return children;
  return hasAccess ? children : forbiddenFallback;
};

export const getAccess = (
  permissions: PermissionsTypes[],
  func: () => void
) => {
  const hasAccess: boolean = hasAccessPermission(permissions, getPermissions());

  return hasAccess
    ? func()
    : notification.error({ message: 'Unauthorized Access' });
};
