import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import MedicineOrdersRoute from './routes/MedicineOrdersRoute';

export const MedicineOrdersRoutes = [
  { path: Paths.MedicineOrderList, element: <MedicineOrdersRoute /> },
  {
    path: Paths.MedicineOrder,
    element: <Navigate to={Paths.MedicineOrderList} />,
  },
];
