import { Authorization } from '@modules/auth';
import { useOrder } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import OrderDetails from '../components/OrderDetails';

const OrderDetailsRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useOrder({ id });

  return (
    <Authorization allowedAccess={['ORDER_VIEW', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Order Details">
          <OrderDetails order={data?.data?.payload} />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};

export default OrderDetailsRoute;
