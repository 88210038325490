export const Paths = {
  root: '/',

  //!Dashboard
  dashboard: '/dashboard/',
  defaultDashboard: '/dashboard/default/',

  //Role
  Role: '/role',
  RoleList: '/role/list',
  RoleCreate: '/role/create',
  RoleUpdate: (id = ':id') => `/role/update/${id}`,

  //Role
  UserRole: '/user-role',
  UserRoleList: '/user-role/list',
  UserRoleCreate: '/user-role/create',
  UserRoleUpdate: (id = ':id') => `/user-role/update/${id}`,

  //Permission
  Permission: '/permission',
  PermissionList: '/permission/list',
  PermissionCreate: '/permission/create',
  PermissionUpdate: (id = ':id') => `/permission/update/${id}`,

  //Users
  users: '/users',
  userCreate: '/users/create',
  userUpdate: '/users/update',

  //subscription
  subscription: '/subscription',
  subscriptionList: '/subscription/list',
  subscriptionCreate: '/subscription/create',
  subscriptionUpdate: (id = ':id') => `/subscription/update/${id}`,

  //Base Department
  BaseDepartment: '/base-department',
  BaseDepartmentList: '/base-department/list',
  BaseDepartmentCreate: '/base-department/create',
  BaseDepartmentUpdate: (id = ':id') => `/base-department/update/${id}`,

  //Departments
  Department: '/department',
  DepartmentList: '/department/list',
  DepartmentCreate: '/department/create',
  DepartmentUpdate: (id = ':id') => `/department/update/${id}`,

  //Departments
  DepartmentImage: '/department-image',
  DepartmentImageList: '/department-image/list',
  DepartmentImageCreate: '/department-image/create',
  DepartmentImageUpdate: (id = ':id') => `/department-image/update/${id}`,

  //Category
  Categorie: '/category',
  CategorieList: '/category/list',
  CategoryCreate: '/category/create',
  CategoryUpdate: (id = ':id') => `/category/update/${id}`,

  // Sub Category
  SubCategory: '/sub-category',
  SubCategoryList: '/sub-category/list',
  SubCategoryCreate: '/sub-category/create',
  SubCategoryUpdate: (id = ':id') => `/sub-category/update/${id}`,

  //Packages

  Package: '/package',
  PackageList: '/package/list',
  PackageCreate: '/package/create',
  PackageUpdate: (id = ':id') => `/package/update/${id}`,
  PackageDetails: (id = ':id') => `/package/details/${id}`,

  //Products
  Product: '/product',
  ProductList: '/product/list',
  ProductCreate: '/product/create',
  ProductUpdate: (id = ':id') => `/product/update/${id}`,
  ProductDetails: (id = ':id') => `/product/details/${id}`,

  //Providers
  Provider: '/provider',
  ProviderList: '/provider/list',
  ProviderCreate: '/provider/create',
  ProviderUpdate: (id = ':id') => `/provider/update/${id}`,
  ProviderDetails: (id = ':id') => `/provider/details/${id}`,

  //Provider Product
  ProviderProduct: '/provider-product',
  ProviderProductList: '/provider-product/list',
  ProviderProductCreate: '/provider-product/create',
  ProviderProductUpdate: (id = ':id') => `/provider-product/update/${id}`,
  ProviderProductDetails: (id = ':id') => `/provider-product/details/${id}`,

  //Provider Packages
  ProviderPackage: '/provider-package',
  ProviderPackageList: '/provider-package/list',
  ProviderPackageCreate: '/provider-package/create',
  ProviderPackageUpdate: (id = ':id') => `/provider-package/update/${id}`,
  ProviderPackageDetails: (id = ':id') => `/provider-package/Details/${id}`,

  //Provider Discount
  ProviderDiscount: '/provider-discount',
  ProviderDiscounList: '/provider-discount/list',
  ProviderDiscountsCreate: '/provider-discount/create',
  ProviderDiscountsUpdate: (id = ':id') => `/provider-discount/update/${id}`,

  //Provider Department
  ProviderDepartment: '/provider-Department',
  ProviderDepartmentList: '/provider-Department/list',
  ProviderDepartmentCreate: '/provider-Department/create',
  ProviderDepartmentUpdate: (id = ':id') => `/provider-Department/update/${id}`,
  ProviderDepartmentDetails: (id = ':id') =>
    `/provider-Department/details/${id}`,

  Banner: '/banner',
  BannerList: '/banner/list',

  //address
  address: '/address',
  //divisions - address
  Division: '/address/division',
  DivisionList: '/address/division/list',
  DivisionCreate: '/address/division/create',
  DivisionUpdate: (id = ':id') => `/address/division/update/${id}`,

  //Districts - address
  District: '/address/district',
  DistrictList: '/address/district/list',
  DistrictCreate: '/address/district/create',
  DistrictUpdate: (id = ':id') => `/address/district/update/${id}`,

  //zones - address
  Zone: '/address/zone',
  ZoneList: '/address/zone/list',
  ZoneCreate: '/address/zone/create',
  ZoneUpdate: (id = ':id') => `/address/zone/update/${id}`,

  //Addresses - address
  Address: '/address/addresses',
  AddressList: '/address/addresses/list',
  AddressCreate: '/address/addresses/create',
  AddressUpdate: (id = ':id') => `/address/addresses/update/${id}`,

  //Orders
  Order: '/order',
  OrderList: '/order/list',
  OrderCreate: '/order/create',
  OrderUpdate: (id = ':id') => `/order/update/${id}`,
  OrderInvoice: (id = ':id') => `/order/invoice/${id}`,
  OrderDetails: (id = ':id') => `/order/details/${id}`,

  //Order Request
  orderRequests: '/orders-requests',
  orderRequestCreate: '/orders-requests/create',
  orderRequestUpdate: '/orders-requests/update',

  //Ambulance Requests
  AmbulanceRequests: '/orders-requests/ambulance-requests',
  AmbulanceRequestCreate: '/orders-requests/ambulance-requests/create',
  AmbulanceRequestUpdate: '/orders-requests/ambulance-requests/update',

  //Blood Requests
  BloodRequests: '/orders-requests/blood-requests',
  BloodRequestCreate: '/orders-requests/blood-requests/create',
  BloodRequestUpdate: '/orders-requests/blood-requests/update',

  //medicineOrders
  MedicineOrder: '/medicine-order',
  MedicineOrderList: '/medicine-order/list',
  MedicineOrderCreate: '/medicine-order/create',
  MedicineOrderUpdate: (id = ':id') => `/medicine-order/update/${id}`,

  //diagnosticPrescrtiptions
  DiagnosticPrescription: '/diagnostic-prescription',
  DiagnosticPrescriptionList: '/diagnostic-prescription/list',
  DiagnosticPrescriptionCreate: '/diagnostic-prescription/create',
  DiagnosticPrescriptionUpdate: (id = ':id') =>
    `/diagnostic-prescription/update/${id}`,

  //Call Reaquest
  CallRequest: '/call-request',
  CallRequestList: '/call-request/list',
  CallRequestCreate: '/call-request/create',
  CallRequestUpdate: (id = ':id') => `/call-request/update/${id}`,
  CallRequestDetails: (id = ':id') => `/call-request/Details/${id}`,

  //Slots
  slots: '/slots',
  slotCreate: '/slots/create',
  slotUpdate: '/slots/update',

  //Featured Item
  FeaturedItem: '/featured-item',
  FeaturedItemList: '/featured-item/list',
  FeaturedItemCreate: '/featured-item/create',
  FeaturedItemUpdate: (id = ':id') => `/featured-item/update/${id}`,

  // Offer list
  Offer: '/offer',
  OfferList: '/offer/list',
  OfferCreate: '/offer/create',
  OfferUpdate: (id = ':id') => `/offer/update/${id}`,
  OfferDetails: (id = ':id') => `/offer/details/${id}`,
};
