import ProductDetails from '@modules/product/components/ProductDetails';
import { useProducts, useProviders } from '@shared/hooks';
import {
  IProduct,
  IProviderProductUpdate,
  IProvider,
  IProviderProduct,
} from '@shared/interfaces';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import { useEffect, useState } from 'react';
const { Option } = Select;

interface IProps {
  initialValues?: IProviderProduct;
  onFinish?: (values: IProviderProductUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const ProviderProductUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const products: IProduct[] = useProducts().data?.data?.payload;
  const providers: IProvider[] = useProviders().data?.data?.payload;

  const [productDetail, setProductDetail] = useState<IProduct>();

  // reset form value and set product details
  const [form] = Form.useForm();
  useEffect(() => {
    setProductDetail(
      products?.find((pd) => pd.id === initialValues.product?.id)
    );
    form.resetFields();
  }, [form, initialValues, products]);

  // set hbCommission Field Value
  const [hbCommission, setHbCommission] = useState({
    hbPatientPrice: initialValues.hbPatientPrice,
    hbDiscountPrice: initialValues.hbDiscountPrice,
  });
  useEffect(() => {
    form.setFieldsValue({
      hbCommission: hbCommission.hbPatientPrice - hbCommission.hbDiscountPrice,
    });
  }, [form, hbCommission]);

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={{
        ...initialValues,
        product: initialValues?.product?.id,
        provider: initialValues?.provider?.id,
        department: initialValues?.department?.id,
        category: initialValues?.category?.id,
        subCategory: initialValues?.subCategory?.id,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Provider"
            name="provider"
            rules={[
              {
                required: true,
                message: 'Please select a provider!',
              },
            ]}
          >
            <Select
              placeholder="Select a provider"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {providers?.map((pv) => (
                <Option key={pv.id} value={pv.id}>
                  {pv.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Product"
            name="product"
            rules={[
              {
                required: true,
                message: 'Please select a product!',
              },
            ]}
          >
            <Select
              placeholder="Select a product"
              showSearch
              allowClear
              onChange={(val) =>
                setProductDetail(products.find((pd) => pd.id === val))
              }
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {products?.map((pd) => (
                <Option key={pd.id} value={pd.id}>
                  {pd.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          {productDetail && (
            <div className="pb-5">
              <h2 className="text-2xl font-semibold pb-5 text-center underline">
                Product Details
              </h2>
              <ProductDetails product={productDetail} />
            </div>
          )}
        </Col>
        {productDetail && (
          <>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item label="Priority" name="priority">
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter priority"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item label="Is Active" name="isActive">
                <Radio.Group buttonStyle="solid" className="w-full">
                  <Radio.Button className="w-1/2 text-center" value={true}>
                    True
                  </Radio.Button>
                  <Radio.Button className="w-1/2 text-center" value={false}>
                    False
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Is Available"
                name="isAvailable"
                rules={[
                  {
                    required: true,
                    message: 'Please chose a value!',
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid" className="w-full">
                  <Radio.Button className="w-1/2 text-center" value={true}>
                    True
                  </Radio.Button>
                  <Radio.Button className="w-1/2 text-center" value={false}>
                    False
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Is HomeSampleAvailable"
                name="isHomeSampleAvailable"
                rules={[
                  {
                    required: true,
                    message: 'Please chose a value!',
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid" className="w-full">
                  <Radio.Button className="w-1/2 text-center" value={true}>
                    True
                  </Radio.Button>
                  <Radio.Button className="w-1/2 text-center" value={false}>
                    False
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Estimated Report Delivery Time"
                name="estimatedReportDeliveryTime"
                rules={[
                  {
                    required: true,
                    message: 'Please Report Delivery Time!',
                  },
                ]}
              >
                <Input placeholder="Enter Report Delivery Time" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Home collection charge"
                name="homeCollectionCharge"
                rules={[
                  {
                    required: true,
                    message: 'Please enter home collection charge!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter home collection charge"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Regular Price"
                name="regularPrice"
                rules={[
                  {
                    required: true,
                    message: 'Please enter home regular price!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter regular price"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Hb Discount Price"
                name="hbDiscountPrice"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Hb Discount Price!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter Hb Discount Price"
                  onChange={(val: number) =>
                    setHbCommission({ ...hbCommission, hbDiscountPrice: val })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Hb Patient Price"
                name="hbPatientPrice"
                rules={[
                  {
                    required: true,
                    message: 'Please enter patient Price!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter patient Price"
                  onChange={(val: number) =>
                    setHbCommission({ ...hbCommission, hbPatientPrice: val })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Hb Commission"
                name="hbCommission"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Hb Commission!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter Hb Commission"
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ProviderProductUpdateForm;
