import { getAccess } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useDeleteDivision, useDivisions } from '@shared/hooks';
import { IDivision } from '@shared/interfaces';
import { Button, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';

interface IProps {
  searchTerm?: string;
}
const DivisionList: React.FC<IProps> = ({ searchTerm }) => {
  const navigate = useNavigate();

  const deleteDivision = useDeleteDivision();

  const { search } = useLocation();
  const query = {
    page: parseInt(search.slice(6, 7)) || 1,
    limit: parseInt(search.slice(13)) || 10,
  };
  const { data, isLoading } = useDivisions({
    options: {
      page: query.page,
      limit: query.limit,
      searchTerm: searchTerm,
      sort: ['createdAt,DESC'],
    },
  });

  const dataSource = data?.data?.payload?.map((x: IDivision) => ({
    key: x.id,
    id: x.id,
    title: x.title,
    isActive: x.isActive.toString(),
  }));

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.DivisionUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() =>
              getAccess(['ADDRESS_MANAGEMENT', 'FORBIDDEN'], () =>
                deleteDivision.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: query.page,
        total: data?.data?.total,
        onChange: (page: number, limit: number) => {
          navigate(`?page=${page}&limit=${limit}`);
        },
      }}
    />
  );
};

export default DivisionList;
