import { getAccess } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useDeleteProviderProduct, useProviderProducts } from '@shared/hooks';
import { IProviderProduct } from '@shared/interfaces';
import { Button, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit, AiOutlineBars } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';

const ProviderProductList = () => {
  const navigate = useNavigate();

  const deleteProviderProduct = useDeleteProviderProduct();

  const { search } = useLocation();
  const query = {
    page: parseInt(search.slice(6, 7)) || 1,
    limit: parseInt(search.slice(13)) || 10,
  };
  const { data, isLoading } = useProviderProducts({
    options: {
      page: query.page,
      limit: query.limit,
      sort: ['createdAt,DESC'],
    },
  });

  const dataSource = data?.data?.payload?.map((x: IProviderProduct) => ({
    key: x.id,
    id: x.id,
    title: x.title,
    priority: x.priority,
    isActive: x.isActive.toString(),
    isAvailable: x.isAvailable.toString(),
    isHomeSampleAvailable: x.isHomeSampleAvailable.toString(),
    branch: x.branch,
    sampleType: x.sampleType,
    estimatedReportDeliveryTime: x.estimatedReportDeliveryTime,
    homeCollectionCharge: x.homeCollectionCharge,
    regularPrice: x.regularPrice,
    hbDiscountPrice: x.hbDiscountPrice,
    hbPatientPrice: x.hbPatientPrice,
    hbCommission: x.hbCommission,
    product: x.product?.title,
    provider: x.provider?.name,
    providerBranch: x.providerBranch?.title,
    department: x.department?.title,
    category: x.category?.title,
    subCategory: x.subCategory?.title,
  }));

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Is Available',
      dataIndex: 'isAvailable',
      key: 'isAvailable',
    },
    {
      title: 'Home Charge',
      dataIndex: 'homeCollectionCharge',
      key: 'homeCollectionCharge',
    },
    {
      title: 'Regular Price',
      dataIndex: 'regularPrice',
      key: 'regularPrice',
    },
    {
      title: 'Discount Price',
      dataIndex: 'hbDiscountPrice',
      key: 'hbDiscountPrice',
    },
    {
      title: 'Patient Price',
      dataIndex: 'hbPatientPrice',
      key: 'hbPatientPrice',
    },
    {
      title: 'Hb Commission',
      dataIndex: 'hbCommission',
      key: 'hbCommission',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.ProviderProductUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Package?"
            onConfirm={() =>
              getAccess(['PROVIDER_PRODUCT_DELETE', 'FORBIDDEN'], () =>
                deleteProviderProduct.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.ProviderProductDetails(id))}
          >
            <AiOutlineBars />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: query.page,
        total: data?.data?.total,
        onChange: (page: number, limit: number) => {
          navigate(`?page=${page}&limit=${limit}`);
        },
      }}
    />
  );
};
export default ProviderProductList;
