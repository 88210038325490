import { Authorization } from '@modules/auth';
import { useCallRequest, useUpdateCallRequest } from '@shared/hooks';
import { ICallRequestUpdate } from '@shared/interfaces';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CallRequestUpdateForm from '../components/CallRequestUpdateForm';

const CallRequestUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useCallRequest({ id });

  const updateCallRequest = useUpdateCallRequest({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  const initialValues: ICallRequestUpdate = {
    user: data?.data?.payload?.user?.id,
    package: data?.data?.payload?.package?.id,
    isActive: data?.data?.payload?.isActive,
    priority: data?.data?.payload?.priority,
  };
  return (
    <Authorization allowedAccess={['ORDER_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading} empty={false}>
        <PageHeader onBack={() => navigate(-1)} title="Update Call Request">
          <CallRequestUpdateForm
            initialValues={initialValues}
            isLoading={updateCallRequest.isLoading}
            onFinish={(values) =>
              updateCallRequest.mutateAsync({ ...values, id })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};

export default CallRequestUpdatePage;
