import { Button, Empty, notification, Spin, Upload } from 'antd';
import React, { CSSProperties, useState } from 'react';

import {
  LoadingOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { storage } from './_storage-function';
import { ENV } from '@shared/config';

// purify component start here
interface Props {
  loading?: boolean;
  empty?: boolean;
  hide?: '*' | 'loader' | 'empty';
  children?: any;
  style?: CSSProperties;
}
export const Purify: React.FC<Props> = ({
  empty = false,
  loading = false,
  hide,
  children,
  style,
}) => {
  return (
    <>
      {loading === true ? (
        hide === 'loader' || hide === '*' ? (
          <span></span>
        ) : (
          <div className="h-60 grid place-content-center" style={style}>
            <Spin className="content-center" />
          </div>
        )
      ) : empty === true ? (
        hide === 'empty' || hide === '*' ? (
          <span></span>
        ) : (
          <div style={style}>
            <Empty className="content-center" />
          </div>
        )
      ) : (
        children
      )}
    </>
  );
};
// purify component end

// custom image upload component
interface IUploadProps {
  type?: 'text' | 'picture' | 'picture-card';
  maxCount?: number;
  onChange?: (url: string) => void;
  showUploadList?: boolean;
}

export const CustomUpload = ({
  type = 'picture-card',
  maxCount = 1,
  onChange,
  showUploadList = true,
}: IUploadProps) => {
  const [image, setImage] = useState({
    url: '',
    isLoading: false,
  });

  const beforeUpload = (file: any) => {
    const acceptedType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg+xml';
    if (!acceptedType) {
      notification.error({
        message: 'You can only upload JPG/PNG/SVG file!',
        duration: 6,
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({
        message: 'Image must smaller than 2MB!',
        duration: 6,
      });
    }
    return acceptedType && isLt2M;
  };

  const handleUpload = (info: any) => {
    if (info.file?.status === 'uploading') {
      setImage({
        url: '',
        isLoading: true,
      });
    }
    if (info.file?.status === 'done') {
      onChange(info.file?.response?.payload?.links[0]);
      setImage({
        url: info.file?.response?.payload?.links[0],
        isLoading: false,
      });
    }
  };

  return (
    <Upload
      name="files"
      listType={type}
      headers={{
        'x-client-name': 'WEB',
        Authorization: `Bearer ${storage?.getToken()}`,
      }}
      showUploadList={showUploadList}
      maxCount={maxCount}
      onPreview={() => window.open(image.url)}
      action={`${ENV.API_END_POINT}/utils/upload`}
      beforeUpload={beforeUpload}
      onChange={handleUpload}
    >
      {type === 'picture-card' ? (
        <div>
          {image.isLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      ) : (
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      )}
    </Upload>
  );
};
