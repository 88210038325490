import { AddressService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useAddresss hook ------------------------------------
type IUseAddresses = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof AddressService.filter>;
};
export const useAddresses = ({ options, config }: IUseAddresses = {}) => {
  return useQuery({
    ...config,
    queryKey: [AddressService.NAME, options],
    queryFn: () => AddressService.filter(options),
  });
};

//----------------------- useAddress hook --------------------------------------
type IUseAddress = {
  id: string;
  config?: QueryConfig<typeof AddressService.filterById>;
};

export const useAddress = ({ id, config }: IUseAddress) => {
  return useQuery({
    ...config,
    queryFn: () => AddressService.filterById(id),
  });
};

//------------------ useCreateAddress hook ---------------------------------
type IUseCreateAddress = {
  config?: MutationConfig<typeof AddressService.create>;
};

export const useCreateAddress = ({ config }: IUseCreateAddress = {}) => {
  return useMutation({
    ...config,
    mutationFn: AddressService.create,
  });
};

//------------------ useUpdateAddress hook ----------------------------------
type IUseUpdateAddress = {
  config?: MutationConfig<typeof AddressService.update>;
};

export const useUpdateAddress = ({ config }: IUseUpdateAddress = {}) => {
  return useMutation({
    ...config,
    mutationFn: AddressService.update,
  });
};

//------------------ useDeleteAddress hook ----------------------------------
type IUseDeleteAddress = {
  config?: MutationConfig<typeof AddressService.delete>;
};

export const useDeleteAddress = ({ config }: IUseDeleteAddress = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(AddressService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: AddressService.delete,
  });
};
