import { Authorization } from '@modules/auth';
import { useProviderDiscount, useUpdateProviderDiscount } from '@shared/hooks';
import { IProviderDiscountUpdate } from '@shared/interfaces';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import ProviderDiscountUpdateForm from '../components/ProviderDiscountUpdateForm';

const ProviderDiscountUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useProviderDiscount({ id });

  const initialValues: IProviderDiscountUpdate = {
    ...data?.data?.payload,
    provider: data?.data?.payload?.provider?.id,
    validity: moment(data?.data?.payload?.validity),
  };

  const updateProviderDiscount = useUpdateProviderDiscount({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PROVIDER_DISCOUNT_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader
          onBack={() => navigate(-1)}
          title="Update Provider Discount"
        >
          <ProviderDiscountUpdateForm
            initialValues={initialValues}
            isLoading={updateProviderDiscount.isLoading}
            onFinish={(values) =>
              updateProviderDiscount.mutateAsync({ id, ...values })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default ProviderDiscountUpdateRoute;
