import { Authorization } from '@modules/auth';
import { useDepartmentImage, useUpdateDepartmentImage } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import DepartmentImageUpdateForm from '../components/DepartmentImageUpdateForm';

const DepartmentImageUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useDepartmentImage({ id });

  const updateDepartmentImage = useUpdateDepartmentImage({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['DEPARTMENT_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update DepartmentImage">
          <DepartmentImageUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateDepartmentImage.isLoading}
            onFinish={(values) =>
              updateDepartmentImage.mutateAsync({ ...values, id })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default DepartmentImageUpdatePage;
