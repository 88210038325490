import { Authorization } from '@modules/auth';
import { usePermission, useUpdatePermission } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import PermissionUpdateForm from '../components/PermissionUpdateForm';

const PermissionUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = usePermission({ id });

  const updateBaseDepartment = useUpdatePermission({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PERMISSION_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update Permission">
          <PermissionUpdateForm
            initialValues={{
              title: data?.data?.payload?.title,
              role: data?.data?.payload?.role?.id,
            }}
            isLoading={updateBaseDepartment.isLoading}
            onFinish={(values) =>
              updateBaseDepartment.mutateAsync({ id, ...values })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default PermissionUpdateRoute;
