import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import SubcategoriesRoute from './routes/SubcategoriesRoute';
import SubcategoryCreateRoute from './routes/SubcategoryCreateRoute';
import SubcategoryUpdateRoute from './routes/SubcategoryUpdateRoute';

export const SubCategoryRoutes = [
  { path: Paths.SubCategory, element: <Navigate to={Paths.SubCategory} /> },
  { path: Paths.SubCategoryList, element: <SubcategoriesRoute /> },
  { path: Paths.SubCategoryCreate, element: <SubcategoryCreateRoute /> },
  { path: Paths.SubCategoryUpdate(), element: <SubcategoryUpdateRoute /> },
];
