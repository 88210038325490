import { Authorization } from '@modules/auth';
import { useOrderRequests } from '@shared/hooks';
import { _ } from '@shared/utils';
import { PageHeader } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AmbulanceRequestQueryForm from '../components/AmbulanceRequestQueryForm';
import AmbulanceRequestsList from '../components/AmbulanceRequestsList';

const AmbulanceRequestsRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState({
    user: searchParams.get('user'),
  });

  const { data, isLoading } = useOrderRequests({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      filter: ['requestType||$eq||AMBULANCE'],
      s: _.toCleanObject({
        'user.id': query?.user,
      }),
      sort: ['createdAt,DESC'],
    },
  });
  return (
    <Authorization allowedAccess={['ORDER_VIEW', 'FORBIDDEN']}>
      <PageHeader title="Ambulance Requests">
        <AmbulanceRequestQueryForm
          initialValues={_.toCleanObject({ ...query })}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        />
        <AmbulanceRequestsList
          isLoading={isLoading}
          data={data?.data?.payload}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};
export default AmbulanceRequestsRoute;
