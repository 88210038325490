import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';
import { OfferService } from '@shared/services';

//---------------- useOffers hook ------------------------------------
type IuseOffers = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof OfferService.filter>;
};
export const useOffers = ({ options, config }: IuseOffers = {}) => {
  return useQuery({
    ...config,
    queryKey: [OfferService.NAME, options],
    queryFn: () => OfferService.filter(options),
  });
};

//----------------------- useOffer hook --------------------------------------
type IuseOffer = {
  id: string;
  config?: QueryConfig<typeof OfferService.filterById>;
};

export const useOffer = ({ id, config }: IuseOffer) => {
  return useQuery({
    ...config,
    queryFn: () => OfferService.filterById(id),
  });
};

//------------------ useCreateSubscription hook ---------------------------------
type IUseCreateOffer = {
  config?: MutationConfig<typeof OfferService.create>;
};

export const useCreateOffer = ({ config }: IUseCreateOffer = {}) => {
  return useMutation({
    ...config,
    mutationFn: OfferService.create,
  });
};

//------------------ useUpdateOffer hook ----------------------------------
type IUseUpdateOffer = {
  config?: MutationConfig<typeof OfferService.update>;
};

export const useUpdateOffer = ({ config }: IUseUpdateOffer = {}) => {
  return useMutation({
    ...config,
    mutationFn: OfferService.update,
  });
};

//------------------ useDeleteOffer hook ----------------------------------
type IUseDeleteOffer = {
  config?: MutationConfig<typeof OfferService.delete>;
};

export const useDeleteOffer = ({ config }: IUseDeleteOffer = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(OfferService.NAME);
        notification.success({
          message: 'Offer successfully deleted',
        });
      }
    },
    mutationFn: OfferService.delete,
  });
};
