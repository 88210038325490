import { Authorization } from '@modules/auth';
import { useProduct } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ProductDetails from '../components/ProductDetails';

const ProductDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useProduct({ id });

  return (
    <Authorization allowedAccess={['PRODUCT_VIEW', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Product details">
          <ProductDetails product={data?.data?.payload} />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default ProductDetailsPage;
