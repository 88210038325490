import { useFamilies } from '@shared/hooks';
import { IFamily } from '@shared/interfaces';
import { Col, Form, Input, Row, Select } from 'antd';

interface IProps {
  initialValues: object;
  onValuesChange: (c, v) => void;
}
const SubscriptionQueryForm: React.FC<IProps> = ({
  initialValues,
  onValuesChange,
}) => {
  const families: IFamily[] = useFamilies()?.data?.data?.payload;

  return (
    <Form
      size="large"
      layout="vertical"
      className="data-query-form"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="title" name="title">
            <Input allowClear placeholder="Search by title" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="family" name="family">
            <Select
              showSearch
              allowClear
              placeholder="Select a family"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {families?.map((family) => (
                <Select.Option key={family?.id} value={family?.id}>
                  {`${family?.firstName} ${family?.lastName} -${family?.relationship}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SubscriptionQueryForm;
