import { Authorization } from '@modules/auth';
import { useCreateAddress } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import AddressCreateForm from '../components/AddressCreateForm';

const AddressCreateRoute = () => {
  const navigate = useNavigate();
  const createAddress = useCreateAddress({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Address">
        <AddressCreateForm
          isLoading={createAddress.isLoading}
          onFinish={(values) => createAddress.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};

export default AddressCreateRoute;
