import { Authorization } from '@modules/auth';
import { useOrderRequests } from '@shared/hooks';
import { _ } from '@shared/utils';
import { Button, PageHeader } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BloodRequestQueryForm from '../components/BloodRequestQueryForm';
import BloodRequestsList from '../components/BloodRequestsList';

const BloodRequestsRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState({
    user: searchParams.get('user'),
    bloodGroup: searchParams.get('bloodGroup'),
  });

  const { data, isLoading } = useOrderRequests({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      filter: ['requestType||$eq||BLOOD'],

      s: _.toCleanObject({
        'user.id': query?.user,
        bloodGroup: { $starts: query?.bloodGroup },
      }),
      sort: ['createdAt,DESC'],
    },
  });
  // console.log(data);
  return (
    <Authorization allowedAccess={['ORDER_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Blood Requests"
        extra={[
          <Button key="1" onClick={() => null} type="primary">
            Create
          </Button>,
        ]}
      >
        <BloodRequestQueryForm
          initialValues={_.toCleanObject({ ...query })}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        />
        <BloodRequestsList
          isLoading={isLoading}
          data={data?.data?.payload}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};
export default BloodRequestsRoute;
