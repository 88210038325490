import { FeaturedItemService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useFeaturedItems hook ------------------------------------
type IUseFeaturedItems = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof FeaturedItemService.filter>;
};
export const useFeaturedItems = ({
  options,
  config,
}: IUseFeaturedItems = {}) => {
  return useQuery({
    ...config,
    queryKey: [FeaturedItemService.NAME, options],
    queryFn: () => FeaturedItemService.filter(options),
  });
};

//----------------------- useFeaturedItem hook --------------------------------------
type IUseFeaturedItem = {
  id: string;
  config?: QueryConfig<typeof FeaturedItemService.filterById>;
};

export const useFeaturedItem = ({ id, config }: IUseFeaturedItem) => {
  return useQuery({
    ...config,
    queryFn: () => FeaturedItemService.filterById(id),
  });
};

//------------------ useCreateFeaturedItem hook ---------------------------------
type IUseCreateFeaturedItem = {
  config?: MutationConfig<typeof FeaturedItemService.create>;
};

export const useCreateFeaturedItem = ({
  config,
}: IUseCreateFeaturedItem = {}) => {
  return useMutation({
    ...config,
    mutationFn: FeaturedItemService.create,
  });
};

//------------------ useUpdateFeaturedItem hook ----------------------------------
type IUseUpdateFeaturedItem = {
  config?: MutationConfig<typeof FeaturedItemService.update>;
};

export const useUpdateFeaturedItem = ({
  config,
}: IUseUpdateFeaturedItem = {}) => {
  return useMutation({
    ...config,
    mutationFn: FeaturedItemService.update,
  });
};

//------------------ useDeleteFeaturedItem hook ----------------------------------
type IUseDeleteFeaturedItem = {
  config?: MutationConfig<typeof FeaturedItemService.delete>;
};

export const useDeleteFeaturedItem = ({
  config,
}: IUseDeleteFeaturedItem = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(FeaturedItemService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: FeaturedItemService.delete,
  });
};
