import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import ProviderDiscountCreateRoute from './routes/ProviderDiscountCreateRoute';
import ProviderDiscountsRoute from './routes/ProviderDiscountsRoute';
import ProviderDiscountUpdateRoute from './routes/ProviderDiscountUpdateRoute';

export const ProviderDiscountRoutes = [
  { path: Paths.ProviderDiscounList, element: <ProviderDiscountsRoute /> },
  {
    path: Paths.ProviderDiscountsCreate,
    element: <ProviderDiscountCreateRoute />,
  },
  {
    path: Paths.ProviderDiscountsUpdate(),
    element: <ProviderDiscountUpdateRoute />,
  },
  {
    path: Paths.ProviderDiscount,
    element: <Navigate to={Paths.ProviderDiscounList} />,
  },
];
