import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import BaseDepartmentCreatePage from './routes/BaseDepartmentCreatePage';
import BaseDepartmentsPage from './routes/BaseDepartmentsPage';
import BaseDepartmentUpdatePage from './routes/BaseDepartmentUpdatePage';

export const BaseDepartmentRoutes = [
  { path: Paths.BaseDepartmentList, element: <BaseDepartmentsPage /> },
  { path: Paths.BaseDepartmentCreate, element: <BaseDepartmentCreatePage /> },
  { path: Paths.BaseDepartmentUpdate(), element: <BaseDepartmentUpdatePage /> },
  {
    path: Paths.BaseDepartment,
    element: <Navigate to={Paths.BaseDepartmentList} />,
  },
];
