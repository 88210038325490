import React, { useEffect, useState } from 'react';
import {
  ICategory,
  ISubcategory,
  ISubcategoryUpdate,
} from '@shared/interfaces';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import { CustomUpload } from '@shared/utils';
import { useCategories } from '@shared/hooks';
import ImagePreview from '@shared/components/ImagePreview';
const { Option } = Select;

interface IProps {
  initialValues?: ISubcategory;
  onFinish?: (values: ISubcategoryUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const SubcategoryUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const categories: ICategory[] = useCategories().data?.data?.payload;

  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const [images, setImages] = useState({
    icon: '',
    image: '',
    backgroundImage: '',
  });

  const onSubmitForm = (values: ISubcategoryUpdate) => {
    values.icon = images.icon || initialValues.icon;
    values.image = images.image || initialValues.image;
    values.backgroundImage =
      images.backgroundImage || initialValues.backgroundImage;
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={{
        ...initialValues,
        category: initialValues.category?.id,
      }}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter a title!',
              },
            ]}
          >
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Category"
            name="category"
            rules={[
              {
                required: true,
                message: 'Please select a category!',
              },
            ]}
          >
            <Select
              placeholder="Select a category"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {categories?.map((ct) => (
                <Option key={ct.id} value={ct.id}>
                  {ct.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Featured" name="isFeatured">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item label="Priority" name="priority">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter priority"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={1} placeholder="Enter description" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Icon" name="icon">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.icon || initialValues.icon}
                  alt="icon"
                />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImages({ ...images, icon: url })}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.image || initialValues.image}
                  alt="Thumb"
                />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImages({ ...images, image: url })}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Background Image" name="backgroundImage">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.backgroundImage || initialValues.backgroundImage}
                  alt="backgroundImage"
                />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) =>
                  setImages({ ...images, backgroundImage: url })
                }
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default SubcategoryUpdateForm;
