import {
  IBaseFilter,
  IOfferCreate,
  IOfferResponse,
  IOffersResponse,
  IOfferUpdate,
} from '@shared/interfaces';

import { CoreAxiosInstance } from '@shared/config';
import { AxiosResponse } from 'axios';
import { _ } from '@shared/utils';

const END_POINT: string = '/offers';

export const OfferService = {
  NAME: END_POINT,
  create(payload: IOfferCreate): Promise<AxiosResponse<IOfferResponse>> {
    return CoreAxiosInstance.post(END_POINT, payload);
  },
  filter(options: IBaseFilter): Promise<AxiosResponse<IOffersResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}?${_.queryNormalizer(options)}`);
  },
  filterById(id: string): Promise<AxiosResponse<IOfferResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}/${id}`);
  },
  update(payload: IOfferUpdate): Promise<AxiosResponse<IOfferResponse>> {
    const { id } = payload;
    delete payload.id;
    return CoreAxiosInstance.patch(`${END_POINT}/${id}`, payload);
  },
  delete(id: string): Promise<AxiosResponse<IOfferResponse>> {
    return CoreAxiosInstance.delete(`${END_POINT}/${id}`);
  },
};
