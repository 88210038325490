import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import ProductCreatePage from './routes/ProductCreatePage';
import ProductDetailsPage from './routes/ProductDetailsPage';
import ProductsPage from './routes/ProductsPage';
import ProductUpdatePage from './routes/ProductUpdatePage';

export const ProductRoutes = [
  { path: Paths.ProductList, element: <ProductsPage /> },
  { path: Paths.ProductCreate, element: <ProductCreatePage /> },
  { path: Paths.ProductUpdate(), element: <ProductUpdatePage /> },
  { path: Paths.ProductDetails(), element: <ProductDetailsPage /> },
  { path: Paths.Product, element: <Navigate to={Paths.ProductList} /> },
];
