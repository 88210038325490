import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { Button, Input, PageHeader } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import ProviderDiscountList from '../components/ProviderDiscountList';

const ProviderDiscountsRoute = () => {
  const navigate = useNavigate();
  return (
    <Authorization allowedAccess={['PROVIDER_DISCOUNT_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Provider Discount List"
        subTitle={
          <Input
            size="large"
            placeholder="Search"
            prefix={<AiOutlineSearch />}
            style={{ borderRadius: 30 }}
          />
        }
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.ProviderDiscountsCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <ProviderDiscountList />
      </PageHeader>
    </Authorization>
  );
};

export default ProviderDiscountsRoute;
