import { CallRequestService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useCallRequests hook ------------------------------------
type IUseCallRequests = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof CallRequestService.filter>;
};
export const useCallRequests = ({ options, config }: IUseCallRequests = {}) => {
  return useQuery({
    ...config,
    queryKey: [CallRequestService.NAME, options],
    queryFn: () => CallRequestService.filter(options),
  });
};

//----------------------- useCallRequest hook --------------------------------------
type IUseCallRequest = {
  id: string;
  config?: QueryConfig<typeof CallRequestService.filterById>;
};

export const useCallRequest = ({ id, config }: IUseCallRequest) => {
  return useQuery({
    ...config,
    queryFn: () => CallRequestService.filterById(id),
  });
};

//------------------ useCreateCallRequest hook ---------------------------------
type IUseCreateCallRequest = {
  config?: MutationConfig<typeof CallRequestService.create>;
};

export const useCreateCallRequest = ({
  config,
}: IUseCreateCallRequest = {}) => {
  return useMutation({
    ...config,
    mutationFn: CallRequestService.create,
  });
};

//------------------ useUpdateCallRequest hook ----------------------------------
type IUseUpdateCallRequest = {
  config?: MutationConfig<typeof CallRequestService.update>;
};

export const useUpdateCallRequest = ({
  config,
}: IUseUpdateCallRequest = {}) => {
  return useMutation({
    ...config,
    mutationFn: CallRequestService.update,
  });
};

//------------------ useDeleteCallRequest hook ----------------------------------
type IUseDeleteCallRequest = {
  config?: MutationConfig<typeof CallRequestService.delete>;
};

export const useDeleteCallRequest = ({
  config,
}: IUseDeleteCallRequest = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(CallRequestService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: CallRequestService.delete,
  });
};
