import {
  Button,
  Col,
  Form,
  Row,
  Select,
  notification,
  Radio,
  InputNumber,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { queryClient } from '@shared/config';
import { useBanner, useUpdateBanner } from '@shared/hooks';
import { BannerService } from '@shared/services';
import { IUpdateBanner } from '@shared/interfaces';
import { CustomUpload, Purify } from '@shared/utils';

interface IFProps {
  id?: string;
  onFinish: () => void;
}
const BannerEditForm: React.FC<IFProps> = ({ id, onFinish }) => {
  const { data, isLoading, refetch } = useBanner({ id });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const updateBanner = useUpdateBanner({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          onFinish();
          queryClient.invalidateQueries(BannerService.NAME);
          notification.success({
            type: 'success',
            message: res?.data?.message,
          });
        } else {
          notification.error({
            type: 'error',
            message: res?.data?.message || 'Something is wrong!',
          });
        }
      },
    },
  });

  // banner image upload
  const [image, setImage] = useState('');
  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    setImage(data?.data?.payload?.link);
    form.resetFields();
  }, [form, data]);

  const onSubmit = (values: IUpdateBanner) => {
    values.link = image;
    updateBanner.mutateAsync({ ...values, id });
  };

  return (
    <div className="banner-create-form">
      <Purify loading={isLoading}>
        <Form
          form={form}
          size="large"
          layout="vertical"
          onFinish={onSubmit}
          initialValues={data?.data?.payload}
        >
          <Row gutter={{ xs: 10, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Banner Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Please input a type!',
                  },
                ]}
              >
                <Select placeholder="Select a type">
                  <Select.Option value="HERO_SLIDER">HERO_SLIDER</Select.Option>
                  <Select.Option value="OFFER_SLIDER">
                    OFFER_SLIDER
                  </Select.Option>
                  <Select.Option value="COUPON_SLIDER">
                    COUPON_SLIDER
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Is Active"
                name="isActive"
                rules={[
                  {
                    required: true,
                    message: 'Please chose a value!',
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid" className="w-full">
                  <Radio.Button className="w-1/2 text-center" value={true}>
                    True
                  </Radio.Button>
                  <Radio.Button className="w-1/2 text-center" value={false}>
                    False
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Priority" name="priority">
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter  priority"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Image" name="image">
                <div className="flex gap-4 items-center">
                  <div>
                    <div className="uploaded-image-view">
                      <img src={image} alt="bannerImage" />
                    </div>
                  </div>
                  <CustomUpload onChange={(url) => setImage(url)} />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <div className="text-right">
            <Button
              loading={updateBanner.isLoading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Purify>
    </div>
  );
};

export default BannerEditForm;
