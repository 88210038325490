import PackageDetails from '@modules/package/components/PackageDetails';
import { usePackages, useProviders } from '@shared/hooks';
import {
  IPackage,
  IProvider,
  IProviderPackage,
  IProviderPackageCreate,
} from '@shared/interfaces';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
} from 'antd';
import { useEffect, useState } from 'react';
const { Option } = Select;

interface IProps {
  initialValues?: IProviderPackage;
  onFinish?: (values: IProviderPackageCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const ProviderPackageCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const providers: IProvider[] = useProviders().data?.data?.payload;

  const packages: IPackage[] = usePackages().data?.data?.payload;

  const [packageDetail, setPackageDetail] = useState<IPackage>();

  const [hbDiscountToggle, setHbDiscountToggle] = useState(false);
  const [hbPatientToggle, setHbPatientToggle] = useState(false);

  const [form] = Form.useForm();

  // set hbDiscountPrice Field Value
  const [hbDiscount, setHbDiscount] = useState({
    regularPrice: 0,
    hbDiscountPercentage: 0,
  });
  const [hbPatient, setHbPatient] = useState({
    regularPrice: 0,
    hbPatientPercentage: 0,
  });
  const newHbDiscount = Math.round(
    hbDiscount.regularPrice -
      (hbDiscount.regularPrice / 100) * hbDiscount.hbDiscountPercentage
  );

  const newPatientDiscount = Math.round(
    hbDiscount.regularPrice -
      (hbDiscount.regularPrice / 100) * hbPatient.hbPatientPercentage
  );

  // set hbCommission Field Value
  const [hbCommission, setHbCommission] = useState({
    hbPatientPrice: 0,
    hbDiscountPrice: 0,
  });
  useEffect(() => {
    setHbCommission({
      ...hbCommission,
      hbDiscountPrice:
        hbDiscount.hbDiscountPercentage > 0
          ? newHbDiscount
          : hbCommission.hbDiscountPrice,
      hbPatientPrice:
        hbPatient.hbPatientPercentage > 0
          ? newPatientDiscount
          : hbCommission.hbPatientPrice,
    });
    form.setFieldsValue({
      hbDiscountPrice:
        hbDiscount.hbDiscountPercentage > 0
          ? newHbDiscount
          : hbCommission.hbDiscountPrice,
      hbPatientPrice:
        hbPatient.hbPatientPercentage > 0
          ? newPatientDiscount
          : hbCommission.hbPatientPrice,
    });
  }, [
    form,
    hbCommission,
    hbDiscount.hbDiscountPercentage,
    hbPatient.hbPatientPercentage,
    newHbDiscount,
    newPatientDiscount,
  ]);
  useEffect(() => {
    form.setFieldsValue({
      hbCommission: hbCommission.hbPatientPrice - hbCommission.hbDiscountPrice,
    });
  }, [form, hbCommission]);

  const onSubmitForm = (values: IProviderPackageCreate | any) => {
    values.duration = {
      daily: values.duration === 'daily' ? true : false,
      weekly: values.duration === 'weekly' ? true : false,
      monthly: values.duration === 'monthly' ? true : false,
      quarterly: values.duration === 'quarterly' ? true : false,
      halfYearly: values.duration === 'halfYearly' ? true : false,
      yearly: values.duration === 'yearly' ? true : false,
    };
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Provider"
            name="provider"
            rules={[
              {
                required: true,
                message: 'Please select a provider!',
              },
            ]}
          >
            <Select
              placeholder="Select a provider"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {providers?.map((pv) => (
                <Option key={pv.id} value={pv.id}>
                  {pv.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Package"
            name="package"
            rules={[
              {
                required: true,
                message: 'Please select a Package!',
              },
            ]}
          >
            <Select
              placeholder="Select a Package"
              showSearch
              allowClear
              onChange={(val) =>
                setPackageDetail(packages.find((pk) => pk.id === val))
              }
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {packages?.map((pk) => (
                <Option key={pk.id} value={pk.id}>
                  {pk.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {packageDetail && (
          <Col span={24}>
            <div className="pb-5">
              <h2 className="text-2xl font-semibold pb-5 text-center underline">
                Product Details
              </h2>
              <PackageDetails Package={packageDetail} />
            </div>
          </Col>
        )}

        {packageDetail && (
          <>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item label="Priority" name="priority">
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter priority"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item label="Is Active" name="isActive">
                <Radio.Group buttonStyle="solid" className="w-full">
                  <Radio.Button className="w-1/2 text-center" value={true}>
                    True
                  </Radio.Button>
                  <Radio.Button className="w-1/2 text-center" value={false}>
                    False
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Is Available"
                name="isAvailable"
                rules={[
                  {
                    required: true,
                    message: 'Please chose a value!',
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid" className="w-full">
                  <Radio.Button className="w-1/2 text-center" value={true}>
                    True
                  </Radio.Button>
                  <Radio.Button className="w-1/2 text-center" value={false}>
                    False
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Is HomeSampleAvailable"
                name="isHomeSampleAvailable"
                rules={[
                  {
                    required: true,
                    message: 'Please chose a value!',
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid" className="w-full">
                  <Radio.Button className="w-1/2 text-center" value={true}>
                    True
                  </Radio.Button>
                  <Radio.Button className="w-1/2 text-center" value={false}>
                    False
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item label="Branch" name="branch">
                <Input placeholder="Enter branch" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item label="Sample Type" name="sampleType">
                <Input placeholder="Enter sample type" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Estimated Report Delivery Time"
                name="estimatedReportDeliveryTime"
              >
                <Input placeholder="Enter Report Delivery Time" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Home collection charge"
                name="homeCollectionCharge"
                rules={[
                  {
                    required: true,
                    message: 'Please enter home collection charge!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter home collection charge"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Regular Price"
                name="regularPrice"
                rules={[
                  {
                    required: true,
                    message: 'Please enter home regular price!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter regular price"
                  onChange={(val: number) =>
                    setHbDiscount({
                      ...hbDiscount,
                      regularPrice: val,
                    })
                  }
                />
              </Form.Item>
            </Col>

            {hbDiscountToggle && (
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item
                  label="Hb Discount Percentage"
                  name="hbDiscountPercentage"
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Enter Hb Discount Percentage"
                    onChange={(val: number) =>
                      setHbDiscount({
                        ...hbDiscount,
                        hbDiscountPercentage: val,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label={
                  <div className="flex">
                    <span className="mr-2">Hb Discount Price</span>
                    <Switch
                      checked={hbDiscountToggle}
                      checkedChildren="Percentage"
                      unCheckedChildren="Static"
                      onChange={() => {
                        setHbDiscountToggle(!hbDiscountToggle);
                      }}
                    />
                  </div>
                }
                name="hbDiscountPrice"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Hb Discount Price!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter Hb Discount Price"
                  onChange={(val: number) =>
                    setHbCommission({ ...hbCommission, hbDiscountPrice: val })
                  }
                />
              </Form.Item>
            </Col>

            {hbPatientToggle && (
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item
                  label="Hb Patient Percentage"
                  name="hbPatientPercentage"
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Enter Hb Patient Percentage"
                    onChange={(val: number) =>
                      setHbPatient({
                        ...hbPatient,
                        hbPatientPercentage: val,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label={
                  <div className="flex">
                    <span className="mr-2">Hb Patient Price</span>
                    <Switch
                      checked={hbPatientToggle}
                      checkedChildren="Percentage"
                      unCheckedChildren="Static"
                      onChange={() => {
                        setHbPatientToggle(!hbPatientToggle);
                      }}
                    />
                  </div>
                }
                name="hbPatientPrice"
                rules={[
                  {
                    required: true,
                    message: 'Please enter patient Price!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter patient Price"
                  onChange={(val: number) =>
                    setHbCommission({ ...hbCommission, hbPatientPrice: val })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Hb Commission"
                name="hbCommission"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Hb Commission!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter Hb Commission"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Duration" name="duration">
                <Radio.Group buttonStyle="solid" className="w-full">
                  <Radio.Button className="w-1/6 text-center" value="daily">
                    Daily
                  </Radio.Button>
                  <Radio.Button className="w-1/6 text-center" value="weekly">
                    Weekly
                  </Radio.Button>
                  <Radio.Button className="w-1/6 text-center" value="monthly">
                    Monthly
                  </Radio.Button>
                  <Radio.Button className="w-1/6 text-center" value="quarterly">
                    Quarterly
                  </Radio.Button>
                  <Radio.Button className="w-1/6 text-center" value="yearly">
                    Yearly
                  </Radio.Button>
                  <Radio.Button
                    className="w-1/6 text-center"
                    value="halfYearly"
                  >
                    HalfYearly
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </>
        )}

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default ProviderPackageCreateForm;
