import { ProductService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useProducts hook ------------------------------------
type IUseProducts = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof ProductService.filter>;
};
export const useProducts = ({ options, config }: IUseProducts = {}) => {
  return useQuery({
    ...config,
    queryKey: [ProductService.NAME, options],
    queryFn: () => ProductService.filter(options),
  });
};

//----------------------- useProduct hook --------------------------------------
type IUseProduct = {
  id: string;
  config?: QueryConfig<typeof ProductService.filterById>;
};

export const useProduct = ({ id, config }: IUseProduct) => {
  return useQuery({
    ...config,
    queryFn: () => ProductService.filterById(id),
  });
};

//------------------ useCreateProduct hook ---------------------------------
type IUseCreateProduct = {
  config?: MutationConfig<typeof ProductService.create>;
};

export const useCreateProduct = ({ config }: IUseCreateProduct = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProductService.create,
  });
};

//------------------ useUpdateProduct hook ----------------------------------
type IUseUpdateProduct = {
  config?: MutationConfig<typeof ProductService.update>;
};

export const useUpdateProduct = ({ config }: IUseUpdateProduct = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProductService.update,
  });
};

//------------------ useDeleteProduct hook ----------------------------------
type IUseDeleteProduct = {
  config?: MutationConfig<typeof ProductService.delete>;
};

export const useDeleteProduct = ({ config }: IUseDeleteProduct = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(ProductService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: ProductService.delete,
  });
};
