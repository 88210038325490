import { Authorization } from '@modules/auth';
import { useProviderPackage } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

const ProviderPackageDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useProviderPackage({ id });

  console.log(data?.data?.payload);

  return (
    <Authorization allowedAccess={['PROVIDER_PACKAGE_VIEW', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader
          onBack={() => navigate(-1)}
          title="Provider Package details"
        ></PageHeader>
      </Purify>
    </Authorization>
  );
};
export default ProviderPackageDetailsPage;
