import { Authorization } from '@modules/auth';
import { useDepartment, useUpdateDepartment } from '@shared/hooks';
import { IDepartmentUpdate } from '@shared/interfaces';
import { Purify, _ } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import DepartmentUpdateForm from '../components/DepartmentUpdateForm';

const DepartmentUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useDepartment({ id });

  const updateDepartment = useUpdateDepartment({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  const initialValues: IDepartmentUpdate = {
    ...data?.data?.payload,
    serviceType: data?.data?.payload?.serviceType?.id,
    howItWorks: _.jsonParser(data?.data?.payload?.howItWorks),
    whyBookWithUs: _.jsonParser(data?.data?.payload?.whyBookWithUs),
  };

  return (
    <Authorization allowedAccess={['DEPARTMENT_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading} empty={false}>
        <PageHeader onBack={() => navigate(-1)} title="Update Department">
          <DepartmentUpdateForm
            initialValues={initialValues}
            isLoading={updateDepartment.isLoading}
            onFinish={(values) =>
              updateDepartment.mutateAsync({ ...values, id })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default DepartmentUpdatePage;
