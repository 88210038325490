import ImagePreview from '@shared/components/ImagePreview';
import { useProviders } from '@shared/hooks';
import { IProvider, IProviderDiscountUpdate } from '@shared/interfaces';
import { CustomUpload } from '@shared/utils';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface IProps {
  initialValues?: IProviderDiscountUpdate;
  onFinish?: (values: IProviderDiscountUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const ProviderDiscountUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const providers: IProvider[] = useProviders().data?.data?.payload;

  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const [image, setImage] = useState<string>();
  const onSubmitForm = (values: IProviderDiscountUpdate) => {
    values.image = image || initialValues.image;
    values.validity = moment(values.validity).format('YYYY-MM-DD: HH:MM:SS');

    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Provider"
            name="provider"
            rules={[
              {
                required: true,
                message: 'Please select a provider!',
              },
            ]}
          >
            <Select
              placeholder="Select a provider"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {providers?.map((pv) => (
                <Select.Option key={pv.id} value={pv.id}>
                  {pv.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Title" name="title">
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="code" name="code">
            <Input placeholder="Enter code" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item label="Priority" name="priority">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter priority"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Featured" name="isFeatured">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Discount Type" name="discountType">
            <Select placeholder="Select  Discount Type">
              <Select.Option value="OFFER">OFFER</Select.Option>
              <Select.Option value="COUPON">COUPON</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Usages Type" name="usagesType">
            <Select placeholder="Select  Usages Type">
              <Select.Option value="ONLINE">ONLINE</Select.Option>
              <Select.Option value="OFFLINE">OFFLINE</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Website" name="website">
            <Input placeholder="Enter a website url" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Validity" name="validity">
            <DatePicker showTime />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={1} placeholder="Write something" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview src={image || initialValues.image} alt="logo" />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImage(url)}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default ProviderDiscountUpdateForm;
