import { ProviderPackageService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useProviderPackages hook ------------------------------------
type IUseProviderPackages = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof ProviderPackageService.filter>;
};
export const useProviderPackages = ({
  options,
  config,
}: IUseProviderPackages = {}) => {
  return useQuery({
    ...config,
    queryKey: [ProviderPackageService.NAME, options],
    queryFn: () => ProviderPackageService.filter(options),
  });
};

//----------------------- useProviderPackage hook --------------------------------------
type IUseProviderPackage = {
  id: string;
  config?: QueryConfig<typeof ProviderPackageService.filterById>;
};

export const useProviderPackage = ({ id, config }: IUseProviderPackage) => {
  return useQuery({
    ...config,
    queryFn: () => ProviderPackageService.filterById(id),
  });
};

//------------------ useCreateProviderPackage hook ---------------------------------
type IUseCreateProviderPackage = {
  config?: MutationConfig<typeof ProviderPackageService.create>;
};

export const useCreateProviderPackage = ({
  config,
}: IUseCreateProviderPackage = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderPackageService.create,
  });
};

//------------------ useUpdateProviderPackage hook ----------------------------------
type IUseUpdateProviderPackage = {
  config?: MutationConfig<typeof ProviderPackageService.update>;
};

export const useUpdateProviderPackage = ({
  config,
}: IUseUpdateProviderPackage = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderPackageService.update,
  });
};

//------------------ useDeleteProviderPackage hook ----------------------------------
type IUseDeleteProviderPackage = {
  config?: MutationConfig<typeof ProviderPackageService.delete>;
};

export const useDeleteProviderPackage = ({
  config,
}: IUseDeleteProviderPackage = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(ProviderPackageService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: ProviderPackageService.delete,
  });
};
