import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import DiagnosticPrescriptionCreatePage from './routes/DiagnosticPrescriptionCreatePage';
import DiagnosticPrescriptionsRoute from './routes/DiagnosticPrescriptionsRoute';

export const DiagnosticPrescriptionsRoutes = [
  {
    path: Paths.DiagnosticPrescriptionList,
    element: <DiagnosticPrescriptionsRoute />,
  },

  {
    path: Paths.DiagnosticPrescriptionCreate,
    element: <DiagnosticPrescriptionCreatePage />,
  },
  {
    path: Paths.DiagnosticPrescription,
    element: <Navigate to={Paths.DiagnosticPrescriptionList} />,
  },
];
