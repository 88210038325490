import { useDeleteBaseDepartment, useBaseDepartments } from '@shared/hooks';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '@shared/constant';
import { IBaseDepartment } from '@shared/interfaces';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import ImagePreview from '@shared/components/ImagePreview';
import { getAccess } from '@modules/auth';

interface IProps {
  searchTerm?: string;
}

const BaseDepartmentList: React.FC<IProps> = ({ searchTerm }) => {
  const navigate = useNavigate();

  const deleteBaseDepartment = useDeleteBaseDepartment();

  const { search } = useLocation();
  const query = {
    page: parseInt(search.slice(6, 7)) || 1,
    limit: parseInt(search.slice(13)) || 10,
  };

  const { data, isLoading } = useBaseDepartments({
    options: {
      page: query.page,
      limit: query.limit,
      searchTerm: searchTerm,
      sort: ['createdAt,DESC'],
    },
  });

  const dataSource = data?.data?.payload?.map((x: IBaseDepartment) => ({
    key: x.id,
    id: x.id,
    icon: x.icon,
    title: x.title,
    priority: x.priority,
    isActive: x.isActive?.toString(),
    description: x.description,
  }));

  const columns: any = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon: string) => <ImagePreview src={icon} alt="icon" />,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.BaseDepartmentUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Base Department?"
            onConfirm={() =>
              getAccess(['DEPARTMENT_DELETE', 'FORBIDDEN'], () =>
                deleteBaseDepartment.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        pageSize: 10,
        current: query.page,
        total: data?.data?.total,
        onChange: (page: number, limit: number) => {
          navigate(`?page=${page}&limit=${limit}`);
        },
      }}
    />
  );
};

export default BaseDepartmentList;
