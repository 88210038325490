import ImagePreview from '@shared/components/ImagePreview';
import { IBaseDepartmentUpdate } from '@shared/interfaces';
import { CustomUpload } from '@shared/utils';
import { Button, Col, Form, Input, InputNumber, Radio, Row } from 'antd';
import { useEffect, useState } from 'react';

interface IProps {
  initialValues?: IBaseDepartmentUpdate;
  onFinish?: (values: IBaseDepartmentUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const BaseDepartmentUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const [icon, setIcon] = useState<string>();

  const onSubmitForm = (values: IBaseDepartmentUpdate) => {
    values.icon = icon || initialValues.icon;
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter a title!',
              },
            ]}
          >
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Is Active"
            name="isActive"
            rules={[
              {
                required: true,
                message: 'Please chose a value!',
              },
            ]}
          >
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item label="Priority" name="priority">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter priority"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={3} placeholder="Write something" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item label="Icon" name="icon">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview src={icon || initialValues.icon} alt="icon" />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setIcon(url)}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BaseDepartmentUpdateForm;
