import { DistrictService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useDistricts hook ------------------------------------
type IUseDistricts = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof DistrictService.filter>;
};
export const useDistricts = ({ options, config }: IUseDistricts = {}) => {
  return useQuery({
    ...config,
    queryKey: [DistrictService.NAME, options],
    queryFn: () => DistrictService.filter(options),
  });
};

//----------------------- useDistrict hook --------------------------------------
type IUseDistrict = {
  id: string;
  config?: QueryConfig<typeof DistrictService.filterById>;
};

export const useDistrict = ({ id, config }: IUseDistrict) => {
  return useQuery({
    ...config,
    queryFn: () => DistrictService.filterById(id),
  });
};

//------------------ useCreateDistrict hook ---------------------------------
type IUseCreateDistrict = {
  config?: MutationConfig<typeof DistrictService.create>;
};

export const useCreateDistrict = ({ config }: IUseCreateDistrict = {}) => {
  return useMutation({
    ...config,
    mutationFn: DistrictService.create,
  });
};

//------------------ useUpdateDistrict hook ----------------------------------
type IUseUpdateDistrict = {
  config?: MutationConfig<typeof DistrictService.update>;
};

export const useUpdateDistrict = ({ config }: IUseUpdateDistrict = {}) => {
  return useMutation({
    ...config,
    mutationFn: DistrictService.update,
  });
};

//------------------ useDeleteDistrict hook ----------------------------------
type IUseDeleteDistrict = {
  config?: MutationConfig<typeof DistrictService.delete>;
};

export const useDeleteDistrict = ({ config }: IUseDeleteDistrict = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(DistrictService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: DistrictService.delete,
  });
};
