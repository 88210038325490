import {
  IBaseFilter,
  IUserRoleCreate,
  IUserRoleResponse,
  IUserRolesResponse,
  IUserRoleUpdate,
} from '@shared/interfaces';

import { CoreAxiosInstance } from '@shared/config';
import { AxiosResponse } from 'axios';
import { _ } from '@shared/utils';

const END_POINT: string = '/user-roles';

export const UserRoleService = {
  NAME: END_POINT,
  create(payload: IUserRoleCreate): Promise<AxiosResponse<IUserRoleResponse>> {
    const { userId } = payload;
    delete payload.userId;
    return CoreAxiosInstance.post(`${END_POINT}/assign/${userId}`, payload);
  },
  filter(
    userId: string,
    options?: IBaseFilter
  ): Promise<AxiosResponse<IUserRolesResponse>> {
    if (userId)
      return CoreAxiosInstance.get(
        `${END_POINT}/${userId}?${_.queryNormalizer(options)}`
      );

    return;
  },
  update(payload: IUserRoleUpdate): Promise<AxiosResponse<IUserRoleResponse>> {
    const { userId } = payload;
    delete payload.userId;
    return CoreAxiosInstance.post(`${END_POINT}/unassign/${userId}`, payload);
  },
};
