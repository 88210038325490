import { Authorization } from '@modules/auth';
import { useCreateDivision } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import DivisionCreateForm from '../components/DivisionCreateForm';

const DivisionCreateRoute = () => {
  const navigate = useNavigate();
  const createDivision = useCreateDivision({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ADDRESS_MANAGEMENT', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Division">
        <DivisionCreateForm
          isLoading={createDivision.isLoading}
          onFinish={(values) => createDivision.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};

export default DivisionCreateRoute;
