import { useProviders } from '@shared/hooks';
import { IProvider } from '@shared/interfaces';
import { Col, Form, Input, Row, Select } from 'antd';

interface IProps {
  initialValues: object;
  onValuesChange: (c, v) => void;
}
const PackageQueryForm: React.FC<IProps> = ({
  initialValues,
  onValuesChange,
}) => {
  const provider: IProvider[] = useProviders()?.data?.data?.payload;

  return (
    <Form
      size="large"
      layout="vertical"
      className="data-query-form"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="title" name="title">
            <Input allowClear placeholder="Search by title" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="provider" name="provider">
            <Select
              showSearch
              allowClear
              placeholder="Select a provider"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {provider?.map((pv) => (
                <Select.Option key={pv?.id} value={pv?.id}>
                  {pv?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PackageQueryForm;
