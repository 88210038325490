import { ZoneService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useZones hook ------------------------------------
type IUseZones = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof ZoneService.filter>;
};
export const useZones = ({ options, config }: IUseZones = {}) => {
  return useQuery({
    ...config,
    queryKey: [ZoneService.NAME, options],
    queryFn: () => ZoneService.filter(options),
  });
};

//----------------------- useZone hook --------------------------------------
type IUseZone = {
  id: string;
  config?: QueryConfig<typeof ZoneService.filterById>;
};

export const useZone = ({ id, config }: IUseZone) => {
  return useQuery({
    ...config,
    queryFn: () => ZoneService.filterById(id),
  });
};

//------------------ useCreateZone hook ---------------------------------
type IUseCreateZone = {
  config?: MutationConfig<typeof ZoneService.create>;
};

export const useCreateZone = ({ config }: IUseCreateZone = {}) => {
  return useMutation({
    ...config,
    mutationFn: ZoneService.create,
  });
};

//------------------ useUpdateZone hook ----------------------------------
type IUseUpdateZone = {
  config?: MutationConfig<typeof ZoneService.update>;
};

export const useUpdateZone = ({ config }: IUseUpdateZone = {}) => {
  return useMutation({
    ...config,
    mutationFn: ZoneService.update,
  });
};

//------------------ useDeleteZone hook ----------------------------------
type IUseDeleteZone = {
  config?: MutationConfig<typeof ZoneService.delete>;
};

export const useDeleteZone = ({ config }: IUseDeleteZone = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(ZoneService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: ZoneService.delete,
  });
};
