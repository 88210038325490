import { useDeleteDepartment } from '@shared/hooks';
import {
  Button,
  PaginationProps,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@shared/constant';
import { IDepartment } from '@shared/interfaces';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import ImagePreview from '@shared/components/ImagePreview';
import { getAccess } from '@modules/auth';

interface IProps {
  isLoading: boolean;
  departments: IDepartment[];
  pagination: PaginationProps;
}
const DepartmentList: React.FC<IProps> = ({
  isLoading,
  departments,
  pagination,
}) => {
  const navigate = useNavigate();

  const deleteDepartment = useDeleteDepartment();

  const dataSource = departments?.map((x: IDepartment) => ({
    key: x.id,
    id: x.id,
    title: x.title,
    uiType: x.uiType,
    baseDepartment: x.serviceType?.title,
    description: x.description,
    isActive: x.isActive?.toString(),
    isFeatured: x.isFeatured?.toString(),
    priority: x.priority,
    icon: x.icon,
    image: x.image,
    backgroundImage: x.backgroundImage,
  }));

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Featured',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Ui Type',
      dataIndex: 'uiType',
      key: 'uiType',
    },
    {
      title: 'Base Department',
      dataIndex: 'baseDepartment',
      key: 'baseDepartment',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon: string) => <ImagePreview src={icon} alt="icon" />,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="thumbImage" />,
    },
    {
      title: 'Background',
      dataIndex: 'backgroundImage',
      key: 'backgroundImage',
      render: (backgroundImage: string) => (
        <ImagePreview src={backgroundImage} alt="BgImage" />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.DepartmentUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it?"
            onConfirm={() =>
              getAccess(['DEPARTMENT_DELETE', 'FORBIDDEN'], () =>
                deleteDepartment.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
    />
  );
};

export default DepartmentList;
