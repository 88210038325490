import { useBaseDepartments } from '@shared/hooks';
import { IDepartment } from '@shared/interfaces';
import { Col, Form, Input, Row, Select } from 'antd';

interface IProps {
  initialValues: object;
  onValuesChange: (c, v) => void;
}
const DepartmentQueryForm: React.FC<IProps> = ({
  initialValues,
  onValuesChange,
}) => {
  const baseDeppartens: IDepartment[] =
    useBaseDepartments()?.data?.data?.payload;

  return (
    <Form
      size="large"
      layout="vertical"
      className="data-query-form"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Form.Item label="title" name="title">
            <Input allowClear placeholder="Search by name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Form.Item label="uiType" name="uiType">
            <Select allowClear placeholder="Select a uiType">
              <Select.Option value="SERVICE_LIST">SERVICE_LIST</Select.Option>
              <Select.Option value="CONTACT_US">CONTACT_US</Select.Option>
              <Select.Option value="SUBSCRIPTION">SUBSCRIPTION</Select.Option>
              <Select.Option value="CALL_REQUEST">SUBSCRIPTION</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Form.Item label="Base Department" name="serviceType">
            <Select allowClear placeholder="Select a serviceType">
              {baseDeppartens?.map((dep) => (
                <Select.Option key={dep?.id} value={dep?.id}>
                  {dep?.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DepartmentQueryForm;
