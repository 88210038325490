import { usePackages, useProducts, useSlots, useUsers } from '@shared/hooks';
import {
  IOrderUpdate,
  IPackage,
  IProduct,
  ISlot,
  IUser,
} from '@shared/interfaces';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';

interface IProps {
  initialValues?: IOrderUpdate;
  onFinish?: (values: IOrderUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const OrderUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  //necessary data fetching
  const users: IUser[] = useUsers().data?.data?.payload;
  const products: IProduct[] = useProducts().data?.data?.payload;
  const packages: IPackage[] = usePackages().data?.data?.payload;
  const slots: ISlot[] = useSlots().data?.data?.payload;

  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const [deliveryDate, setDeliveryDate] = useState<string>();
  const onDateChange = (date: any, value: string) => {
    setDeliveryDate(value);
  };
  console.log(deliveryDate);

  const onSubmitForm = (values: IOrderUpdate) => {
    // values.deliveryDate = deliveryDate;
    console.log(values);
    // onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="User"
            name="user"
            rules={[
              {
                required: true,
                message: 'Please select a user!',
              },
            ]}
          >
            <Select
              placeholder="Select  a user"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users?.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.firstName + ' ' + user.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Products" name="products">
            <Select
              placeholder="Select a product"
              showSearch
              allowClear
              mode="multiple"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {products?.map((pd) => (
                <Select.Option key={pd.id} value={pd.id}>
                  {pd.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Packages" name="packages">
            <Select
              placeholder="Select a Package"
              showSearch
              allowClear
              mode="multiple"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {packages?.map((pk) => (
                <Select.Option key={pk.id} value={pk.id}>
                  {pk.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Provider" name="provider">
            <Select
              placeholder="Select  a provider"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* {providers?.map((pv) => (
                <Option key={pv.id} value={pv.id}>
                  {pv.title}
                </Option>
              ))} */}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Family" name="family">
            <Select
              placeholder="Select  a family"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* {providers?.map((pv) => (
                <Option key={pv.id} value={pv.id}>
                  {pv.title}
                </Option>
              ))} */}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="DeliveryDate"
            name="deliveryDate"
            rules={[
              {
                required: true,
                message: 'Please enter deliveryDate',
              },
            ]}
          >
            <DatePicker
              showTime
              style={{ width: '100%' }}
              onChange={onDateChange}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Slot"
            name="slot"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please select a slot!',
            //   },
            // ]}
          >
            <Select
              placeholder="Select  a slot"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {slots?.map((slot) => (
                <Select.Option key={slot.id} value={slot.id}>
                  {slot.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Address" name="address">
            <Select
              placeholder="Select  a address"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* {address?.map((ad) => (
                <Option key={ad.id} value={ad.id}>
                  {ad.title}
                </Option>
              ))} */}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="instructions"
            name="instructions"
            rules={[
              {
                required: true,
                message: 'Please enter instructions!',
              },
            ]}
          >
            <Input.TextArea placeholder="Enter instructions" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderUpdateForm;
