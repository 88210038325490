import { ProviderDepartmentService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useProviderDepartments hook ------------------------------------
type IUseProviderDepartments = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof ProviderDepartmentService.filter>;
};
export const useProviderDepartments = ({
  options,
  config,
}: IUseProviderDepartments = {}) => {
  return useQuery({
    ...config,
    queryKey: [ProviderDepartmentService.NAME, options],
    queryFn: () => ProviderDepartmentService.filter(options),
  });
};

//----------------------- useProviderDepartment hook --------------------------------------
type IUseProviderDepartment = {
  id: string;
  config?: QueryConfig<typeof ProviderDepartmentService.filterById>;
};

export const useProviderDepartment = ({
  id,
  config,
}: IUseProviderDepartment) => {
  return useQuery({
    ...config,
    queryFn: () => ProviderDepartmentService.filterById(id),
  });
};

//------------------ useCreateProviderDepartment hook ---------------------------------
type IUseCreateProviderDepartment = {
  config?: MutationConfig<typeof ProviderDepartmentService.create>;
};

export const useCreateProviderDepartment = ({
  config,
}: IUseCreateProviderDepartment = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderDepartmentService.create,
  });
};

//------------------ useUpdateProviderDepartment hook ----------------------------------
type IUseUpdateProviderDepartment = {
  config?: MutationConfig<typeof ProviderDepartmentService.update>;
};

export const useUpdateProviderDepartment = ({
  config,
}: IUseUpdateProviderDepartment = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderDepartmentService.update,
  });
};

//------------------ useDeleteProviderDepartment hook ----------------------------------
type IUseDeleteProviderDepartment = {
  config?: MutationConfig<typeof ProviderDepartmentService.delete>;
};

export const useDeleteProviderDepartment = ({
  config,
}: IUseDeleteProviderDepartment = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(ProviderDepartmentService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: ProviderDepartmentService.delete,
  });
};
