import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import UserRoleListRoute from './routes/UserRoleListRoute';

export const UserRoleRoutes = [
  {
    path: Paths.UserRole,
    element: <Navigate to={Paths.UserRoleList} />,
  },
  { path: Paths.UserRoleList, element: <UserRoleListRoute /> },
  //   { path: Paths.RoleCreate, element: <RoleCreateRoute /> },
  //   { path: Paths.RoleUpdate(), element: <RoleUpdateRoute /> },
];
