import {
  IDepartmentCreate,
  IDepartmentFilter,
  IDepartmentResponse,
  IDepartmentsResponse,
  IDepartmentUpdate,
} from '@shared/interfaces';

import { CoreAxiosInstance } from '@shared/config';
import { _ } from '@shared/utils';
import { AxiosResponse } from 'axios';

const END_POINT: string = '/departments';

export const DepartmentService = {
  NAME: END_POINT,
  create(
    payload: IDepartmentCreate
  ): Promise<AxiosResponse<IDepartmentResponse>> {
    return CoreAxiosInstance.post(END_POINT, payload);
  },
  filter(
    options: IDepartmentFilter
  ): Promise<AxiosResponse<IDepartmentsResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}?${_.queryNormalizer(options)}`);
  },
  filterById(id: string): Promise<AxiosResponse<IDepartmentResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}/${id}`);
  },
  update(
    payload: IDepartmentUpdate
  ): Promise<AxiosResponse<IDepartmentResponse>> {
    const { id } = payload;
    delete payload.id;
    return CoreAxiosInstance.patch(`${END_POINT}/${id}`, payload);
  },
  delete(id: string): Promise<AxiosResponse<IDepartmentResponse>> {
    return CoreAxiosInstance.delete(`${END_POINT}/${id}`);
  },
};
