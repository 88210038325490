import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import ProviderProductCreatePage from './routes/ProviderProductCreatePage';
import ProviderProductDetailsPage from './routes/ProviderProductDetailsPage';
import ProviderProductsPage from './routes/ProviderProductsPage';
import ProviderProductUpdatePage from './routes/ProviderProductUpdatePage';

export const ProviderProductRoutes = [
  { path: Paths.ProviderProductList, element: <ProviderProductsPage /> },
  { path: Paths.ProviderProductCreate, element: <ProviderProductCreatePage /> },
  {
    path: Paths.ProviderProductUpdate(),
    element: <ProviderProductUpdatePage />,
  },
  {
    path: Paths.ProviderProductDetails(),
    element: <ProviderProductDetailsPage />,
  },
  {
    path: Paths.ProviderProduct,
    element: <Navigate to={Paths.ProviderProduct} />,
  },
];
