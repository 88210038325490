import { getAccess } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useDeleteProviderPackage } from '@shared/hooks';
import { IProviderPackage } from '@shared/interfaces';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AiFillDelete, AiFillEdit, AiOutlineBars } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

interface IProps {
  isLoading: boolean;
  providerPackages: IProviderPackage[];
  pagination: PaginationProps;
}
const ProviderPackageList: React.FC<IProps> = ({
  isLoading,
  providerPackages,
  pagination,
}) => {
  const navigate = useNavigate();
  const deleteProviderPackage = useDeleteProviderPackage();

  const dataSource = providerPackages?.map((x: IProviderPackage) => ({
    key: x.id,
    id: x.id,
    title: x.title,
    priority: x.priority,
    isActive: x.isActive.toString(),
    isAvailable: x.isAvailable.toString(),
    isHomeSampleAvailable: x.isHomeSampleAvailable.toString(),
    branch: x.branch,
    sampleType: x.sampleType,
    estimatedReportDeliveryTime: x.estimatedReportDeliveryTime,
    homeCollectionCharge: x.homeCollectionCharge,
    regularPrice: x.regularPrice,
    hbDiscountPrice: x.hbDiscountPrice,
    hbPatientPrice: x.hbPatientPrice,
    hbCommission: x.hbCommission,
    provider: x.provider?.name,
    // package: x.package?.title,
    // providerBranch: x.providerBranch?.title,
    // department: x.department?.title,
  }));

  const columns: ColumnsType = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'IsActive',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'HomeCharge',
      dataIndex: 'homeCollectionCharge',
      key: 'homeCollectionCharge',
    },
    {
      title: 'RegularPrice',
      dataIndex: 'regularPrice',
      key: 'regularPrice',
    },
    {
      title: 'DiscountPrice',
      dataIndex: 'hbDiscountPrice',
      key: 'hbDiscountPrice',
    },
    {
      title: 'PatientPrice',
      dataIndex: 'hbPatientPrice',
      key: 'hbPatientPrice',
    },
    {
      title: 'Commission',
      dataIndex: 'hbCommission',
      key: 'hbCommission',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.ProviderPackageUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it?"
            onConfirm={() =>
              getAccess(['PROVIDER_PACKAGE_DELETE', 'FORBIDDEN'], () =>
                deleteProviderPackage.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.ProviderPackageDetails(id))}
          >
            <AiOutlineBars />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
    />
  );
};
export default ProviderPackageList;
