import DefaultDashboardPage from '@modules/dashboard/routes/DefaultDashboard';
import AppLayOut from '@shared/components/layout/AppLayout';
import NotFound from '@shared/components/NotFound';
import { DashboardRoutes } from '@modules/dashboard';
import { Outlet, useRoutes } from 'react-router-dom';
import {
  BaseDepartmentRoutes,
  DepartmentImageRoutes,
  DepartmentRoutes,
} from '@modules/department';
import { CategoryRoutes, SubCategoryRoutes } from '@modules/category';
import { Paths } from '@shared/constant';
import { PackageRoutes } from '@modules/package';
import { UserRoutes } from '@modules/user';
import {
  ProviderDiscountRoutes,
  ProviderPackageRoutes,
  ProviderProductRoutes,
  ProviderRoutes,
} from '@modules/provider';
import { ProductRoutes } from '@modules/product';
import { AddressRoutes } from '@modules/address';
import {
  OrderRoutes,
  OrderRequestRoutes,
  MedicineOrdersRoutes,
  CallRequestsRoutes,
  DiagnosticPrescriptionsRoutes,
} from '@modules/order';
import {
  BannerRoutes,
  FeaturedItemRoutes,
  OfferRoutes,
} from '@modules/featured';
import { SlotRoutes } from '@modules/address/slot';
import { SubscriptionRoutes } from '@modules/subscription';
import {
  PermissionRoutes,
  RoleRoutes,
  UserRoleRoutes,
} from '@modules/authorization';
import { ProviderDepartmentRoutes } from '@modules/provider/provider-department';

const App = () => {
  let pathName = window.location.pathname;
  return (
    <AppLayOut>
      {pathName === '/' ? <DefaultDashboardPage /> : ''}
      <Outlet />
    </AppLayOut>
  );
};

const ProtectedRoutes = () => {
  const routes = [
    {
      path: Paths.dashboard,
      children: DashboardRoutes,
    },
    {
      path: Paths.Role,
      children: RoleRoutes,
    },
    {
      path: Paths.UserRole,
      children: UserRoleRoutes,
    },
    {
      path: Paths.Permission,
      children: PermissionRoutes,
    },
    {
      path: Paths.users,
      children: UserRoutes,
    },
    {
      path: Paths.subscription,
      children: SubscriptionRoutes,
    },
    {
      path: Paths.BaseDepartment,
      children: BaseDepartmentRoutes,
    },
    {
      path: Paths.Department,
      children: DepartmentRoutes,
    },
    {
      path: Paths.DepartmentImage,
      children: DepartmentImageRoutes,
    },
    {
      path: Paths.Categorie,
      children: CategoryRoutes,
    },
    {
      path: Paths.SubCategory,
      children: SubCategoryRoutes,
    },
    {
      path: Paths.Package,
      children: PackageRoutes,
    },
    {
      path: Paths.Product,
      children: ProductRoutes,
    },
    {
      path: Paths.Provider,
      children: ProviderRoutes,
    },
    {
      path: Paths.ProviderProduct,
      children: ProviderProductRoutes,
    },
    {
      path: Paths.ProviderPackage,
      children: ProviderPackageRoutes,
    },
    {
      path: Paths.ProviderDiscount,
      children: ProviderDiscountRoutes,
    },
    {
      path: Paths.ProviderDepartment,
      children: ProviderDepartmentRoutes,
    },
    {
      path: Paths.address,
      children: AddressRoutes,
    },
    {
      path: Paths.Order,
      children: OrderRoutes,
    },
    {
      path: Paths.Banner,
      children: BannerRoutes,
    },
    {
      path: Paths.orderRequests,
      children: OrderRequestRoutes,
    },
    {
      path: Paths.slots,
      children: SlotRoutes,
    },
    {
      path: Paths.FeaturedItem,
      children: FeaturedItemRoutes,
    },
    {
      path: Paths.Offer,
      children: OfferRoutes,
    },
    {
      path: Paths.MedicineOrder,
      children: MedicineOrdersRoutes,
    },
    {
      path: Paths.DiagnosticPrescription,
      children: DiagnosticPrescriptionsRoutes,
    },

    {
      path: Paths.CallRequest,
      children: CallRequestsRoutes,
    },
  ];

  return useRoutes([
    {
      path: '',
      element: <App />,
      children: routes,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);
};

export default ProtectedRoutes;
