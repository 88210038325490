import { IPackageProviderPackagesResponse } from './../interfaces/_package.interfaces';
import {
  IBaseFilter,
  IPackageCreate,
  IPackageResponse,
  IPackagesResponse,
  IPackageUpdate,
} from '@shared/interfaces';

import { CoreAxiosInstance } from '@shared/config';
import { _ } from '@shared/utils';
import { AxiosResponse } from 'axios';

const END_POINT: string = '/packages';

export const PackageService = {
  NAME: END_POINT,
  create(payload: IPackageCreate): Promise<AxiosResponse<IPackageResponse>> {
    return CoreAxiosInstance.post(END_POINT, payload);
  },
  filter(options: IBaseFilter): Promise<AxiosResponse<IPackagesResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}?${_.queryNormalizer(options)}`);
  },
  filterById(id: string): Promise<AxiosResponse<IPackageResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}/${id}`);
  },
  update(payload: IPackageUpdate): Promise<AxiosResponse<IPackageResponse>> {
    const { id } = payload;
    delete payload.id;
    return CoreAxiosInstance.patch(`${END_POINT}/${id}`, payload);
  },
  delete(id: string): Promise<AxiosResponse<IPackageResponse>> {
    return CoreAxiosInstance.delete(`${END_POINT}/${id}`);
  },
  getProviderPackages(
    id: string,
    options: IBaseFilter
  ): Promise<AxiosResponse<IPackageProviderPackagesResponse>> {
    if (!id) return;
    return CoreAxiosInstance.get(
      `${END_POINT}/${id}/provider-packages?${_.queryNormalizer(options)}`
    );
  },
};
