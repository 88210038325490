import { Paths } from '@shared/constant';
import { useDeleteSubscription } from '@shared/hooks';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { _ } from '@shared/utils';
import { getAccess } from '@modules/auth';
import ImagePreview from '@shared/components/ImagePreview';
import { ISubscription } from '@shared/interfaces';

interface IProps {
  isLoading: boolean;
  data: ISubscription[];
  pagination: PaginationProps;
}
const SubscriptionList: React.FC<IProps> = ({
  isLoading,
  data,
  pagination,
}) => {
  const navigate = useNavigate();

  const deleteSubscription = useDeleteSubscription();

  const dataSource = data?.map((x) => ({
    key: x?.id,
    id: x?.id,
    title: x?.title || x?.providerPackage?.title,
    image: x?.image,
    amount: x?.amount,
    duration: x?.duration,
    issueDate: _.getStutteredDate(x?.issueDate),
    expiryDate: _.getStutteredDate(x?.expiryDate),
    subscriptionNo: x?.subscriptionNo,
    family: `${x?.family?.firstName} ${x?.family?.lastName} - ${x?.family?.phoneNumber}`,
  }));

  const columns = [
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="TImage" />,
    },
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'issueDate',
      dataIndex: 'issueDate',
      key: 'issueDate',
    },
    {
      title: 'expiryDate',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
    },
    {
      title: 'subscriptionNo',
      dataIndex: 'subscriptionNo',
      key: 'subscriptionNo',
    },
    {
      title: 'family',
      dataIndex: 'family',
      key: 'family',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.subscriptionUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it"
            onConfirm={() =>
              getAccess(['SUBSCRIPTION_DELETE', 'FORBIDDEN'], () =>
                deleteSubscription.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={pagination}
      />
    </>
  );
};
export default SubscriptionList;
