import { Authorization } from '@modules/auth';
import { useMedicineOrders } from '@shared/hooks';
import { _ } from '@shared/utils';
import { PageHeader } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MedicineOrderList from '../components/MedicineOrderList';
import MedicineOrderQueryForm from '../components/MedicineOrderQueryForm';

const MedicineOrdersRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState({
    user: searchParams.get('user'),
    status: searchParams.get('status'),
  });

  const { data, isLoading } = useMedicineOrders({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      s: _.toCleanObject({
        'user.id': query?.user,
        status: query?.status,
      }),
      sort: ['createdAt,DESC'],
    },
  });
  console.log(data);
  return (
    <Authorization allowedAccess={['ORDER_VIEW', 'FORBIDDEN']}>
      <PageHeader title="Prescription List">
        <MedicineOrderQueryForm
          initialValues={_.toCleanObject({ ...query })}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        />
        <MedicineOrderList
          isLoading={isLoading}
          data={data?.data?.payload}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};

export default MedicineOrdersRoute;
