import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import ProviderPackageCreatePage from './routes/ProviderPackageCreatePage';
import ProviderPackageDetailsPage from './routes/ProviderPackageDetailsPage';
import ProviderPackagesPage from './routes/ProviderPackagesPage';
import ProviderPackageUpdatePage from './routes/ProviderPackageUpdatePage';

export const ProviderPackageRoutes = [
  { path: Paths.ProviderPackageList, element: <ProviderPackagesPage /> },
  { path: Paths.ProviderPackageCreate, element: <ProviderPackageCreatePage /> },
  {
    path: Paths.ProviderPackageUpdate(),
    element: <ProviderPackageUpdatePage />,
  },
  {
    path: Paths.ProviderPackageDetails(),
    element: <ProviderPackageDetailsPage />,
  },
  {
    path: Paths.ProviderPackage,
    element: <Navigate to={Paths.ProviderPackageList} />,
  },
];
