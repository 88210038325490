import { Authorization } from '@modules/auth';
import { useOrderRequest, useUpdateOrderRequest } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import OrderRequestUpdateForm from '../components/OrderRequestUpdateForm';

const OrderRequestUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useOrderRequest({ id });

  const updateOrderRequest = useUpdateOrderRequest({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ORDER_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader
          onBack={() => navigate(-1)}
          title="Update Emergency Requests"
        >
          <OrderRequestUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateOrderRequest.isLoading}
            onFinish={(values) =>
              updateOrderRequest.mutateAsync({ ...values, id })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default OrderRequestUpdatePage;
