import { Authorization } from '@modules/auth';
import { useCreateCategory } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import CategoryCreateForm from '../components/CategoryCreateForm';

const CategoryCreateRoute = () => {
  const navigate = useNavigate();

  const createCategory = useCreateCategory({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['CATEGORY_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Category">
        <CategoryCreateForm
          isLoading={createCategory.isLoading}
          onFinish={(values) => createCategory.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default CategoryCreateRoute;
