import { useProviderPackages, useProviders } from '@shared/hooks';
import { IProvider, IProviderPackage } from '@shared/interfaces';
import { Col, Form, Row, Select } from 'antd';

interface IProps {
  initialValues: object;
  onValuesChange: (c, v) => void;
}
const FeaturedItemQueryForm: React.FC<IProps> = ({
  initialValues,
  onValuesChange,
}) => {
  const providers: IProvider[] = useProviders()?.data?.data?.payload;
  const packages: IProviderPackage[] =
    useProviderPackages()?.data?.data?.payload;

  return (
    <Form
      size="large"
      layout="vertical"
      className="data-query-form"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Form.Item label="Provider" name="provider">
            <Select
              allowClear
              showSearch
              placeholder="Select a provider"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {providers?.map((pv) => (
                <Select.Option key={pv?.id} value={pv?.id}>
                  {pv?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Form.Item label="providerPackage" name="providerPackage">
            <Select
              allowClear
              showSearch
              placeholder="Select a providerPackage"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {packages?.map((pkg) => (
                <Select.Option key={pkg?.id} value={pkg?.id}>
                  {pkg?.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FeaturedItemQueryForm;
