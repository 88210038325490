import {
  IBaseFilter,
  ISubscriptionCreate,
  ISubscriptionResponse,
  ISubscriptionsResponse,
  ISubscriptionUpdate,
} from '@shared/interfaces';

import { CoreAxiosInstance } from '@shared/config';
import { AxiosResponse } from 'axios';
import { _ } from '@shared/utils';

const END_POINT: string = '/subscriptions';

export const SubscriptionService = {
  NAME: END_POINT,
  create(
    payload: ISubscriptionCreate
  ): Promise<AxiosResponse<ISubscriptionResponse>> {
    return CoreAxiosInstance.post(END_POINT, payload);
  },
  filter(options: IBaseFilter): Promise<AxiosResponse<ISubscriptionsResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}?${_.queryNormalizer(options)}`);
  },
  filterById(id: string): Promise<AxiosResponse<ISubscriptionResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}/${id}`);
  },
  update(
    payload: ISubscriptionUpdate
  ): Promise<AxiosResponse<ISubscriptionResponse>> {
    const { id } = payload;
    delete payload.id;
    return CoreAxiosInstance.patch(`${END_POINT}/${id}`, payload);
  },
  delete(id: string): Promise<AxiosResponse<ISubscriptionResponse>> {
    return CoreAxiosInstance.delete(`${END_POINT}/${id}`);
  },
};
