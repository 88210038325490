import { IMAGES } from '@assets/images';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { IOrder } from '@shared/interfaces';

const OrderInvoice = ({ order }: { order: IOrder }) => {
  console.log(order);

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '2px solid #8e8c8d',
      padding: '50px 30px',
    },
    logo: {
      width: 150,
    },
    content: {
      padding: '20px 30px 50px',
    },
    billToBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      border: '1px solid #acacac',
      marginBottom: 20,
    },
    billTo: {
      padding: 20,
    },
    tableHead: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 10,
      backgroundColor: '#2e63d6',
      color: '#ffffff',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 10,
      color: '#6c6c6c',
      borderBottom: '1px solid #acacac',
    },
    priceSummary: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 50,
    },
    priceArea: {
      width: 300,
    },
    priceRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: '#6c6c6c',
      fontSize: 16,
      padding: 10,
    },
    priceTotal: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#2e63d6',
      color: '#ffffff',
      fontSize: 16,
      padding: 10,
    },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Image style={styles.logo} src={IMAGES?.Logo} />
            {/* <Text style={{ fontSize: 12 }}>
              Creating a digital health care eco system
            </Text>
            <Text style={{ fontSize: 12 }}></Text> */}
          </View>
          <View>
            <Text style={{ fontSize: 30, fontWeight: 800, color: '#2e63d6' }}>
              INVOICE
            </Text>
          </View>
        </View>
        <View style={styles.content}>
          <View style={styles.billToBox}>
            <View style={styles.billTo}>
              <Text style={{ fontSize: 10, color: '#6c6c6c' }}>Bill To: </Text>
              <Text style={{ fontSize: 16, fontWeight: 600 }}>
                {order?.user?.firstName + ' ' + order?.user?.lastName}
              </Text>
              <Text style={{ fontSize: 10, color: '#6c6c6c' }}>
                {order?.address?.title}
              </Text>
            </View>
            <View style={styles.billTo}>
              <Text>Area</Text>
            </View>
            <View style={styles.billTo}>
              <Text>Area</Text>
            </View>
          </View>

          <View style={styles.tableHead}>
            <Text>Item description</Text>
            <Text>Total</Text>
          </View>
          {order?.orderItems?.map((item) => (
            <View key={item.id} style={styles.tableRow}>
              <Text>{item?.providerPackage?.title}</Text>
              <Text>{item?.total}</Text>
            </View>
          ))}

          <View style={styles.priceSummary}>
            <View style={styles.priceArea}>
              <View style={styles.priceRow}>
                <Text>Sub Total</Text>
                <Text>{order?.subTotal}</Text>
              </View>
              <View style={styles.priceTotal}>
                <Text>Total</Text>
                <Text>{order?.total}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default OrderInvoice;
