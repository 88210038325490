import {
  IBaseFilter,
  IRoleCreate,
  IRoleResponse,
  IRolesResponse,
  IRoleUpdate,
} from '@shared/interfaces';

import { CoreAxiosInstance } from '@shared/config';
import { AxiosResponse } from 'axios';
import { _ } from '@shared/utils';

const END_POINT: string = '/roles';

export const RoleService = {
  NAME: END_POINT,
  create(payload: IRoleCreate): Promise<AxiosResponse<IRoleResponse>> {
    return CoreAxiosInstance.post(END_POINT, payload);
  },
  filter(options: IBaseFilter): Promise<AxiosResponse<IRolesResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}?${_.queryNormalizer(options)}`);
  },
  filterById(id: string): Promise<AxiosResponse<IRoleResponse>> {
    return CoreAxiosInstance.get(`${END_POINT}/${id}`);
  },
  update(payload: IRoleUpdate): Promise<AxiosResponse<IRoleResponse>> {
    const { id } = payload;
    delete payload.id;
    return CoreAxiosInstance.patch(`${END_POINT}/${id}`, payload);
  },
  delete(id: string): Promise<AxiosResponse<IRoleResponse>> {
    return CoreAxiosInstance.delete(`${END_POINT}/${id}`);
  },
};
