import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import OrderCreateRoute from './routes/OrderCreateRoute';
import OrderDetailsRoute from './routes/OrderDetailsRoute';
import OrderInvoiceRoute from './routes/OrderInvoiceRoute';
import OrderListRoute from './routes/OrderListRoute';
import OrderUpdateRoute from './routes/OrderUpdateRoute';

export const OrderRoutes = [
  { path: Paths.OrderList, element: <OrderListRoute /> },
  { path: Paths.OrderCreate, element: <OrderCreateRoute /> },
  { path: Paths.OrderUpdate(), element: <OrderUpdateRoute /> },
  { path: Paths.OrderInvoice(), element: <OrderInvoiceRoute /> },
  { path: Paths.OrderDetails(), element: <OrderDetailsRoute /> },
  { path: Paths.Order, element: <Navigate to={Paths.OrderList} /> },
];
