import { Authorization } from '@modules/auth';
import { useOrder, useUpdateOrder } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import OrderUpdateForm from '../components/OrderUpdateForm';

const OrderUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useOrder({ id });

  const updateOrder = useUpdateOrder({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: res?.data?.message || 'Order Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ORDER_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update Order">
          <OrderUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateOrder.isLoading}
            onFinish={(values) => updateOrder.mutateAsync({ ...values, id })}
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default OrderUpdateRoute;
