import { SubcategoryService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useSubcategories hook ------------------------------------
type IUseSubcategories = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof SubcategoryService.filter>;
};
export const useSubcategories = ({
  options,
  config,
}: IUseSubcategories = {}) => {
  return useQuery({
    ...config,
    queryKey: [SubcategoryService.NAME, options],
    queryFn: () => SubcategoryService.filter(options),
  });
};

//----------------------- useSubcategory hook --------------------------------------
type IUseSubcategory = {
  id: string;
  config?: QueryConfig<typeof SubcategoryService.filterById>;
};

export const useSubcategory = ({ id, config }: IUseSubcategory) => {
  return useQuery({
    ...config,
    queryFn: () => SubcategoryService.filterById(id),
  });
};

//------------------ useCreateSubcategory hook ---------------------------------
type IUseCreateSubcategory = {
  config?: MutationConfig<typeof SubcategoryService.create>;
};

export const useCreateSubcategory = ({
  config,
}: IUseCreateSubcategory = {}) => {
  return useMutation({
    ...config,
    mutationFn: SubcategoryService.create,
  });
};

//------------------ useUpdateSubcategory hook ----------------------------------
type IUseUpdateSubcategory = {
  config?: MutationConfig<typeof SubcategoryService.update>;
};

export const useUpdateSubcategory = ({
  config,
}: IUseUpdateSubcategory = {}) => {
  return useMutation({
    ...config,
    mutationFn: SubcategoryService.update,
  });
};

//------------------ useDeleteSubcategory hook ----------------------------------
type IUseDeleteSubcategory = {
  config?: MutationConfig<typeof SubcategoryService.delete>;
};

export const useDeleteSubcategory = ({
  config,
}: IUseDeleteSubcategory = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(SubcategoryService.NAME);
        notification.success({
          message: res?.data?.message || 'Subcategory successfully deleted',
        });
      }
    },
    mutationFn: SubcategoryService.delete,
  });
};
