import { Authorization } from '@modules/auth';
import { useProduct, useUpdateProduct } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ProductUpdateForm from '../components/ProductUpdateForm';

const ProductUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useProduct({ id });

  const updateProduct = useUpdateProduct({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PACKAGE_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading} empty={false}>
        <PageHeader onBack={() => navigate(-1)} title="Update Product">
          <ProductUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateProduct.isLoading}
            onFinish={(values) => updateProduct.mutateAsync({ ...values, id })}
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default ProductUpdatePage;
