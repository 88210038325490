import { Authorization } from '@modules/auth';
import { useSubscription, useUpdateSubscription } from '@shared/hooks';
import { ISubscriptionUpdate } from '@shared/interfaces';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import moment from 'moment';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SubscriptionUpdateForm from '../components/SubscriptionUpdateForm';

const SubscriptionUpdateRoute = () => {
  const navigate = useNavigate();

  const { id }: any = useParams();
  const { data, isLoading } = useSubscription({ id });

  const initialValues: ISubscriptionUpdate = {
    title: data?.data?.payload?.title,
    image: data?.data?.payload?.image,
    amount: data?.data?.payload?.amount,
    duration: data?.data?.payload?.duration,
    issueDate: moment(data?.data?.payload?.issueDate),
    expiryDate: moment(data?.data?.payload?.expiryDate),
    providerPackage: data?.data?.payload?.providerPackage?.id,
    family: data?.data?.payload?.family?.id,
  };

  const updateSubscription = useUpdateSubscription({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });
  return (
    <Authorization allowedAccess={['SUBSCRIPTION_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader title="Update Subscription" onBack={() => navigate(-1)}>
          <SubscriptionUpdateForm
            initialValues={initialValues}
            isLoading={updateSubscription.isLoading}
            onFinish={(values) =>
              updateSubscription.mutateAsync({ id, ...values })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default SubscriptionUpdateRoute;
