import ImagePreview from '@shared/components/ImagePreview';
import { useBaseDepartments } from '@shared/hooks';
import { IBaseDepartment, IDepartmentUpdate } from '@shared/interfaces';
import { CustomUpload } from '@shared/utils';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlus } from 'react-icons/ai';
const { Option } = Select;

interface IProps {
  initialValues?: IDepartmentUpdate;
  onFinish?: (values: IDepartmentUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const DepartmentUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const baseDepartments: IBaseDepartment[] =
    useBaseDepartments().data?.data?.payload;

  const [images, setImages] = useState({
    icon: '',
    image: '',
    backgroundImage: '',
  });

  const onSubmitForm = (values: IDepartmentUpdate) => {
    values.icon = images.icon || initialValues.icon;
    values.image = images.image || initialValues.image;
    values.backgroundImage =
      images.backgroundImage || initialValues.backgroundImage;
    values.howItWorks = JSON.stringify(values.howItWorks);
    values.whyBookWithUs = JSON.stringify(values.whyBookWithUs);
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter a title!',
              },
            ]}
          >
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Featured Title" name="featuredTitle">
            <Input placeholder="Featured Title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Base Department"
            name="serviceType"
            rules={[
              {
                required: true,
                message: 'Please Select Base Department!',
              },
            ]}
          >
            <Select
              placeholder="Select  Base Department"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {baseDepartments?.map((bd) => (
                <Option key={bd.id} value={bd.id}>
                  {bd.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Ui type" name="uiType">
            <Select placeholder="Select  Ui type">
              <Option value="SERVICE_LIST">SERVICE_LIST</Option>
              <Option value="CONTACT_US">CONTACT_US</Option>
              <Option value="SUBSCRIPTION">SUBSCRIPTION</Option>
              <Option value="REQUEST_CALL">REQUEST_CALL</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Is Featured" name="isFeatured">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item label="Priority" name="priority">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter priority"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={16} xl={16}>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={1} placeholder="Write something" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.List name="whyBookWithUs">
            {(fields, { add, remove }) => (
              <div className="mt-4">
                {fields.map(({ key, name }) => (
                  <div key={key} className="flex gap-2 items-baseline">
                    <Form.Item name={name} className="w-full">
                      <Input placeholder="Why Book With Us" />
                    </Form.Item>
                    <AiOutlineMinusCircle
                      className="text-xl cursor-pointer"
                      onClick={() => remove(name)}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    block
                    type="dashed"
                    onClick={() => add()}
                    icon={<AiOutlinePlus className="inline mr-1 mb-1" />}
                  >
                    Add Why Book With Us
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.List name="howItWorks">
            {(fields, { add, remove }) => (
              <div className="mt-4">
                {fields.map(({ key, name }) => (
                  <div key={key} className="flex gap-2 items-baseline">
                    <Form.Item name={name} className="w-full">
                      <Input placeholder="How It Works" />
                    </Form.Item>
                    <AiOutlineMinusCircle
                      className="text-xl cursor-pointer"
                      onClick={() => remove(name)}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    block
                    type="dashed"
                    onClick={() => add()}
                    icon={<AiOutlinePlus className="inline mr-1 mb-1" />}
                  >
                    Add How It Works
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Icon" name="icon">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.icon || initialValues.icon}
                  alt="icon"
                />
              </div>
              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImages({ ...images, icon: url })}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.image || initialValues.image}
                  alt="thumb"
                />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImages({ ...images, image: url })}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Background Image" name="backgroundImage">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview
                  src={images.backgroundImage || initialValues.backgroundImage}
                  alt="backgroundImage"
                />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) =>
                  setImages({ ...images, backgroundImage: url })
                }
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DepartmentUpdateForm;
