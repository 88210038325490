import { Authorization } from '@modules/auth';
import { useCreateProduct } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import ProductCreateForm from '../components/ProductCreateForm';

const ProductCreatePage = () => {
  const navigate = useNavigate();

  const createProduct = useCreateProduct({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PRODUCT_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Product">
        <ProductCreateForm
          isLoading={createProduct.isLoading}
          onFinish={(values) => createProduct.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default ProductCreatePage;
