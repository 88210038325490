import { BaseDepartmentService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useBaseDepartments hook ------------------------------------
type IUseBaseDepartments = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof BaseDepartmentService.filter>;
};
export const useBaseDepartments = ({
  options,
  config,
}: IUseBaseDepartments = {}) => {
  return useQuery({
    ...config,
    queryKey: [BaseDepartmentService.NAME, options],
    queryFn: () => BaseDepartmentService.filter(options),
  });
};

//----------------------- useBaseDepartment hook --------------------------------------
type IUseBaseDepartment = {
  id: string;
  config?: QueryConfig<typeof BaseDepartmentService.filterById>;
};

export const useBaseDepartment = ({ id, config }: IUseBaseDepartment) => {
  return useQuery({
    ...config,
    queryFn: () => BaseDepartmentService.filterById(id),
  });
};

//------------------ useCreateBaseDepartment hook ---------------------------------
type IUseCreateBaseDepartment = {
  config?: MutationConfig<typeof BaseDepartmentService.create>;
};

export const useCreateBaseDepartment = ({
  config,
}: IUseCreateBaseDepartment = {}) => {
  return useMutation({
    ...config,
    mutationFn: BaseDepartmentService.create,
  });
};

//------------------ useUpdateBaseDepartment hook ----------------------------------
type IUseUpdateBaseDepartment = {
  config?: MutationConfig<typeof BaseDepartmentService.update>;
};

export const useUpdateBaseDepartment = ({
  config,
}: IUseUpdateBaseDepartment = {}) => {
  return useMutation({
    ...config,
    mutationFn: BaseDepartmentService.update,
  });
};

//------------------ useDeleteBaseDepartment hook ----------------------------------
type IUseDeleteBaseDepartment = {
  config?: MutationConfig<typeof BaseDepartmentService.delete>;
};

export const useDeleteBaseDepartment = ({
  config,
}: IUseDeleteBaseDepartment = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(BaseDepartmentService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: BaseDepartmentService.delete,
  });
};
