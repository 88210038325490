import { Authorization } from '@modules/auth';
import { useCreateSubcategory } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import SubcategoryCreateForm from '../components/SubcategoryCreateForm';

const SubcategoryCreateRoute = () => {
  const navigate = useNavigate();

  const createSubcategory = useCreateSubcategory({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['CATEGORY_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Subcategory">
        <SubcategoryCreateForm
          isLoading={createSubcategory.isLoading}
          onFinish={(values) => createSubcategory.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default SubcategoryCreateRoute;
