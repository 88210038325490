import React, { useEffect, useState } from 'react';
import {
  IFamily,
  IProviderPackage,
  ISubscriptionUpdate,
  IUser,
} from '@shared/interfaces';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { CustomUpload } from '@shared/utils';
import { useFamilies, useProviderPackages, useUsers } from '@shared/hooks';
import moment from 'moment';
import ImagePreview from '@shared/components/ImagePreview';
const { Option } = Select;

interface IProps {
  initialValues?: ISubscriptionUpdate;
  onFinish?: (values: ISubscriptionUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const SubscriptionUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const packages: IProviderPackage[] = useProviderPackages({
    options: {
      filter: ['department.uiType||$eq||SUBSCRIPTION'],
    },
  })?.data?.data?.payload;

  const users: IUser[] = useUsers().data?.data?.payload;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedUser, setSelectedUser] = useState();
  const families: IFamily[] = useFamilies({}).data?.data?.payload;

  const [image, setImage] = useState('');

  const onSubmitForm = (values: ISubscriptionUpdate) => {
    values.image = image || initialValues.image;
    values.issueDate = moment(values.issueDate).format('YYYY-MM-DD: hh:mm:ss');
    values.expiryDate = moment(values.expiryDate).format(
      'YYYY-MM-DD: hh:mm:ss'
    );
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Title" name="title">
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="duration" name="duration">
            <Input placeholder="Enter duration" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="providerPackage"
            name="providerPackage"
            rules={[
              {
                required: true,
                message: 'Please select a package!',
              },
            ]}
          >
            <Select
              placeholder="Select a package"
              showSearch
              allowClear
              onChange={(val) => setSelectedUser(val)}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {packages?.map((pk) => (
                <Option key={pk.id} value={pk.id}>
                  {pk.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item
            label="amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter amount!',
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} placeholder="Enter amount" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="issueDate"
            name="issueDate"
            rules={[
              {
                required: true,
                message: 'Please enter issue date!',
              },
            ]}
          >
            <DatePicker showTime style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="expiryDate"
            name="expiryDate"
            rules={[
              {
                required: true,
                message: 'Please enter expiry date!',
              },
            ]}
          >
            <DatePicker showTime style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="User" name="user">
            <Select
              showSearch
              allowClear
              disabled
              placeholder="Select a user"
              onChange={(val) => setSelectedUser(val)}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users?.map((user) => (
                <Option key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName} - ${user.phoneNumber}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="family"
            name="family"
            rules={[
              {
                required: true,
                message: 'Please select a family!',
              },
            ]}
          >
            <Select
              placeholder="Select a family"
              showSearch
              allowClear
              //   disabled={!selectedUser}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {families?.map((family) => (
                <Option key={family.id} value={family.id}>
                  {`${family.firstName} ${family.lastName} - ${family.relationship}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <div className="flex gap-4 items-center">
              <div className="uploaded-image-view">
                <ImagePreview src={image || initialValues.image} alt="logo" />
              </div>

              <CustomUpload
                showUploadList={false}
                onChange={(url) => setImage(url)}
              />
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default SubscriptionUpdateForm;
