import { CategoryService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useCategories hook ------------------------------------
type IUseCategories = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof CategoryService.filter>;
};
export const useCategories = ({ options, config }: IUseCategories = {}) => {
  return useQuery({
    ...config,
    queryKey: [CategoryService.NAME, options],
    queryFn: () => CategoryService.filter(options),
  });
};

//----------------------- useCategory hook --------------------------------------
type IUseCategory = {
  id: string;
  config?: QueryConfig<typeof CategoryService.filterById>;
};

export const useCategory = ({ id, config }: IUseCategory) => {
  return useQuery({
    ...config,
    queryFn: () => CategoryService.filterById(id),
  });
};

//------------------ useCreateCategory hook ---------------------------------
type IUseCreateCategory = {
  config?: MutationConfig<typeof CategoryService.create>;
};

export const useCreateCategory = ({ config }: IUseCreateCategory = {}) => {
  return useMutation({
    ...config,
    mutationFn: CategoryService.create,
  });
};

//------------------ useUpdateCategory hook ----------------------------------
type IUseUpdateCategory = {
  config?: MutationConfig<typeof CategoryService.update>;
};

export const useUpdateCategory = ({ config }: IUseUpdateCategory = {}) => {
  return useMutation({
    ...config,
    mutationFn: CategoryService.update,
  });
};

//------------------ useDeleteCategory hook ----------------------------------
type IUseDeleteCategory = {
  config?: MutationConfig<typeof CategoryService.delete>;
};

export const useDeleteCategory = ({ config }: IUseDeleteCategory = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(CategoryService.NAME);
        notification.success({
          message: res?.data?.message || 'Category successfully deleted',
        });
      }
    },
    mutationFn: CategoryService.delete,
  });
};
