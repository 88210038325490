import { useDepartments, useProviders } from '@shared/hooks';
import {
  IDepartment,
  IProvider,
  IProviderDepartment,
  IProviderDepartmentUpdate,
  IProviderPackageUpdate,
} from '@shared/interfaces';
import { Button, Col, Form, Row, Select } from 'antd';
import { useEffect } from 'react';
const { Option } = Select;

interface IProps {
  initialValues?: IProviderDepartment;
  onFinish?: (values: IProviderDepartmentUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const ProviderDepartmentUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const providers: IProvider[] = useProviders().data?.data?.payload;

  const departments: IDepartment[] = useDepartments().data?.data?.payload;
  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const onSubmitForm = (values: IProviderPackageUpdate | any) => {
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Provider"
            name="provider"
            rules={[
              {
                required: true,
                message: 'Please select a provider!',
              },
            ]}
          >
            <Select
              placeholder="Select a provider"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {providers?.map((pv) => (
                <Option key={pv.id} value={pv.id}>
                  {pv.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="department"
            name="department"
            rules={[
              {
                required: true,
                message: 'Please select a department!',
              },
            ]}
          >
            <Select
              placeholder="Select a department"
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {departments?.map((dept) => (
                <Option key={dept.id} value={dept.id}>
                  {dept.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default ProviderDepartmentUpdateForm;
