import { SlotService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useSlots hook ------------------------------------
type IUseSlots = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof SlotService.filter>;
};
export const useSlots = ({ options, config }: IUseSlots = {}) => {
  return useQuery({
    ...config,
    queryKey: [SlotService.NAME, options],
    queryFn: () => SlotService.filter(options),
  });
};

//----------------------- useSlot hook --------------------------------------
type IUseSlot = {
  id: string;
  config?: QueryConfig<typeof SlotService.filterById>;
};

export const useSlot = ({ id, config }: IUseSlot) => {
  return useQuery({
    ...config,
    queryFn: () => SlotService.filterById(id),
  });
};

//------------------ useCreateSlot hook ---------------------------------
type IUseCreateSlot = {
  config?: MutationConfig<typeof SlotService.create>;
};

export const useCreateSlot = ({ config }: IUseCreateSlot = {}) => {
  return useMutation({
    ...config,
    mutationFn: SlotService.create,
  });
};

//------------------ useUpdateSlot hook ----------------------------------
type IUseUpdateSlot = {
  config?: MutationConfig<typeof SlotService.update>;
};

export const useUpdateSlot = ({ config }: IUseUpdateSlot = {}) => {
  return useMutation({
    ...config,
    mutationFn: SlotService.update,
  });
};

//------------------ useDeleteSlot hook ----------------------------------
type IUseDeleteSlot = {
  config?: MutationConfig<typeof SlotService.delete>;
};

export const useDeleteSlot = ({ config }: IUseDeleteSlot = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(SlotService.NAME);
        notification.success({
          message: res?.data?.message || 'Slot successfully deleted',
        });
      }
    },
    mutationFn: SlotService.delete,
  });
};
