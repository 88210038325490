import { Authorization } from '@modules/auth';
import { useProviderProduct } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

const ProviderProductDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useProviderProduct({ id });

  console.log(data?.data?.payload);

  return (
    <Authorization allowedAccess={['PROVIDER_PRODUCT_VIEW', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader
          onBack={() => navigate(-1)}
          title="Product Product details"
        ></PageHeader>
      </Purify>
    </Authorization>
  );
};
export default ProviderProductDetailsPage;
