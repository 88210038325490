import React, { useEffect, useState } from 'react';
import { IOfferUpdate } from '@shared/interfaces';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { CustomUpload } from '@shared/utils';
import moment from 'moment';

interface IProps {
  initialValues?: IOfferUpdate;
  onFinish?: (values: IOfferUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const OfferUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const [image, setImage] = useState('');

  const onSubmitForm = (values: IOfferUpdate) => {
    values.image = image || initialValues.image;
    values.startDate = moment(values.startDate).format('YYYY-MM-DD');
    values.endDate = moment(values.endDate).format('YYYY-MM-DD');
    onFinish(values);
    // console.log(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Title" name="title">
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="startDate" name="startDate">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="endDate" name="endDate">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="description" name="description">
            <Input.TextArea placeholder="Enter description " />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <CustomUpload onChange={(url) => setImage(url)} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default OfferUpdateForm;
