import {
  addPermissions,
  addToken,
  removePermissions,
  removeToken,
} from '@shared/redux';
import { AuthService, UserService } from '@shared/services';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';

export const useAuth = () => {
  const dispatch = useDispatch();

  const loginFn = useMutation(AuthService.login, {
    onSuccess: async (res) => {
      if (res.data?.success) {
        dispatch(addToken(res.data?.payload?.token));
        notification.success({
          message: 'Login Success',
        });
        const permissions = await (await UserService.getPermissions()).data;
        dispatch(addPermissions(permissions?.payload?.token));
      } else {
        notification.error({ message: res?.data?.message });
      }
    },
  });
  const logoutFn = () => {
    dispatch(removeToken());
    dispatch(removePermissions());
  };

  const loadUserFn = () => {};

  return {
    loginFn,
    logoutFn,
    loadUserFn,
  };
};
