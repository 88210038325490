import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { usePackages } from '@shared/hooks';
import { _ } from '@shared/utils';
import { useState } from 'react';
import { Button, PageHeader, Tag } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PackageList from '../components/PackageList';
import { useDebounce } from 'ahooks';
import PackageQueryForm from '../components/PackageQueryForm';

const PackageListRoute = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    title: searchParams.get('title'),
    department: searchParams.get('department'),
  });

  let pureQuery = _.toCleanObject({ ...query });
  Object.keys(query)?.map(
    (key) => (pureQuery[key] = { $contL: pureQuery[key] })
  );
  const debouncedQuery = useDebounce(pureQuery, { wait: 1000 });

  const { data, isLoading } = usePackages({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      s: _.toCleanObject({
        title: debouncedQuery?.title,
        'department.id': query?.department,
      }),
      sort: ['createdAt,DESC'],
    },
  });

  return (
    <Authorization allowedAccess={['PACKAGE_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Package List"
        subTitle={<Tag key={1}>Total Package: {data?.data?.total}</Tag>}
        extra={[
          <Button
            key={1}
            type="primary"
            onClick={() => navigate(Paths.PackageCreate)}
          >
            Create
          </Button>,
        ]}
      >
        <PackageQueryForm
          initialValues={_.toCleanObject({ ...query })}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        />

        <PackageList
          data={data?.data}
          loading={isLoading}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};
export default PackageListRoute;
