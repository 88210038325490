import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import PackageCreateRoute from './routes/PackageCreateRoue';
import PackageDetailsRoute from './routes/PackageDetailsRoute';
import PackageListRoute from './routes/PackageListRoute';
import PackageUpdateRoute from './routes/PackageUpdateRoute';

export const PackageRoutes = [
  { path: Paths.PackageList, element: <PackageListRoute /> },
  { path: Paths.PackageCreate, element: <PackageCreateRoute /> },
  { path: Paths.PackageUpdate(), element: <PackageUpdateRoute /> },
  { path: Paths.PackageDetails(), element: <PackageDetailsRoute /> },
  { path: Paths.Package, element: <Navigate to={Paths.PackageList} /> },
];
