import { getAccess } from '@modules/auth';
import ImagePreview from '@shared/components/ImagePreview';
import { Paths } from '@shared/constant';
import { useDeleteProvider } from '@shared/hooks';
import { IProvider } from '@shared/interfaces';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit, AiOutlineBars } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

interface IProps {
  isLoading: boolean;
  provirders: IProvider[];
  pagination: PaginationProps;
}
const ProviderList: React.FC<IProps> = ({
  isLoading,
  provirders,
  pagination,
}) => {
  const navigate = useNavigate();
  const deleteProvider = useDeleteProvider();

  const dataSource = provirders?.map((x: IProvider) => ({
    key: x.id,
    id: x.id,
    name: x?.name,
    logo: x?.logo,
    priority: x?.priority,
    isActive: x?.isActive.toString(),
    department: x?.department?.title,
    email: x?.email,
    nid: x?.nid,
    passport: x?.passport,
    tradeLicense: x?.tradeLicense,
    address: x?.address,
    memorandum: x?.memorandum,
  }));

  const columns = [
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo: string) => <ImagePreview src={logo} alt="logo" />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Nid',
      dataIndex: 'nid',
      key: 'nid',
    },
    {
      title: 'Passport',
      dataIndex: 'passport',
      key: 'passport',
    },
    {
      title: 'Trade License',
      dataIndex: 'tradeLicense',
      key: 'tradeLicense',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.ProviderUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Package?"
            onConfirm={() =>
              getAccess(['PROVIDER_DELETE', 'FORBIDDEN'], () =>
                deleteProvider.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.ProviderDetails(id))}
          >
            <AiOutlineBars />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
    />
  );
};
export default ProviderList;
