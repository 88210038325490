import { Authorization } from '@modules/auth';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useCreateDepartmentImage } from '@shared/hooks';
import DepartmentImageCreateForm from '../components/DepartmentImageCreateForm';

const DepartmentImageCreatePage = () => {
  const navigate = useNavigate();

  const createDepartmentImage = useCreateDepartmentImage({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message:
              res?.data?.message || 'DepartmentImage Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['DEPARTMENT_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create DepartmentImage">
        <DepartmentImageCreateForm
          isLoading={createDepartmentImage.isLoading}
          onFinish={(values) => createDepartmentImage.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default DepartmentImageCreatePage;
