import { IProduct } from '@shared/interfaces';
import { Col, Row } from 'antd';
import React from 'react';

interface IProps {
  product: IProduct;
}
const ProductDetails: React.FC<IProps> = ({ product }) => {
  // var ProductDetails = Object.keys(product).map((key) => ({
  //   title: key,
  //   value: product[key],
  // }));

  return (
    <Row
      justify="space-around"
      gutter={[
        { sm: 16, md: 20, lg: 30 },
        { sm: 30, md: 30, lg: 30 },
      ]}
    >
      <Col span={8}>
        <ul>
          <li>
            <b>Title:</b> {product.title}
          </li>
          <li>
            <b>Active:</b> {product.isActive?.toString()}
          </li>
          <li>
            <b>Single Order:</b> {product.isSingleOrder?.toString()}
          </li>
          <li>
            <b>Order Available:</b> {product.isOrderAvailable?.toString()}
          </li>
          <li>
            <b>AddableToCart:</b> {product.isAddableToCart?.toString()}
          </li>
          <li>
            <b>Department:</b> {product.department?.title}
          </li>
          <li>
            <b>Category:</b> {product.category?.title}
          </li>
          <li>
            <b>Subcategory:</b> {product.subCategory?.title}
          </li>
        </ul>
      </Col>
      <Col span={8}>
        <ul>
          <li>
            <b>Sample Type:</b> {product.sampleType}
          </li>
          <li>
            <b>Applicable For:</b> {product.applicableFor}
          </li>
          <li>
            <b>Age Group:</b> {product.ageGroup}
          </li>
          <li>
            <b>What Is:</b> {product.whatIs}
          </li>
          <li>
            <b>Why You Should Take:</b> {product.whyYouShouldTake}
          </li>
          <li>
            <b>Description:</b> {product.description}
          </li>
        </ul>
      </Col>
      <Col span={8}>
        <ul>
          <li>
            <b>Instructions:</b>{' '}
            <ul>
              {JSON.parse(product.instructions)?.map((item, i) => (
                <li key={i} className="flex items-center gap-2">
                  <img src={item.icon} alt="radio-icon" /> {item.content}
                </li>
              ))}
            </ul>
          </li>
          <li>
            <b>This Service Covers:</b>
            <ul>
              {JSON.parse(product.thisServiceCovers)?.map((item, i) => (
                <li key={i} className="flex items-center gap-2">
                  <img src={item.icon} alt="radio-icon" /> {item.content}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </Col>
      <Col span={24}>
        <Row
          justify="center"
          gutter={[
            { sm: 16, md: 30, lg: 50 },
            { sm: 16, md: 30, lg: 50 },
          ]}
        >
          <Col span={6}>
            <h3 className="mb-2">Icon</h3>
            <img
              className="h-60 w-full object-cover shadow"
              src={product.icon}
              alt="Package-icon"
            />
          </Col>
          <Col span={6}>
            <h3 className="mb-2">Image</h3>
            <img
              className="h-60 w-full object-cover shadow"
              src={product.image}
              alt="PackageImage"
            />
          </Col>
          <Col span={6}>
            <h3 className="mb-2">Background Image</h3>
            <img
              className="h-60 w-full object-cover shadow"
              src={product.backgroundImage}
              alt="Package-bgImage"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ProductDetails;
