import { Authorization } from '@modules/auth';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useOrder } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { Button, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import OrderInvoice from '../components/OrderInvoice';

const OrderInvoiceRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useOrder({ id });
  return (
    <Authorization allowedAccess={['ORDER_VIEW', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader
          title="Order Invoice"
          onBack={() => navigate(-1)}
          extra={[
            <PDFDownloadLink
              key={1}
              document={<OrderInvoice order={data?.data?.payload} />}
              fileName="orderInvoice.pdf"
            >
              <Button type="primary">Download Invoice</Button>
            </PDFDownloadLink>,
          ]}
        >
          <PDFViewer width="100%" height="500">
            <OrderInvoice order={data?.data?.payload} />
          </PDFViewer>
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default OrderInvoiceRoute;
