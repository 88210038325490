import { Authorization } from '@modules/auth';
import { useProviderPackage, useUpdateProviderPackage } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ProviderPackageUpdateForm from '../components/ProviderPackageUpdateForm';

const ProviderPackageUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useProviderPackage({ id });

  const updateProviderPackage = useUpdateProviderPackage({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  console.log(data?.data?.payload);

  return (
    <Authorization allowedAccess={['PROVIDER_PACKAGE_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update Provider Package">
          <ProviderPackageUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateProviderPackage.isLoading}
            onFinish={(values) =>
              updateProviderPackage.mutateAsync({ ...values, id })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default ProviderPackageUpdatePage;
