import { ProviderDiscountService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useProviderDiscounts hook ------------------------------------
type IUseProviderDiscounts = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof ProviderDiscountService.filter>;
};
export const useProviderDiscounts = ({
  options,
  config,
}: IUseProviderDiscounts = {}) => {
  return useQuery({
    ...config,
    queryKey: [ProviderDiscountService.NAME, options],
    queryFn: () => ProviderDiscountService.filter(options),
  });
};

//----------------------- useProviderDiscount hook --------------------------------------
type IUseProviderDiscount = {
  id: string;
  config?: QueryConfig<typeof ProviderDiscountService.filterById>;
};

export const useProviderDiscount = ({ id, config }: IUseProviderDiscount) => {
  return useQuery({
    ...config,
    queryFn: () => ProviderDiscountService.filterById(id),
  });
};

//------------------ useCreateProviderDiscount hook ---------------------------------
type IUseCreateProviderDiscount = {
  config?: MutationConfig<typeof ProviderDiscountService.create>;
};

export const useCreateProviderDiscount = ({
  config,
}: IUseCreateProviderDiscount = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderDiscountService.create,
  });
};

//------------------ useUpdateProviderDiscount hook ----------------------------------
type IUseUpdateProviderDiscount = {
  config?: MutationConfig<typeof ProviderDiscountService.update>;
};

export const useUpdateProviderDiscount = ({
  config,
}: IUseUpdateProviderDiscount = {}) => {
  return useMutation({
    ...config,
    mutationFn: ProviderDiscountService.update,
  });
};

//------------------ useDeleteProviderDiscount hook ----------------------------------
type IUseDeleteProviderDiscount = {
  config?: MutationConfig<typeof ProviderDiscountService.delete>;
};

export const useDeleteProviderDiscount = ({
  config,
}: IUseDeleteProviderDiscount = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(ProviderDiscountService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: ProviderDiscountService.delete,
  });
};
