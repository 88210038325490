import { useUsers } from '@shared/hooks';
import { IOrderRequestCreate, IOrderRequest, IUser } from '@shared/interfaces';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';

interface IProps {
  initialValues?: IOrderRequest;
  onFinish?: (values: IOrderRequestCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const OrderRequestCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  //necessary data fetching
  const users: IUser[] = useUsers().data?.data?.payload;

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please enter first name!',
              },
            ]}
          >
            <Input placeholder="Enter a first name" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please enter last name!',
              },
            ]}
          >
            <Input placeholder="Enter a last name" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: 'Please enter phone number!',
              },
            ]}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Request Type"
            name="requestType"
            rules={[
              {
                required: true,
                message: 'Please enter request type!',
              },
            ]}
          >
            <Input placeholder="Enter request type" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="User"
            name="user"
            rules={[
              {
                required: true,
                message: 'Please Select a user!',
              },
            ]}
          >
            <Select
              placeholder="Select  a user"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users?.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.firstName + ' ' + user.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Is Active"
            name="isActive"
            rules={[
              {
                required: true,
                message: 'Please choose a value!',
              },
            ]}
          >
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={24} xl={24}>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter description!',
              },
            ]}
          >
            <Input.TextArea rows={3} placeholder="Write something" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderRequestCreateForm;
