import { Authorization } from '@modules/auth';
import { useCategory, useUpdateCategory } from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import CategoryUpdateForm from '../components/CategoryUpdateForm';

const CategoryUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useCategory({ id });

  const updateCategory = useUpdateCategory({
    config: {
      onSuccess: (res) => {
        if (res.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['CATEGORY_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader onBack={() => navigate(-1)} title="Update Category">
          <CategoryUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateCategory.isLoading}
            onFinish={(values) => updateCategory.mutateAsync({ ...values, id })}
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default CategoryUpdateRoute;
