import { Authorization } from '@modules/auth';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useCreateCallRequest } from '@shared/hooks';
import CallRequestCreateForm from '../components/CallRequestCreateForm';

const CallRequestCreatePage = () => {
  const navigate = useNavigate();

  const createCallRequest = useCreateCallRequest({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: res?.data?.message || 'Call Request Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ORDER_CREATE', 'FORBIDDEN']}>
      <PageHeader
        onBack={() => navigate(-1)}
        title="Create Call Request"
      ></PageHeader>
      <CallRequestCreateForm
        isLoading={createCallRequest.isLoading}
        onFinish={(values) => createCallRequest.mutateAsync(values)}
      />
    </Authorization>
  );
};
export default CallRequestCreatePage;
