import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { Button, PageHeader } from 'antd';
import { useState } from 'react';
import { useDebounce } from 'ahooks';
import { _ } from '@shared/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ProviderPackageList from '../components/ProviderPackageList';
import ProviderPackageQueryForm from '../components/ProviderPackageQueryForm';
import { useProviderPackages } from '@shared/hooks';

const ProviderPackagesPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState(
    _.toCleanObject({
      title: searchParams.get('title'),
      'provider.id': searchParams.get('provider'),
    })
  );

  let pureQuery = _.toCleanObject({ ...query });
  Object.keys(query)?.map(
    (key) => (pureQuery[key] = { $contL: pureQuery[key] })
  );
  const debouncedQuery = useDebounce(pureQuery, { wait: 1000 });

  const { data, isLoading } = useProviderPackages({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      s: _.toCleanObject({
        title: debouncedQuery?.title,
        'provider.id': query?.provider,
      }),
      sort: ['createdAt,DESC'],
    },
  });

  return (
    <Authorization allowedAccess={['PROVIDER_PACKAGE_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Provider Package List"
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.ProviderPackageCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <ProviderPackageQueryForm
          initialValues={_.toCleanObject({ ...query })}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        />
        <ProviderPackageList
          isLoading={isLoading}
          providerPackages={data?.data?.payload}
          pagination={{
            total: data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};

export default ProviderPackagesPage;
