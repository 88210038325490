import { Paths } from '@shared/constant';
import { useDeleteOrderRequest } from '@shared/hooks';
import { IOrderRequest } from '@shared/interfaces';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { _ } from '@shared/utils';
import { getAccess } from '@modules/auth';

interface IProps {
  isLoading: boolean;
  data: IOrderRequest[];
  pagination: PaginationProps;
}
const BloodRequestsList: React.FC<IProps> = ({
  isLoading,
  data,
  pagination,
}) => {
  const navigate = useNavigate();

  const deleteOrderRequest = useDeleteOrderRequest();

  const dataSource = data?.map((x: IOrderRequest) => ({
    key: x?.id,
    id: x?.id,
    user: x?.user?.firstName + ' ' + x?.user?.lastName,
    bloodGroup: x?.bloodGroup,
    phoneNumber: x?.phoneNumber,
    location: x?.location,
    description: x?.description,
    date: _.getStutteredDate(x?.date),
    createdAt: _.getStutteredDate(x?.createdAt),
  }));

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'bloodGroup',
      dataIndex: 'bloodGroup',
      key: 'bloodGroup',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      // sorter: (a, b) => a.createdAt.length - b.createdAt.length,
    },

    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(`${Paths.orderRequestUpdate}/${id}`)}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Department?"
            onConfirm={() =>
              getAccess(['ORDER_DELETE', 'FORBIDDEN'], () =>
                deleteOrderRequest.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
    />
  );
};
export default BloodRequestsList;
