import { Authorization } from '@modules/auth';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useCreateFeaturedItem } from '@shared/hooks';
import FeaturedItemCreateForm from '../components/FeaturedItemCreateForm';

const FeaturedItemCreateRoute = () => {
  const navigate = useNavigate();

  const createFeaturedItem = useCreateFeaturedItem({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: res?.data?.message || 'FeaturedItem Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['FEATURED_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Featured Item">
        <FeaturedItemCreateForm
          isLoading={createFeaturedItem.isLoading}
          onFinish={(values) => createFeaturedItem.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default FeaturedItemCreateRoute;
