import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { useFamilies, useUsers } from '@shared/hooks';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserList from '../components/UserList';
import { _ } from '@shared/utils';
import moment from 'moment';
import { useDebounce } from 'ahooks';

const UsersPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [createdAt, setCreatedAt] = useState<any>(
    searchParams.get('createdAt') || ''
  );
  const [query, setQuery] = useState(
    _.toCleanObject({
      firstName: searchParams.get('firstName'),
      phoneNumber: searchParams.get('phoneNumber'),
      referenceCode: searchParams.get('referenceCode'),
      createdAt: createdAt,
    })
  );

  let pureQuery = _.toCleanObject({ ...query });
  Object.keys(query)?.map(
    (key) => (pureQuery[key] = { $contL: pureQuery[key] })
  );
  const debouncedQuery = useDebounce(pureQuery, { wait: 1000 });
  const usersQuery = useUsers({
    options: {
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 10,
      s: _.toCleanObject({
        ...debouncedQuery,
        role: 'Customer',
        createdAt: _.jsonParser(createdAt),
      }),
      sort: ['createdAt,DESC'],
    },
  });

  const familiesQuery = useFamilies({
    options: {
      s: _.toCleanObject({
        createdAt: _.jsonParser(createdAt),
      }),
    },
  });

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      createdAt:
        Object.keys(_.jsonParser(createdAt))?.length >= 2
          ? 'Date Range'
          : createdAt,
    });
  }, [createdAt, form]);

  return (
    <Authorization allowedAccess={['USER_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="User List"
        subTitle={
          <>
            <Tag key={1}>Total user: {usersQuery?.data?.data?.total}</Tag>
            <Tag key={2}>
              Total family member: {familiesQuery?.data?.data?.total}
            </Tag>
          </>
        }
        extra={[
          <Button
            key={1}
            onClick={() => navigate(Paths.userCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <Form
          form={form}
          size="large"
          layout="vertical"
          className="data-query-form"
          initialValues={{ ...query }}
          onValuesChange={(c, v) => {
            setSearchParams(
              _.toCleanObject({
                ...v,
              })
            );
            setQuery(_.toCleanObject(v));
          }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              <Form.Item label="Name" name="firstName">
                <Input allowClear placeholder="Search by name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              <Form.Item label="Phone Number" name="phoneNumber">
                <Input allowClear placeholder="Search by phone number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              <Form.Item label="Reference Code" name="referenceCode">
                <Input allowClear placeholder="Search by reference code" />
              </Form.Item>
            </Col>
            <div className="flex flex-wrap lg:ml-1">
              <Form.Item
                className="w-full sm:w-40"
                label="createdAt"
                name="createdAt"
              >
                <Select
                  placeholder="Select a date"
                  onChange={(value) => setCreatedAt(value)}
                >
                  <Select.Option value="">All</Select.Option>
                  <Select.Option
                    value={JSON.stringify({
                      $eq: moment().format('YYYY-MM-DD'),
                    })}
                  >
                    Today
                  </Select.Option>
                  <Select.Option
                    value={JSON.stringify({
                      $eq: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                    })}
                  >
                    Yesterday
                  </Select.Option>
                  <Select.Option
                    value={JSON.stringify({
                      $gte: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    })}
                  >
                    Last 7 days
                  </Select.Option>
                  <Select.Option
                    value={JSON.stringify({
                      $gte: moment().subtract(15, 'days').format('YYYY-MM-DD'),
                    })}
                  >
                    Last 15 days
                  </Select.Option>
                  <Select.Option
                    value={JSON.stringify({
                      $gte: moment().startOf('month').format('YYYY-MM-DD'),
                    })}
                  >
                    This Month
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Custom date range">
                <DatePicker.RangePicker
                  onChange={(v) => {
                    if (_.isEmpty(v)) {
                      setCreatedAt('');
                      setSearchParams(_.toCleanObject({ ...query }));
                      return;
                    }

                    setCreatedAt(
                      JSON.stringify({
                        $gte: moment(v[0]).format('YYYY-MM-DD'),
                        $lte: moment(v[1]).format('YYYY-MM-DD'),
                      })
                    );
                    setSearchParams(
                      _.toCleanObject({
                        ...query,
                        createdAt: JSON.stringify({
                          $gte: moment(v[0]).format('YYYY-MM-DD'),
                          $lte: moment(v[1]).format('YYYY-MM-DD'),
                        }),
                      })
                    );
                  }}
                />
              </Form.Item>
            </div>
          </Row>
        </Form>

        <UserList
          users={usersQuery?.data?.data?.payload}
          families={familiesQuery?.data?.data?.payload}
          isLoading={usersQuery?.isLoading || familiesQuery?.isLoading}
          pagination={{
            total: usersQuery?.data?.data?.total,
            current: parseInt(searchParams.get('page')) || 1,
            pageSize: parseInt(searchParams.get('limit')) || 10,
            onChange: (page, limit) =>
              setSearchParams(_.toCleanObject({ ...query, page, limit })),
          }}
        />
      </PageHeader>
    </Authorization>
  );
};
export default UsersPage;
