import { Authorization } from '@modules/auth';
import { useCreateProvider } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import ProviderCreateForm from '../components/ProviderCreateForm';

const ProviderCreatePage = () => {
  const navigate = useNavigate();

  const createProvider = useCreateProvider({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PROVIDER_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Provider">
        <ProviderCreateForm
          isLoading={createProvider.isLoading}
          onFinish={(values) => createProvider.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};

export default ProviderCreatePage;
