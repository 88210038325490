import { usePackages, useUsers } from '@shared/hooks';
import { ICallRequestUpdate, IPackage, IUser } from '@shared/interfaces';
import { Button, Col, Form, InputNumber, Radio, Row, Select } from 'antd';
import { useEffect } from 'react';
const { Option } = Select;

interface IProps {
  initialValues?: ICallRequestUpdate;
  onFinish?: (values: ICallRequestUpdate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const CallRequestUpdateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  // reset form value
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const users: IUser[] = useUsers().data?.data?.payload;
  const packages: IPackage[] = usePackages().data?.data?.payload;

  const onSubmitForm = (values: ICallRequestUpdate) => {
    const selectedUSer: IUser = users.find((user) => user?.id === values.user);
    values.firstName = selectedUSer.firstName || ' ';
    values.lastName = selectedUSer.lastName || ' ';
    values.phoneNumber = selectedUSer.phoneNumber;
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="user"
            name="user"
            rules={[
              {
                required: true,
                message: 'Please Select user!',
              },
            ]}
          >
            <Select
              placeholder="Select a user"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users?.map((user) => (
                <Option key={user?.id} value={user?.id}>
                  {`${user?.firstName} ${user?.lastName} - ${user?.phoneNumber}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Package"
            name="package"
            rules={[
              {
                required: true,
                message: 'Please Select package!',
              },
            ]}
          >
            <Select
              placeholder="Select package"
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {packages?.map((pkg) => (
                <Option key={pkg.id} value={pkg.id}>
                  {pkg.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Is Active" name="isActive">
            <Radio.Group buttonStyle="solid" className="w-full">
              <Radio.Button className="w-1/2 text-center" value={true}>
                True
              </Radio.Button>
              <Radio.Button className="w-1/2 text-center" value={false}>
                False
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item label="Priority" name="priority">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter a priority"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CallRequestUpdateForm;
