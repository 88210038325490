import { Button, PageHeader, Modal } from 'antd';
import { Authorization, getAccess } from '@modules/auth';
import { useState } from 'react';
import BannerList from '../components/BannerList';
import BannerCreateForm from '../components/BannerCreateForm';

const BannersRoute = () => {
  const [createBanner, setCreateBanner] = useState<boolean>(false);

  return (
    <Authorization allowedAccess={['BANNER_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Banners"
        extra={[
          <Button
            key="1"
            onClick={() =>
              getAccess(['BANNER_CREATE', 'FORBIDDEN'], () =>
                setCreateBanner(true)
              )
            }
            type="primary"
          >
            Create Banner
          </Button>,
        ]}
      >
        <BannerList />
      </PageHeader>

      <Modal
        centered
        title="Create Banner"
        width={700}
        visible={createBanner}
        onCancel={() => setCreateBanner(false)}
        footer={false}
      >
        <BannerCreateForm onSubmit={() => setCreateBanner(false)} />
      </Modal>
    </Authorization>
  );
};

export default BannersRoute;
