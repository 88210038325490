import { createSlice } from '@reduxjs/toolkit';
import { storage } from '@shared/utils';

export interface IAuthSlice {
  token: string;
  permissions: string;
  user: boolean;
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    token: storage.getToken() || '',
    permissions: storage.getData('_permission_token') || '',
    user: null,
  } as IAuthSlice,
  reducers: {
    addToken: (state: IAuthSlice, { payload }) => {
      state.token = payload;
      storage.setToken(payload);
    },
    removeToken: (state: any) => {
      state.token = '';
      storage.removeToken();
    },
    addPermissions: (state: IAuthSlice, { payload }) => {
      state.permissions = payload;
      storage.setData('_permission_token', payload);
    },
    removePermissions: (state: any) => {
      state.permissions = '';
      storage.removeData('_permission_token');
    },
  },
});
export const { addToken, removeToken, addPermissions, removePermissions } =
  authSlice.actions;

export default authSlice.reducer;
