import { Paths } from '@shared/constant';
import AmbulanceRequestsRoute from './routes/AmbulanceRequestsRoute';
import BloodRequestsRoute from './routes/BloodRequestsRoute';
import OrderRequestCreatePage from './routes/OrderRequestCreatePage';
import OrderRequestsPage from './routes/OrderRequestsPage';
import OrderRequestUpdatePage from './routes/OrderRequestUpdatePage';

export const OrderRequestRoutes = [
  { path: '', element: <OrderRequestsPage /> },
  { path: 'create', element: <OrderRequestCreatePage /> },
  { path: 'update/:id', element: <OrderRequestUpdatePage /> },
  { path: Paths.AmbulanceRequests, element: <AmbulanceRequestsRoute /> },
  { path: Paths.BloodRequests, element: <BloodRequestsRoute /> },
];
