import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import OfferCreateRoute from './routes/OfferCreateRoute';
import OfferListRoute from './routes/OfferListRoute';
import OfferUpdateRoute from './routes/OfferUpdateRoute';

export const OfferRoutes = [
  { path: Paths.OfferList, element: <OfferListRoute /> },
  { path: Paths.OfferCreate, element: <OfferCreateRoute /> },
  { path: Paths.OfferUpdate(), element: <OfferUpdateRoute /> },
  { path: Paths.Offer, element: <Navigate to={Paths.OfferList} /> },
  // { path: Paths.OfferCreate, element: <Offer /> },
];
