import { useDivisions } from '@shared/hooks';
import { IDistrict, IDistrictCreate, IDivision } from '@shared/interfaces';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';

interface IProps {
  initialValues?: IDistrict;
  onFinish?: (values: IDistrictCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}

const DistrictCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const divisions: IDivision[] = useDivisions().data?.data?.payload;

  return (
    <div className="lg:w-1/2 xl:w-max mt-20 mx-auto p-6 shadow-lg rounded-lg border">
      <Form
        size="large"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter a title!',
                },
              ]}
            >
              <Input placeholder="Enter a title" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Division"
              name="division"
              rules={[
                {
                  required: true,
                  message: 'Please select a division!',
                },
              ]}
            >
              <Select
                placeholder="Select a division"
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {divisions?.map((dv) => (
                  <Select.Option key={dv.id} value={dv.id}>
                    {dv.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Is Active"
              name="isActive"
              rules={[
                {
                  required: true,
                  message: 'Please chose a value!',
                },
              ]}
            >
              <Radio.Group buttonStyle="solid" className="w-full">
                <Radio.Button className="w-1/2 text-center" value={true}>
                  True
                </Radio.Button>
                <Radio.Button className="w-1/2 text-center" value={false}>
                  False
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item className="text-right">
              <Button loading={isLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DistrictCreateForm;
