import { OrderRequestService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useOrderRequests hook ------------------------------------
type IUseOrderRequests = {
  options: IBaseFilter;
  config?: QueryConfig<typeof OrderRequestService.filter>;
};
export const useOrderRequests = ({ options, config }: IUseOrderRequests) => {
  return useQuery({
    ...config,
    queryKey: [OrderRequestService.NAME, options],
    queryFn: () => OrderRequestService.filter(options),
  });
};

//----------------------- useOrderRequest hook --------------------------------------
type IUseOrderRequest = {
  id: string;
  config?: QueryConfig<typeof OrderRequestService.filterById>;
};

export const useOrderRequest = ({ id, config }: IUseOrderRequest) => {
  return useQuery({
    ...config,
    queryFn: () => OrderRequestService.filterById(id),
  });
};

//------------------ useCreateOrderRequest hook ---------------------------------
type IUseCreateOrderRequest = {
  config?: MutationConfig<typeof OrderRequestService.create>;
};

export const useCreateOrderRequest = ({
  config,
}: IUseCreateOrderRequest = {}) => {
  return useMutation({
    ...config,
    mutationFn: OrderRequestService.create,
  });
};

//------------------ useUpdateOrderRequest hook ----------------------------------
type IUseUpdateOrderRequest = {
  config?: MutationConfig<typeof OrderRequestService.update>;
};

export const useUpdateOrderRequest = ({
  config,
}: IUseUpdateOrderRequest = {}) => {
  return useMutation({
    ...config,
    mutationFn: OrderRequestService.update,
  });
};

//------------------ useDeleteOrderRequest hook ----------------------------------
type IUseDeleteOrderRequest = {
  config?: MutationConfig<typeof OrderRequestService.delete>;
};

export const useDeleteOrderRequest = ({
  config,
}: IUseDeleteOrderRequest = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(OrderRequestService.NAME);
        notification.success({
          message: 'Deleted successfully',
        });
      }
    },
    mutationFn: OrderRequestService.delete,
  });
};
