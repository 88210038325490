import { Card, Modal, Pagination, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { Purify } from '@shared/utils';
import React, { useState } from 'react';
import { useBanners, useDeleteBanner } from '@shared/hooks';
import { IBanner } from '@shared/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import BannerEditForm from './BannerEditForm';
import { getAccess } from '@modules/auth';

interface IProps {
  searchTerm?: string;
}
const BannerList: React.FC<IProps> = ({ searchTerm }) => {
  const [editId, setEditId] = useState<string>();

  const navigate = useNavigate();

  const { search } = useLocation();
  const query = {
    page: parseInt(search.slice(6, 7)) || 1,
    limit: parseInt(search.slice(13)) || 10,
  };
  const { data, isLoading } = useBanners({
    options: {
      page: query.page,
      limit: query.limit,
      searchTerm: searchTerm,
      sort: ['createdAt,DESC'],
    },
  });

  const deleteBanner = useDeleteBanner();

  return (
    <React.Fragment>
      <Purify
        loading={isLoading}
        empty={data?.data?.payload?.length <= 0 ? true : false}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  xl:grid-cols-5 gap-4">
          {data?.data?.payload?.map((banner: IBanner) => (
            <Card
              key={banner.id}
              cover={<img src={banner.link} alt="banner_image" />}
              actions={[
                <LinkOutlined
                  key="url"
                  onClick={() => window.open(banner.link)}
                />,

                <EditOutlined
                  key="edit"
                  onClick={() =>
                    getAccess(['BANNER_UPDATE', 'FORBIDDEN'], () =>
                      setEditId(banner.id)
                    )
                  }
                />,
                <Popconfirm
                  key="delete"
                  title="Are you sure to delete it"
                  onConfirm={() =>
                    getAccess(['BANNER_DELETE', 'FORBIDDEN'], () =>
                      deleteBanner.mutateAsync(banner.id)
                    )
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined />
                </Popconfirm>,
              ]}
            >
              <Card.Meta title={banner.type} />
            </Card>
          ))}
        </div>

        <div className="text-center py-10">
          <Pagination
            pageSize={10}
            current={query.page}
            total={data?.data?.total}
            onChange={(page: number, limit: number) => {
              navigate(`?page=${page}&limit=${limit}`);
            }}
          />
        </div>
      </Purify>

      <Modal
        centered
        title="Update Banner"
        width={700}
        visible={editId ? true : false}
        onCancel={() => setEditId('')}
        footer={false}
      >
        <BannerEditForm id={editId} onFinish={() => setEditId('')} />
      </Modal>
    </React.Fragment>
  );
};

export default BannerList;
