import { Authorization } from '@modules/auth';
import { useCreateOffer } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import OfferCreateForm from '../components/OfferCreateForm';

const OfferCreateRoute = () => {
  const navigate = useNavigate();

  const createOffer = useCreateOffer({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['SUBSCRIPTION_CREATE', 'FORBIDDEN']}>
      <PageHeader title="Create Offer" onBack={() => navigate(-1)}>
        <OfferCreateForm
          isLoading={createOffer.isLoading}
          onFinish={(values) => createOffer.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default OfferCreateRoute;
