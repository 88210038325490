import { Authorization } from '@modules/auth';
import {
  useProviderDepartment,
  useUpdateProviderDepartment,
} from '@shared/hooks';
import { Purify } from '@shared/utils';
import { notification, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ProviderDepartmentUpdateForm from '../components/ProviderDepartmentUpdateForm';

const ProviderDepartmentUpdateRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useProviderDepartment({ id });

  const updateProviderDepartment = useUpdateProviderDepartment({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Updated successfully',
          });
        }
      },
    },
  });

  console.log(data?.data?.payload);

  return (
    <Authorization allowedAccess={['PROVIDER_DEPARTMENT_UPDATE', 'FORBIDDEN']}>
      <Purify loading={isLoading}>
        <PageHeader
          onBack={() => navigate(-1)}
          title="Update Provider Department"
        >
          <ProviderDepartmentUpdateForm
            initialValues={data?.data?.payload}
            isLoading={updateProviderDepartment.isLoading}
            onFinish={(values) =>
              updateProviderDepartment.mutateAsync({ ...values, id })
            }
          />
        </PageHeader>
      </Purify>
    </Authorization>
  );
};
export default ProviderDepartmentUpdateRoute;
