import { PackageService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- usePackages hook ------------------------------------
type IUsePackages = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof PackageService.filter>;
};
export const usePackages = ({ options, config }: IUsePackages = {}) => {
  return useQuery({
    ...config,
    queryKey: [PackageService.NAME, options],
    queryFn: () => PackageService.filter(options),
  });
};

//----------------------- usePackage hook --------------------------------------
type IUsePackage = {
  id: string;
  config?: QueryConfig<typeof PackageService.filterById>;
};

export const usePackage = ({ id, config }: IUsePackage) => {
  return useQuery({
    ...config,
    queryFn: () => PackageService.filterById(id),
  });
};

//------------------ useCreatePackage hook ---------------------------------
type IUseCreatePackage = {
  config?: MutationConfig<typeof PackageService.create>;
};

export const useCreatePackage = ({ config }: IUseCreatePackage = {}) => {
  return useMutation({
    ...config,
    mutationFn: PackageService.create,
  });
};

//------------------ useUpdatePackage hook ----------------------------------
type IUseUpdatePackage = {
  config?: MutationConfig<typeof PackageService.update>;
};

export const useUpdatePackage = ({ config }: IUseUpdatePackage = {}) => {
  return useMutation({
    ...config,
    mutationFn: PackageService.update,
  });
};

//------------------ useDeletePackage hook ----------------------------------
type IUseDeletePackage = {
  config?: MutationConfig<typeof PackageService.delete>;
};

export const useDeletePackage = ({ config }: IUseDeletePackage = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(PackageService.NAME);
        notification.success({
          message: 'Successfully deleted',
        });
      }
    },
    mutationFn: PackageService.delete,
  });
};

interface IProviderPackage {
  id?: string;
  options?: IBaseFilter;
  config?: QueryConfig<typeof PackageService.getProviderPackages>;
}
export const usePackageProviderPackages = ({
  id,
  options,
  config,
}: IProviderPackage = {}) => {
  const queryName = `${PackageService.NAME}${PackageService.getProviderPackages.name}${id}`;
  return useQuery({
    ...config,
    queryKey: [queryName, options],
    queryFn: () => PackageService.getProviderPackages(id, options),
  });
};
