import { FamilyService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- useFamilies hook ------------------------------------
type IUseFamilies = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof FamilyService.filter>;
};
export const useFamilies = ({ options, config }: IUseFamilies = {}) => {
  return useQuery({
    ...config,
    queryKey: [FamilyService.NAME, options],
    queryFn: () => FamilyService.filter(options),
  });
};

//----------------------- useFamily hook --------------------------------------
type IUseFamily = {
  id: string;
  config?: QueryConfig<typeof FamilyService.filterById>;
};

export const useFamily = ({ id, config }: IUseFamily) => {
  return useQuery({
    ...config,
    queryFn: () => FamilyService.filterById(id),
  });
};

//------------------ useCreateFamily hook ---------------------------------
type IUseCreateFamily = {
  config?: MutationConfig<typeof FamilyService.create>;
};

export const useCreateFamily = ({ config }: IUseCreateFamily = {}) => {
  return useMutation({
    ...config,
    mutationFn: FamilyService.create,
  });
};

//------------------ useUpdateFamily hook ----------------------------------
type IUseUpdateFamily = {
  config?: MutationConfig<typeof FamilyService.update>;
};

export const useUpdateFamily = ({ config }: IUseUpdateFamily = {}) => {
  return useMutation({
    ...config,
    mutationFn: FamilyService.update,
  });
};

//------------------ useDeleteFamily hook ----------------------------------
type IUseDeleteFamily = {
  config?: MutationConfig<typeof FamilyService.delete>;
};

export const useDeleteFamily = ({ config }: IUseDeleteFamily = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(FamilyService.NAME);
        notification.success({
          message: 'Family successfully deleted',
        });
      }
    },
    mutationFn: FamilyService.delete,
  });
};
