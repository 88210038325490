import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import DepartmentCreatePage from './routes/DepartmentCreatePage';
import DepartmentsPage from './routes/DepartmentsPage';
import DepartmentUpdatePage from './routes/DepartmentUpdatePage';

export const DepartmentRoutes = [
  { path: Paths.DepartmentList, element: <DepartmentsPage /> },
  { path: Paths.DepartmentCreate, element: <DepartmentCreatePage /> },
  { path: Paths.DepartmentUpdate(), element: <DepartmentUpdatePage /> },
  { path: Paths.Department, element: <Navigate to={Paths.DepartmentList} /> },
];
