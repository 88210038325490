import { getAccess } from '@modules/auth';
import ImagePreview from '@shared/components/ImagePreview';
import { Paths } from '@shared/constant';
import { useDeletePackage } from '@shared/hooks';
import { IPackage, IPackagesResponse } from '@shared/interfaces';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit, AiOutlineBars } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

interface IProps {
  data?: IPackagesResponse;
  loading?: boolean;
  pagination: PaginationProps;
}
const PackageList: React.FC<IProps> = ({ data, loading, pagination }) => {
  const navigate = useNavigate();

  const deletePackage = useDeletePackage();

  const dataSource = data?.payload?.map((x: IPackage) => ({
    key: x?.id,
    id: x?.id,
    title: x?.title,
    description: x?.description,
    profile: x?.profile,
    priority: x?.priority,
    isActive: x?.isActive?.toString(),
    isDoctorConsultancyAvailable: x?.isDoctorConsultancyAvailable?.toString(),
    isAddableToCart: x?.isAddableToCart?.toString(),
    isOrderAvailable: x?.isOrderAvailable?.toString(),
    department: x?.department?.title,
    category: x?.category?.title,
    subCategory: x?.subCategory?.title,
    icon: x?.icon,
    image: x?.image,
    backgroundImage: x?.backgroundImage,
    whatIs: x?.whatIs,
    thisServiceCovers: x?.thisServiceCovers,
    whyYouShouldTake: x?.whyYouShouldTake,
    instructions: x?.instructions,
    applicableFor: x?.applicableFor,
    ageGroup: x?.ageGroup,
  }));

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'IsActive',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Subcategory',
      dataIndex: 'subCategory',
      key: 'subCategory',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon: string) => <ImagePreview src={icon} alt="icon" />,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="thumbImage" />,
    },
    {
      title: 'BgImage',
      dataIndex: 'backgroundImage',
      key: 'backgroundImage',
      render: (backgroundImage: string) => (
        <ImagePreview src={backgroundImage} alt="BgImage" />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.PackageUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Package?"
            onConfirm={() =>
              getAccess(['PACKAGE_DELETE', 'FORBIDDEN'], () =>
                deletePackage.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
          <Button
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.PackageDetails(id))}
          >
            <AiOutlineBars />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={pagination}
    />
  );
};

export default PackageList;
