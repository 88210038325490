import { Authorization } from '@modules/auth';
import { Paths } from '@shared/constant';
import { Button, Input, PageHeader } from 'antd';
import { startTransition, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import BaseDepartmentList from '../components/BaseDepartmentList';

const BaseDepartmentsPage = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>();

  return (
    <Authorization allowedAccess={['DEPARTMENT_VIEW', 'FORBIDDEN']}>
      <PageHeader
        title="Base Department List"
        subTitle={
          <Input
            size="large"
            placeholder="Search"
            prefix={<AiOutlineSearch />}
            style={{ borderRadius: 30 }}
            onChange={(e) =>
              startTransition(() => setSearchTerm(e.target.value))
            }
          />
        }
        extra={[
          <Button
            key="1"
            onClick={() => navigate(Paths.BaseDepartmentCreate)}
            type="primary"
          >
            Create
          </Button>,
        ]}
      >
        <BaseDepartmentList searchTerm={searchTerm} />
      </PageHeader>
    </Authorization>
  );
};

export default BaseDepartmentsPage;
