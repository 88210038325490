import React, { useState } from 'react';
import {
  IDurationEntity,
  IFamily,
  IProviderPackage,
  ISubscriptionCreate,
  IUser,
} from '@shared/interfaces';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { CustomUpload } from '@shared/utils';
import { useFamilies, useProviderPackages, useUsers } from '@shared/hooks';
import moment from 'moment';
const { Option } = Select;

interface IProps {
  initialValues?: ISubscriptionCreate;
  onFinish?: (values: ISubscriptionCreate) => void;
  onFinishFailed?: (errorInfo: any) => void;
  isLoading?: boolean;
}
const SubscriptionCreateForm: React.FC<IProps> = ({
  initialValues,
  onFinish,
  onFinishFailed,
  isLoading,
}) => {
  const [selectedPackage, setSelectedPackage] = useState('');

  const packages: IProviderPackage[] = useProviderPackages({
    options: {
      filter: ['department.uiType||$eq||SUBSCRIPTION'],
    },
  })?.data?.data?.payload;

  const users: IUser[] = useUsers().data?.data?.payload;
  const [selectedUser, setSelectedUser] = useState('');
  const families: IFamily[] = useFamilies({
    options: {
      filter: selectedUser && [`user.id||$eq||${selectedUser}`],
    },
  }).data?.data?.payload;

  const getDuration = (duration: IDurationEntity) => {
    if (duration.daily) return '1 Day';
    if (duration.weekly) return '1 Week';
    if (duration.monthly) return '1 Month';
    if (duration.quarterly) return '3 Months';
    if (duration.halfYearly) return '6 Months';
    if (duration.yearly) return '1 Year';

    return '';
  };

  const [image, setImage] = useState('');
  const onSubmitForm = (values: ISubscriptionCreate) => {
    const chosenPackage = packages?.find((pk) => pk?.id === selectedPackage);

    const expDA = Number(getDuration(chosenPackage?.duration).split(' ')[0]);
    const expDU: any = getDuration(chosenPackage?.duration).split(' ')[1];

    values.title = values.title || chosenPackage?.title;
    values.duration = getDuration(chosenPackage?.duration);
    values.amount = chosenPackage?.hbPatientPrice;
    values.image = image || chosenPackage?.package?.icon;
    values.issueDate = moment(values.issueDate).format('YYYY-MM-DD: hh:mm:ss');
    values.expiryDate = moment(values.issueDate)
      .add(expDA, expDU)
      .format('YYYY-MM-DD: hh:mm:ss');
    onFinish(values);
  };

  return (
    <Form
      size="large"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{ sm: 16, md: 20, lg: 30 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="Title" name="title">
            <Input placeholder="Enter a title" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="duration" name="duration">
            <Input disabled placeholder="Enter duration" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="providerPackage"
            name="providerPackage"
            rules={[
              {
                required: true,
                message: 'Please select a package!',
              },
            ]}
          >
            <Select
              placeholder="Select a package"
              showSearch
              allowClear
              onChange={(val) => setSelectedPackage(val)}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {packages?.map((pk) => (
                <Option key={pk.id} value={pk.id}>
                  {pk.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item
            label="amount"
            name="amount"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter amount!',
            //   },
            // ]}
          >
            <InputNumber
              disabled
              style={{ width: '100%' }}
              placeholder="Enter amount"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="issueDate"
            name="issueDate"
            rules={[
              {
                required: true,
                message: 'Please enter issue date!',
              },
            ]}
          >
            <DatePicker showTime style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="expiryDate"
            name="expiryDate"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter expiry date!',
            //   },
            // ]}
          >
            <DatePicker disabled showTime style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="User"
            name="user"
            rules={[
              {
                required: true,
                message: 'Please select a user!',
              },
            ]}
          >
            <Select
              placeholder="Select a user"
              showSearch
              allowClear
              onChange={(val) => setSelectedUser(val)}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users?.map((user) => (
                <Option key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName} - ${user.phoneNumber}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="family"
            name="family"
            rules={[
              {
                required: true,
                message: 'Please select a family!',
              },
            ]}
          >
            <Select
              placeholder="Select a family"
              showSearch
              allowClear
              disabled={selectedUser ? false : true}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {families?.map((family) => (
                <Option key={family.id} value={family.id}>
                  {`${family.firstName} ${family.lastName} - ${family.relationship}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item label="Image" name="image">
            <CustomUpload onChange={(url) => setImage(url)} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="text-right">
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default SubscriptionCreateForm;
