import React, { useState } from 'react';
import { Paths } from '@shared/constant';
import { useUpdateOrder } from '@shared/hooks';
import { IOrder } from '@shared/interfaces';
import {
  Button,
  Form,
  Modal,
  PaginationProps,
  Select,
  Space,
  Table,
} from 'antd';
import { AiFillEdit, AiOutlineBars } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import OrderDetails from './OrderDetails';
import { FaFileInvoice } from 'react-icons/fa';
import { queryClient } from '@shared/config';
import { OrderService } from '@shared/services';
import { ColumnsType } from 'antd/lib/table';
import { _ } from '@shared/utils';

interface IProps {
  isLoading: boolean;
  data: IOrder[];
  pagination: PaginationProps;
}

const OrderList: React.FC<IProps> = ({ isLoading, data, pagination }) => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [editId, setEditId] = useState<string>();

  const updateOrder = useUpdateOrder({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          queryClient.invalidateQueries(OrderService.NAME);
          setEditId(null);
        }
      },
    },
  });

  const dataSource = data?.map((x: IOrder) => ({
    key: x?.id,
    id: x?.id,
    user: x?.user.firstName + ' ' + x?.user.lastName,
    address: x?.address?.title,
    provider: x?.provider?.name,
    instructions: x?.instructions,
    deliveryDate: x?.deliveryDate,
    createdAt: _.getStutteredDate(x?.createdAt),
    status: x?.status,
  }));

  const columns: ColumnsType = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={`order-status ${status}`}>{status}</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string, record: IOrder) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => {
              setEditId(id);
              form.setFieldsValue({ status: record.status });
            }}
          >
            <AiFillEdit />
          </Button>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.OrderDetails(id))}
          >
            <AiOutlineBars />
          </Button>

          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.OrderInvoice(id))}
          >
            <FaFileInvoice />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={pagination}
        expandable={{
          expandedRowRender: (record: IOrder) => (
            <OrderDetails order={data.find((item) => item.id === record.id)} />
          ),
        }}
      />
      <Modal
        centered
        title="Update Order Status"
        width={250}
        open={editId ? true : false}
        onCancel={() => setEditId(null)}
        onOk={() => setEditId(null)}
        footer={false}
      >
        <Form
          size="large"
          layout="vertical"
          form={form}
          onFinish={(val) =>
            updateOrder.mutateAsync({ id: editId, status: val.status })
          }
        >
          <Form.Item name="status">
            <Select placeholder="Select a status">
              <Select.Option value="PLACED">PLACED</Select.Option>
              <Select.Option value="CONFIRMED">CONFIRMED</Select.Option>
              <Select.Option value="COMPLETED">COMPLETED</Select.Option>
              <Select.Option value="CANCELED">CANCELED</Select.Option>
            </Select>
          </Form.Item>
          <div className="text-right">
            <Button
              loading={updateOrder.isLoading}
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default OrderList;
