import { Paths } from '@shared/constant';
import { useDeleteOffer } from '@shared/hooks';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { _ } from '@shared/utils';
import { getAccess } from '@modules/auth';
import ImagePreview from '@shared/components/ImagePreview';
import { IOffer } from '@shared/interfaces';

interface IProps {
  isLoading: boolean;
  data: IOffer[];
  pagination: PaginationProps;
}
const OfferList: React.FC<IProps> = ({ isLoading, data, pagination }) => {
  const navigate = useNavigate();

  const deleteOffer = useDeleteOffer();

  const dataSource = data?.map((x) => ({
    key: x?.id,
    id: x?.id,
    title: x?.title,
    image: x?.image,
    startDate: _.getStutteredDate(x?.startDate),
    endDate: _.getStutteredDate(x?.endDate),
  }));

  const columns = [
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => <ImagePreview src={image} alt="TImage" />,
    },
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'startDate',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'endDate',
      dataIndex: 'endDate',
      key: 'endDate',
    },

    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.OfferUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete it"
            onConfirm={() =>
              getAccess(['SUBSCRIPTION_DELETE', 'FORBIDDEN'], () =>
                deleteOffer.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={pagination}
      />
    </>
  );
};
export default OfferList;
