import React from 'react';
import { Paths } from '@shared/constant';
import { ICallRequest } from '@shared/interfaces';
import { Button, PaginationProps, Popconfirm, Space, Table } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { getAccess } from '@modules/auth';
import { useDeleteCallRequest } from '@shared/hooks';

interface IProps {
  isLoading: boolean;
  data: ICallRequest[];
  pagination: PaginationProps;
}
const CallRequestList: React.FC<IProps> = ({ isLoading, data, pagination }) => {
  const navigate = useNavigate();
  const deleteCallRequest = useDeleteCallRequest();

  const dataSource = data?.map((x) => ({
    key: x?.id,
    id: x?.id,
    user: x?.user.firstName + ' ' + x?.user.lastName,
    phoneNumber: x?.phoneNumber,
    isActive: x?.isActive?.toString(),
    package: x?.package.title,
  }));

  const columns = [
    {
      title: 'user',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'phoneNumber',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'isActive',
      dataIndex: 'isActive',
      key: 'isActive',
    },
    {
      title: 'package',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <Space>
          <Button
            type="primary"
            style={{ fontSize: 20, borderRadius: 5 }}
            onClick={() => navigate(Paths.CallRequestUpdate(id))}
          >
            <AiFillEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this Call Request?"
            onConfirm={() =>
              getAccess(['ORDER_DELETE', 'FORBIDDEN'], () =>
                deleteCallRequest.mutate(id)
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="primary"
              style={{ fontSize: 20, borderRadius: 5 }}
            >
              <AiFillDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
    />
  );
};
export default CallRequestList;
