import { PermissionService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig, queryClient } from '@shared/config';
import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';

//---------------- usePermissions hook ------------------------------------
type IUsePermissions = {
  options?: IBaseFilter;
  config?: QueryConfig<typeof PermissionService.filter>;
};
export const usePermissions = ({ options, config }: IUsePermissions = {}) => {
  return useQuery({
    ...config,
    queryKey: [PermissionService.NAME, options],
    queryFn: () => PermissionService.filter(options),
  });
};

//----------------------- usePermission hook --------------------------------------
type IUsePermission = {
  id: string;
  config?: QueryConfig<typeof PermissionService.filterById>;
};

export const usePermission = ({ id, config }: IUsePermission) => {
  return useQuery({
    ...config,
    queryFn: () => PermissionService.filterById(id),
  });
};

//------------------ useCreatePermission hook ---------------------------------
type IUseCreatePermission = {
  config?: MutationConfig<typeof PermissionService.create>;
};

export const useCreatePermission = ({ config }: IUseCreatePermission = {}) => {
  return useMutation({
    ...config,
    mutationFn: PermissionService.create,
  });
};

//------------------ useUpdatePermission hook ----------------------------------
type IUseUpdatePermission = {
  config?: MutationConfig<typeof PermissionService.update>;
};

export const useUpdatePermission = ({ config }: IUseUpdatePermission = {}) => {
  return useMutation({
    ...config,
    mutationFn: PermissionService.update,
  });
};

//------------------ useDeletePermission hook ----------------------------------
type IUseDeletePermission = {
  config?: MutationConfig<typeof PermissionService.delete>;
};

export const useDeletePermission = ({ config }: IUseDeletePermission = {}) => {
  return useMutation({
    ...config,
    onSuccess: (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries(PermissionService.NAME);
        notification.success({
          message: 'Permission successfully deleted',
        });
      }
    },
    mutationFn: PermissionService.delete,
  });
};
