import { Authorization } from '@modules/auth';
import { useCreateOrder } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import OrderCreateForm from '../components/OrderCreateForm';

const OrderCreateRoute = () => {
  const navigate = useNavigate();

  const createOrder = useCreateOrder({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: res?.data?.message || 'Order Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['ORDER_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Order">
        <OrderCreateForm
          isLoading={createOrder.isLoading}
          onFinish={(values) => createOrder.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};

export default OrderCreateRoute;
