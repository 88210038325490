import { Paths } from '@shared/constant';
import { Navigate } from 'react-router-dom';
import CategoriesRoute from './routes/CategoriesRoute';
import CategoryCreateRoute from './routes/CategoryCreateRoute';
import CategoryUpdateRoute from './routes/CategoryUpdateRoute';

export const CategoryRoutes = [
  { path: Paths.Categorie, element: <Navigate to={Paths.CategorieList} /> },
  { path: Paths.CategorieList, element: <CategoriesRoute /> },
  { path: Paths.CategoryCreate, element: <CategoryCreateRoute /> },
  { path: Paths.CategoryUpdate(), element: <CategoryUpdateRoute /> },
];
