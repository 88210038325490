import { UserRoleService } from '@shared/services';
import { IBaseFilter } from '@shared/interfaces';
import { MutationConfig, QueryConfig } from '@shared/config';
import { useMutation, useQuery } from 'react-query';

//---------------- useUserRoles hook ------------------------------------
type IUseUserRoles = {
  userId: string;
  options?: IBaseFilter;
  config?: QueryConfig<typeof UserRoleService.filter>;
};
export const useUserRoles = ({ userId, options, config }: IUseUserRoles) => {
  return useQuery({
    ...config,
    queryKey: [UserRoleService.NAME, options],
    queryFn: () => UserRoleService.filter(userId, options),
  });
};

// //----------------------- useUserRole hook --------------------------------------
// type IUseUserRole = {
//   id: string;
//   config?: QueryConfig<typeof UserRoleService.filterById>;
// };

// export const useUserRole = ({ id, config }: IUseUserRole) => {
//   return useQuery({
//     ...config,
//     queryFn: () => UserRoleService.filterById(id),
//   });
// };

//------------------ useCreateUserRole hook ---------------------------------
type IUseCreateUserRole = {
  config?: MutationConfig<typeof UserRoleService.create>;
};

export const useCreateUserRole = ({ config }: IUseCreateUserRole = {}) => {
  return useMutation({
    ...config,
    mutationFn: UserRoleService.create,
  });
};

//------------------ useUpdateUserRole hook ----------------------------------
type IUseUpdateUserRole = {
  config?: MutationConfig<typeof UserRoleService.update>;
};

export const useUpdateUserRole = ({ config }: IUseUpdateUserRole = {}) => {
  return useMutation({
    ...config,
    mutationFn: UserRoleService.update,
  });
};

// //------------------ useDeleteUserRole hook ----------------------------------
// type IUseDeleteUserRole = {
//   config?: MutationConfig<typeof UserRoleService.delete>;
// };

// export const useDeleteUserRole = ({ config }: IUseDeleteUserRole = {}) => {
//   return useMutation({
//     ...config,
//     onSuccess: (res) => {
//       if (res?.data?.success) {
//         queryClient.invalidateQueries(UserRoleService.NAME);
//         notification.success({
//           message: 'UserRole successfully deleted',
//         });
//       }
//     },
//     mutationFn: UserRoleService.delete,
//   });
// };
