import { Authorization } from '@modules/auth';
import { useCreateProviderPackage } from '@shared/hooks';
import { notification, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import ProviderPackageCreateForm from '../components/ProviderPackageCreateForm';

const ProviderPackageCreatePage = () => {
  const navigate = useNavigate();

  const createProviderPackage = useCreateProviderPackage({
    config: {
      onSuccess: (res) => {
        if (res?.data?.success) {
          navigate(-1);
          notification.success({
            message: 'Created successfully',
          });
        }
      },
    },
  });

  return (
    <Authorization allowedAccess={['PROVIDER_PACKAGE_CREATE', 'FORBIDDEN']}>
      <PageHeader onBack={() => navigate(-1)} title="Create Provider Package">
        <ProviderPackageCreateForm
          isLoading={createProviderPackage.isLoading}
          onFinish={(values) => createProviderPackage.mutateAsync(values)}
        />
      </PageHeader>
    </Authorization>
  );
};
export default ProviderPackageCreatePage;
